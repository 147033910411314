import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { CircularProgress } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";

import { whereObjectUsed } from "app/crud/networks.crud";
import { getWhereUsed } from "utils/utils";
import BeSafeButton from "components/commons/BeSafeButton";
import BeSafeContainer from "components/commons/BeSafeContainer";

const DnsRecordSubList = ({
  previewLoading,
  dnsRecords,
  selectedDnsRecord,
  deleteDnsRecordObject,
  showEditDnsRecordModal,
}) => {
  const intl = useIntl();

  const [whereUsed, setWhereUsed] = useState([]);

  useEffect(() => {
    whereObjectUsed(selectedDnsRecord.id)
      .then((response) => {
        setWhereUsed(getWhereUsed(response.data));
      })
      .catch(() => { });
  }, [selectedDnsRecord.id]);

  return (
    <>
      <BeSafeContainer visible={previewLoading}>
        <div className="d-flex card justify-content-center align-items-center w-60 h-100 mr3">
          <CircularProgress />
        </div>
      </BeSafeContainer>
      <BeSafeContainer visible={!previewLoading && dnsRecords.length <= 0}>
        <span className="card w-60 h-100 mr-3 p-3 text-center pt-5">
          <FormattedMessage id="GENERAL.NO_DATA" />
        </span>
      </BeSafeContainer>
      <BeSafeContainer visible={!previewLoading && dnsRecords.length > 0}>
        <Card className="w-60 h-100 mr-3">
          <Card.Header>
            <Card.Title className="d-flex justify-content-between py-0 my-0 text-cus-heading">
              <span className="my-auto">
                {selectedDnsRecord.name}
              </span>
              {selectedDnsRecord.editable &&(
                <div>
                  <BeSafeButton
                    variant="transparent"
                    className="pr-1"
                    onClick={showEditDnsRecordModal}
                    icon={<Edit className="icon-table-md" />}
                    tooltip="GENERAL.EDIT_SMALL"
                  />
                  <BeSafeButton
                    variant="transparent"
                    className="px-2"
                    onClick={deleteDnsRecordObject}
                    icon={<Delete className="icon-table-md" />}
                    tooltip="GENERAL.DELETE_SMALL"
                  />
                </div>
              )}
            </Card.Title>
          </Card.Header>
          <Card.Body className="overflow-auto">
            <div className="d-flex py-2">
              <span className="font-weight-bold">
                <FormattedMessage id="GENERAL.DOMAIN_NAME" />
              </span>
              <span>{`: ${selectedDnsRecord?.name || intl.formatMessage({ id: "GENERAL.NONE" }) }`}</span>
            </div>
            <div className="d-flex py-2">
              <span className="font-weight-bold">
                <FormattedMessage id="GENERAL.DESCRIPTION" />
              </span>
              <span>{`: ${selectedDnsRecord?.description || intl.formatMessage({id: "GENERAL.NONE"})}`}</span>
            </div>
            {selectedDnsRecord?.target_secondary ?
            <>
              <div className="d-flex py-2">
                <span className="font-weight-bold">
                  <FormattedMessage id="OBJECT_EXPLORER.IP_ADDRESS_STRICT" />
                </span>
                <span>{`: ${selectedDnsRecord?.target || intl.formatMessage({ id: "GENERAL.NONE" })}`}</span>
              </div>
              <div className="d-flex py-2">
                <span className="font-weight-bold">
                  <FormattedMessage id="OBJECT_EXPLORER.IP_ADDRESS_MODERATE" />
                </span>
                <span>{`: ${selectedDnsRecord?.target_secondary || intl.formatMessage({ id: "GENERAL.NONE" })}`}</span>
              </div>
            </> :
            <div className="d-flex py-2">
              <span className="font-weight-bold">
                <FormattedMessage id="DEVICES.IP_ADDRESS" />
              </span>
              <span>{`: ${selectedDnsRecord?.target || intl.formatMessage({ id: "GENERAL.NONE" })}`}</span>
            </div>
            }
          </Card.Body>
          <Card.Footer className="obj-where-used">
            <p className="font-weight-bold my-0">
              <FormattedMessage id="GENERAL.WHERE_USED" />:
            </p>
            <span>
              {whereUsed?.length <= 0 ? (
                <FormattedMessage id="GENERAL.NOT_IN_USE" />
              ) : (
                whereUsed?.map((r) => r).join(", ")
              )}
            </span>
          </Card.Footer>
        </Card>
      </BeSafeContainer>
    </>
  );
};

export default DnsRecordSubList;
