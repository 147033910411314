import React, { useEffect, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavDropdown, Badge, Spinner } from 'react-bootstrap';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CompareIcon from '@mui/icons-material/Compare';

import {
  SetConfigurationChanges,
  SetInstallationId,
} from 'store/ducks/configuration.duck';
import {
  discardAllChangesConfiguration,
  getCountNotificacionChanges,
} from 'app/crud/configuration.crud';
import { findAllTasks, getTask } from 'app/crud/tasks.crud';
import { successToast, errorToast, warningToast } from 'utils/toastUtils';
import { ConfirmationPopup } from 'components/ConfirmDialog';
import ViewChangesModal from 'components/modals/ViewChangesModal';
import InstallChangesModal from 'components/modals/InstallChangesModal';

const NotificationChanges = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const [showModalInstall, setShowModalInstall] = useState(false);
  const [isInstalling, setIsInstalling] = useState(false);
  const installingIntervalTime = 5000;

  const { accountID, changesConfiguration, installationId } = useSelector(
    ({ auth, configuration }) => ({
      accountID: auth.user.account_id,
      changesConfiguration: configuration.changeCount,
      installationId: configuration.installationId,
    }),
    shallowEqual
  );

  const fetchInstallConfiguration = useCallback(
    (installationId) => {
      if (installationId !== null) {
        getTask(installationId)
          .then((response) => {
            const task = response.data;
            if (task === null) {
              dispatch(SetInstallationId(null));
            } else if (task.status === 'finished') {
              dispatch(SetInstallationId(null));
              setIsInstalling(false);
              successToast({
                body: 'NAVBAR.SUCCESS_INSTALL_CONFIGURATION',
                intl: intl,
              });
            } else if (task.status === 'error') {
              dispatch(SetInstallationId(null));
              setIsInstalling(false);

              let error_body = 'NAVBAR.ERROR_INSTALL_CONFIGURATION';
              if (
                [
                  'UTM_LICENSE_EXCEPTION',
                  'CLOUD_LICENSE_EXCEPTION',
                  'INTERFACE_EXCEPTION',
                  'UTM_PROFILE_EXCEPTION',
                ].includes(task.error_message)
              ) {
                error_body = `NAVBAR.${task.error_message}`;
              }

              errorToast({
                body: error_body,
                data: task.error_data ? task.error_data : {},
                intl: intl,
                hideAfter: 0,
              });
            } else if (task.status === 'running') {
              setIsInstalling(true);
            }
          })
          .catch((error) => {});
      } else {
        checkInstallConfigurations();
      }
    },
    [dispatch, intl]
  );

  const checkInstallConfigurations = (callback) => {
    findAllTasks({
      task_type: 'installation',
      status: ['created', 'running'],
    })
      .then((response) => {
        const flag = 'data' in response && response.data.length > 0;
        setIsInstalling(flag);

        if (callback) {
          callback(flag);
        }
      })
      .catch((error) => {});
  };

  const clickInstallNotifications = () => {
    checkInstallConfigurations((isInstalling) => {
      if (isInstalling) {
        warningToast({
          body: 'NAVBAR.WAIT_INSTALL_CONFIGURATION',
          intl: intl,
        });
      } else {
        setShowModalInstall(true);
      }
    });
  };

  useEffect(() => {
    getCountNotificacionChanges()
      .then((response) => {
        dispatch(SetConfigurationChanges(response.data.count_changes));
      })
      .catch(() => {});
  }, [dispatch, installationId]);

  useEffect(() => {
    if (installationId) {
      setIsInstalling(true);
    }
  }, [installationId]);

  useEffect(() => {
    if (isInstalling) {
      const installingInterval = setInterval(() => {
        fetchInstallConfiguration(installationId);
      }, installingIntervalTime);

      return () => clearInterval(installingInterval);
    }
  }, [isInstalling, installationId, fetchInstallConfiguration]);

  const discardChanges = () => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: 'GENERAL.WARNING',
      }),
      description: intl.formatMessage({
        id: 'NAVBAR.CONFIRM_DISCARD_CHANGES',
      }),
      okLabel: intl.formatMessage({
        id: 'GENERAL.OK',
      }),
      cancelLabel: intl.formatMessage({
        id: 'GENERAL.CANCEL',
      }),
      okAction: () => {
        discardAllChangesConfiguration(accountID)
          .then((response) => {
            navigate(0);
            successToast({
              body: 'NAVBAR.SUCCESS_DISCARD_CHANGES',
              intl: intl,
            });
          })
          .catch((err) => {
            errorToast({ body: err.response.data?.error, intl: intl });
          });
      },
    });
  };

  return (
    <>
      {changesConfiguration > 0 && (
        <NavDropdown
          className="d-flex-navbar"
          title={
            <div className="d-flex align-items-center justify-content-center text-primary">
              <InsertDriveFileIcon />
              <FormattedMessage id="NAVBAR.CHANGES" />
              <Badge variant="warning" className="ml-1 pb-1">
                {changesConfiguration}
              </Badge>
            </div>
          }
        >
          {isInstalling ? (
            <NavDropdown.Item disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ margin: '0 8px 0 4px' }}
              />
              <FormattedMessage id="NAVBAR.INSTALLING" />
            </NavDropdown.Item>
          ) : (
            <NavDropdown.Item onClick={clickInstallNotifications}>
              <CloudDownloadIcon style={{ marginRight: '4px' }} />
              <FormattedMessage id="NAVBAR.INSTALL_CONFIGURATION" />
            </NavDropdown.Item>
          )}
          <NavDropdown.Item onClick={() => setShowModal(true)}>
            <CompareIcon style={{ marginRight: '4px' }} />
            <FormattedMessage id="NAVBAR.VIEW_CHANGES" />
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => discardChanges()}>
            <DeleteIcon style={{ marginRight: '4px' }} />
            <FormattedMessage id="NAVBAR.DISCARD_CHANGES" />
          </NavDropdown.Item>
        </NavDropdown>
      )}
      <ViewChangesModal
        showModal={showModal}
        onHide={() => setShowModal(false)}
      ></ViewChangesModal>
      <InstallChangesModal
        showModal={showModalInstall}
        changes={changesConfiguration}
        check={checkInstallConfigurations}
        onHide={() => setShowModalInstall(false)}
      ></InstallChangesModal>
    </>
  );
};

export default NotificationChanges;
