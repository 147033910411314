import ErrorPage from 'components/pages/ErrorPage';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Loader from './Loader';

const RoleAccess = ({ roles = [] }) => {
  const { role } = useSelector(
    ({ auth }) => ({
      role: auth.user?.role,
    }),
    shallowEqual
  );

  if (!role) return <Loader />;

  return roles.includes(role) ? (
    <Outlet />
  ) : (
    <ErrorPage status={404} message="Not found" />
  );
};

export default RoleAccess;
