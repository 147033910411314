import { CircularProgress } from "@mui/material";
import { Card, ListGroup, Image } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import { useEffect } from "react";
import { whereObjectUsed } from "app/crud/networks.crud";
import { useState } from "react";
import { getWhereUsed } from "utils/utils";

const GeolocationSubList = ({
  previewLoading,
  locations,
  selectedLocation,
}) => {
  const [whereUsed, setWhereUsed] = useState([]);

  useEffect(() => {
    whereObjectUsed(selectedLocation.id)
      .then((response) => {
        setWhereUsed(getWhereUsed(response.data));
      })
      .catch(() => { });
  }, [selectedLocation.id]);

  return (
    <>
      {previewLoading ? (
        <div className="d-flex card justify-content-center align-items-center w-60 h-100 mr3">
          <CircularProgress />
        </div>
      ) : locations.length <= 0 ? (
        <span className="card w-60 h-100 mr-3 p-3 text-center pt-5">
          <FormattedMessage id="GENERAL.NO_DATA" />
        </span>
      ) : (
        <Card className="w-60 h-100 mr-3">
          <Card.Header>
            <Card.Title className="d-flex justify-content-between py-0 my-0 text-cus-heading">
              <span className="my-auto">
                <Image
                  src={`/images/flags/flag-icons/4x3/${_.lowerCase(
                    selectedLocation.description
                  )}.svg`}
                  style={{
                    width: "30px",
                    border: "1px solid #7e7e7e",
                  }}
                />{" "}
                <FormattedMessage
                  id={`COUNTRIES.${selectedLocation.description}`}
                />
              </span>
            </Card.Title>
          </Card.Header>
          <Card.Body className="overflow-auto">
            {selectedLocation?.besafe_groups?.length > 0 &&
            <>
              <div className="d-flex py-2">
                <span className="font-weight-bold">
                  <FormattedMessage id="NETWORK_OBJECTS.GROUPS" />
                </span>
              </div>
              <ListGroup>
                {selectedLocation?.besafe_groups?.map((g, index) => (
                  <ListGroup.Item
                    key={g.id}
                    className="d-flex justify-content-between py-0"
                  >
                    <span className="w-75 py-2">{g.name}</span>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </>}
          </Card.Body>
          <Card.Footer className="obj-where-used">
            <p className="font-weight-bold my-0">
              <FormattedMessage id="GENERAL.WHERE_USED" />:
            </p>
            <span>
              {whereUsed?.length <= 0 ? (
                <FormattedMessage id="GENERAL.NOT_IN_USE" />
              ) : (
                whereUsed?.map((r) => r).join(", ")
              )}
            </span>
          </Card.Footer>
        </Card>
      )}
    </>
  );
};

export default GeolocationSubList;
