import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const actionTypes = {
  SetConfigurationChanges: '[Load Configuration change count] Action',
  SetInstallationId: '[Load Installation Id] Action',
  SetSectionTabName: '[Load Section Tab Name] Action',
  SetObjectExplorerModal: '[Load Object Explorer Modal] Action',
  SetLatestConfigurationChange: '[Load Configuration menu] Action',
  SetSecondarySidebar: '[Load Secondary Sidebar] Action',
  SetConcurrentUsersExceeded: '[Load Exceed Concurrent Users] Action',
};

const initialState = {
  changeCount: 0,
  installationId: null,
  sectionTabName: null,
  objectExplorerModal: false,
  latestConfigurationChange: Date.now(),
  secondarySidebar: false,
  concurrentUsersExceeded: false,
};

export const reducer = persistReducer(
  { storage, key: 'configuration' },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetConfigurationChanges:
        return { ...state, changeCount: action.payload.changeCount };

      case actionTypes.SetInstallationId:
        return { ...state, installationId: action.payload.installationId };

      case actionTypes.SetSectionTabName:
        return { ...state, sectionTabName: action.payload.sectionTabName };

      case actionTypes.SetObjectExplorerModal:
        return {
          ...state,
          objectExplorerModal: action.payload.objectExplorerModal,
        };

      case actionTypes.SetLatestConfigurationChange:
        return {
          ...state,
          latestConfigurationChange: action.payload.latestConfigurationChange,
        };

      case actionTypes.SetSecondarySidebar:
        return { ...state, secondarySidebar: action.payload.secondarySidebar };

      case actionTypes.SetConcurrentUsersExceeded:
        return {
          ...state,
          concurrentUsersExceeded: action.payload.concurrentUsersExceeded,
        };

      default:
        return state;
    }
  }
);

export const SetConfigurationChanges = (changeCount) => ({
  type: actionTypes.SetConfigurationChanges,
  payload: { changeCount },
});

export const SetInstallationId = (installationId) => ({
  type: actionTypes.SetInstallationId,
  payload: { installationId },
});

export const SetSectionTabName = (sectionTabName) => ({
  type: actionTypes.SetSectionTabName,
  payload: { sectionTabName },
});

export const SetObjectExplorerModal = (objectExplorerModal) => ({
  type: actionTypes.SetObjectExplorerModal,
  payload: { objectExplorerModal },
});

export const SetLatestConfigurationChange = (latestConfigurationChange) => ({
  type: actionTypes.SetLatestConfigurationChange,
  payload: { latestConfigurationChange },
});

export const SetSecondarySidebar = (secondarySidebar) => ({
  type: actionTypes.SetSecondarySidebar,
  payload: { secondarySidebar },
});

export const SetConcurrentUsersExceeded = (concurrentUsersExceeded) => ({
  type: actionTypes.SetConcurrentUsersExceeded,
  payload: { concurrentUsersExceeded },
});
