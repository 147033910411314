import { combineReducers } from "redux";
import theme from "./themeStore";
import { reducer } from "../../i18n/i18n";
import * as policy from "../ducks/policyChange.duck";
import * as auth from "../ducks/auth.duck";
import * as threat_profiles from "../ducks/threatProfile.duck";
import * as clusters from "../ducks/clusters.duck";
import * as configuration from "../ducks/configuration.duck"

export default combineReducers({
  theme,
  i18n: reducer,
  auth: auth.reducer,
  policy: policy.reducer,
  threat_profiles: threat_profiles.reducer,
  clusters: clusters.reducer,
  configuration: configuration.reducer
});
