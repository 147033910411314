import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import BeSafeTable from "components/commons/table/BeSafeTable";
import BeSafeTabs from "components/commons/BeSafeTabs";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GppBadIcon from '@mui/icons-material/GppBad';
import styles from 'assets/scss/azure_ad.module.scss';
import BeSafeButton from "components/commons/BeSafeButton";

const AzureUserListModal = ({ setShowAzureUserModal, usersList }) => {
  const inValidUsers = usersList.filter(user => !!Object.keys(user.errors).length);
  const validUsers = usersList.filter(user => !Object.keys(user.errors).length);

  const successTitle = () => {
    return (
      <>
        <FormattedMessage id="NETWORK_OBJECTS.SYNCED_USERS" />
        <BeSafeButton
          className="px-1 py-0 ml-2"
          variant="primary"
          disabled={true}
          text={validUsers.length}
        />
      </>
    );
  }
  const errorTitle = () => {
    return (
      <>
        <FormattedMessage id="NETWORK_OBJECTS.SYNC_FAILED" />
        <BeSafeButton
          className="px-1 py-0 ml-2"
          variant="primary"
          disabled={true}
          text={inValidUsers.length}
        />
      </>
    );
  }

  const tabs = [
    {
      key: "success",
      icon: <CheckCircleIcon />,
      title: successTitle(),
      content: (
        <BeSafeTable
          rows={validUsers}
        >
          <BeSafeTable.Column
            header={<FormattedMessage id="USERS.NAME" />}
            cellRenderer={(config) => config.name}
          />
          <BeSafeTable.Column
            header={<FormattedMessage id="USERS.EMAIL" />}
            cellRenderer={(config) => config.data.email}
          />
          <BeSafeTable.Column
            header={<FormattedMessage id="GENERAL.STATUS" />}
            cellRenderer={() => <FormattedMessage id="NETWORK_OBJECTS.SYNCED_SUCCESS" />}
          />
        </BeSafeTable>
      ),
    },
    {
      key: "error",
      icon: <GppBadIcon />,
      title: errorTitle(),
      content: (
        <BeSafeTable
          rows={inValidUsers}
        >
          <BeSafeTable.Column
            header={<FormattedMessage id="USERS.NAME" />}
            cellRenderer={(config) => config.name}
          />
          <BeSafeTable.Column
            header={<FormattedMessage id="USERS.EMAIL" />}
            cellRenderer={(config) => config.data.email}
          />
          <BeSafeTable.Column
            header={<FormattedMessage id="GENERAL.STATUS" />}
            cellRenderer={(config) => !config.errors ? <FormattedMessage id="NETWORK_OBJECTS.SYNC_FAILED" /> : Object.values(config.errors).join(', ')}
          />
        </BeSafeTable>
      ),
    },
  ];

  return (
    <Modal show onHide={() => setShowAzureUserModal(false)} centered size="xl" scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="NETWORK_OBJECTS.SYNC_AZURE_AD_USERS" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.azureUsersTableHeight}>
        <BeSafeTabs defaultActiveKey="success" tabs={tabs} className={`flex-row ${styles.azureUserSyncTab}`} />
      </Modal.Body>
    </Modal>
  );
};

export default AzureUserListModal;