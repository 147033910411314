import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import Loader from './Loader';
import { login } from 'app/crud/auth.crud';
import * as auth from 'store/ducks/auth.duck';

const AuthenticatedAccess = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.authToken !== null,
    }),
    shallowEqual
  );

  if (!initialized) {
    return <Loader />;
  }

  if (
    keycloak.authenticated &&
    !isAuthorized &&
    location.pathname !== '/logout'
  ) {
    document.cookie = 'kc_session =' + keycloak.token + '; path=/';
    login(keycloak.idTokenParsed.email)
      .then((response) => {
        dispatch(auth.actions.login(response.data, keycloak.token));
      })
      .catch((error) => {
        if (error.response?.data?.error === 'User not authorized') {
          return;
        }
      });
  }

  return <Outlet />;
};

export default AuthenticatedAccess;
