import React from "react";
import { Table } from "react-bootstrap";
import "assets/scss/resizable_table.scss";
import SkeletonLoader from "tiny-skeleton-loader-react";

const BeSafeTableWithLoading = ({ loading, loadingRows, className, borderless, children }) => {
  const thElements = React.Children.map(children, child => {
    if (React.isValidElement(child) && child.type === 'thead') {
      return React.Children.map(child.props.children, th => {
        if (React.isValidElement(th) && th.type === 'tr') {
          return React.Children.map(th.props.children, td => {
            if (React.isValidElement(td) && td.type === 'th') {
              return td;
            }
            return null;
          });
        }
        return null;
      });
    }
    return null;
  });

  const render = () => {
    if (loading) {
      return <>
          <thead>
            {thElements.map((element) => {
              return element;
            })}
          </thead>
          <tbody>
            {[...Array(loadingRows)].map(() => (
              <tr>
                {thElements.map((_element) => {
                  return <td><SkeletonLoader width="100%" height={26.5}/></td>;
                })}
              </tr>
            ))}
          </tbody>
        </>
    }

    return children;
  };

  return (
    <Table className={"mb-0 table-header " + className} hover borderless={borderless}>
      {render()}
    </Table>
  );
};

BeSafeTableWithLoading.defaultProps = {
  loadingRows: 5
};

export default BeSafeTableWithLoading;
