import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { Lock, KeyboardArrowDown } from '@mui/icons-material';

import { encodeProfileId } from 'utils/utils';
import BeSafeContainer from 'components/commons/BeSafeContainer';
import BeSafeButton from 'components/commons/BeSafeButton';

const MenuConfiguration = ({ accountType, menu, parent, onChangeExpanded }) => {
  const location = useLocation();

  const hasLicense = (elem) => {
    return !('has_license' in elem) || elem.has_license;
  };

  const generateKey = (elem, index) => {
    let key = `${parent.name}-${index}`;

    if (elem.id && elem.name) {
      key = `${parent.name}-${elem.name}-${elem.id}`;
    }

    if (elem.name) {
      key = `${parent.name}-${elem.name}-${index}`;
    }

    return key;
  };

  const generateName = (elem) => {
    if (elem.displayName) {
      return <FormattedMessage id={elem.displayName} />;
    }
    return elem.name;
  };

  const generateRoute = (elem) => {
    if (elem) {
      const encodedId = encodeProfileId(elem.id);

      return `/configuration/${parent.name}/${encodedId}`;
    }
    return '';
  };

  const generateTitle = (elem) => {
    if (elem.functionality_type) {
      return `${elem.name} (${elem.functionality_type.toUpperCase()})`;
    }
    return elem.name;
  };

  const isActiveMenu = (elem) => {
    return location.pathname === generateRoute(elem);
  };

  const getFilteredElements = (elements) => {
    if (accountType === 'carrier') {
      return elements.filter(
        (element) =>
          ('menu' in element && 'isCarrierConf' in element) || !element.menu
      );
    }

    return elements;
  };

  const disabledElement = (elem) => {
    return ['ipsec-vpn', 'ssl-vpn'].includes(elem.name);
  };

  const generateClassName = (elem) => {
    let className = 'nav_menu';

    if (!hasLicense(elem)) {
      className += ' is-disabled';
    }

    if (!hasLicense(elem) || disabledElement(elem)) {
      className += ' is-disabled';
    }

    if (elem.expanded) {
      className += ' expanded';
    }

    return className;
  };

  return (
    <>
      {getFilteredElements(menu).map((elem, index) =>
        !('menu' in elem) ? (
          <span key={generateKey(elem, index)}>
            <Link
              className={`nav_option ${isActiveMenu(elem) ? 'is-active' : ''}`}
              to={generateRoute(elem)}
            >
              {generateTitle(elem)}
            </Link>
          </span>
        ) : (
          <span
            key={generateKey(elem, index)}
            className={generateClassName(elem)}
          >
            <span className="nav_title">
              <span className="nav_name">{generateName(elem)}</span>

              <BeSafeContainer
                visible={hasLicense(elem) && !disabledElement(elem)}
              >
                <span className="d-flex justify-content-end align-items-center">
                  <BeSafeButton
                    className="p-0 ml-1"
                    icon={<KeyboardArrowDown className="icon" />}
                    onClick={() => onChangeExpanded(elem)}
                    visible={elem.menu}
                  />
                </span>
              </BeSafeContainer>

              <BeSafeContainer
                visible={!hasLicense(elem) || disabledElement(elem)}
              >
                <span className="icon p-1">
                  <Lock fontSize="small" />
                </span>
              </BeSafeContainer>
            </span>

            <BeSafeContainer
              visible={hasLicense(elem) && !disabledElement(elem)}
            >
              <span className="nav_options">
                <MenuConfiguration
                  accountType={accountType}
                  menu={elem.menu}
                  parent={elem}
                  onChangeExpanded={onChangeExpanded}
                />
              </span>
            </BeSafeContainer>
          </span>
        )
      )}
    </>
  );
};

export default MenuConfiguration;
