import React from 'react'

const ErrorPage = ({status, message}) => {
  return (
    <div className="vh-100 d-flex align-items-center justify-content-center">
      <div className="text-center">
        <h1 className="error-page-status">{status}</h1>
        <h2 className="error-page-message">{message}</h2>
      </div>
    </div>
  )
};

export default ErrorPage
