import React, { useEffect, useCallback } from 'react';
import Logout from '../components/pages/Logout';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { DefaultLayout, routes, titleTemplate } from '../routes';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useRef } from 'react';
import cogoToast from 'cogo-toast';
import RoleAccess from './RoleAccess';
import AuthenticatedAccess from './AuthenticatedAccess';

import { SetConcurrentUsersExceeded } from 'store/ducks/configuration.duck';
import { getConcurrentUsers } from 'app/crud/accounts.crud';
import ErrorPage from 'components/pages/ErrorPage';

const Router = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { accountID, installationId } = useSelector(
    ({ auth, configuration }) => ({
      accountID: auth.user?.account_id,
      installationId: configuration.installationId,
    }),
    shallowEqual
  );

  const intl = useIntl();
  const toastData = useRef(null);
  const dispatch = useDispatch();

  const fetchConcurrentUsers = useCallback(() => {
    dispatch(SetConcurrentUsersExceeded(false));

    if (accountID) {
      getConcurrentUsers(accountID)
        .then((response) => {
          dispatch(SetConcurrentUsersExceeded(response.data.exceeded));
        })
        .catch(() => {});
    }
  }, [accountID, dispatch]);

  const disabledNavigation = (path) => {
    const disabledPaths = [
      '/configuration/ipsec-vpn/',
      '/configuration/ssl-vpn/',
    ];
    return disabledPaths.some((disabledPath) => path.includes(disabledPath));
  };

  useEffect(() => {
    if (disabledNavigation(location.pathname)) {
      navigate('*');
    } else if (location.pathname !== '/logout') {
      fetchConcurrentUsers();
    }
  }, [location.pathname, navigate, fetchConcurrentUsers]);

  useEffect(() => {
    const removeLoadingClass = () => {
      document.documentElement.classList.remove('app-loading');
    };

    const splashScreen = document.querySelector('.app-splash-screen');
    if (splashScreen) {
      splashScreen.style.opacity = 0;
      setTimeout(() => {
        if (splashScreen && splashScreen.parentNode) {
          splashScreen.parentNode.removeChild(splashScreen);
        }
        removeLoadingClass();
      }, 300);
    } else {
      removeLoadingClass();
    }
  }, []);

  const routers = routes.map((route) => {
    route.layout = route.layout || DefaultLayout;
    route.exact = typeof route.exact === 'undefined' ? true : route.exact;
    return route;
  });

  const setTitle = (title) => {
    document.title = titleTemplate.replace('%s', title);
  };

  useEffect(() => {
    if (installationId !== null && toastData.current === null) {
      toastData.current = cogoToast.loading(
        intl.formatMessage({ id: 'GENERAL.INSTALLING' }),
        { hideAfter: 0, position: 'bottom-right' }
      );
    }
    if (installationId === null && toastData.current !== null) {
      toastData.current.hide();
      toastData.current = null;
    }
  }, [installationId, intl]);

  const scrollTop = (
    to,
    duration,
    element = document.scrollingElement || document.documentElement
  ) => {
    if (element.scrollTop === to) return;
    const start = element.scrollTop;
    const change = to - start;
    const startDate = +new Date();

    if (!duration) {
      element.scrollTop = to;
      return;
    }

    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animateScroll = () => {
      const currentDate = +new Date();
      const currentTime = currentDate - startDate;
      element.scrollTop = parseInt(
        easeInOutQuad(currentTime, start, change, duration)
      );
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        element.scrollTop = to;
      }
    };

    animateScroll();
  };

  return (
    <Routes>
      {routers.map((route) => {
        return (
          <Route key={route.path} element={<AuthenticatedAccess />}>
            <Route element={<RoleAccess roles={route.roles} />}>
              <Route
                path={route.path}
                key={route.path}
                exact={route.exact}
                element={
                  <route.layout {...props}>
                    <route.component
                      {...props}
                      setTitle={setTitle}
                      scrollTop={scrollTop}
                    />
                  </route.layout>
                }
              />
            </Route>
          </Route>
        );
      })}
      <Route path="/logout" element={<Logout />} />
      <Route
        path="*"
        element={<ErrorPage status={404} message="Not Found" />}
      />
    </Routes>
  );
};

export default Router;
