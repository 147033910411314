import React from 'react';

const BeSafeGreenCircle = (props) => {
  const circleStyle = {
    width: '13px',
    height: '13px',
    backgroundColor: props.active ? '#49be25' : "grey",
    borderRadius: '50%',
    display: 'inline-block',
    animation: 'blink 1s infinite'
  };

  return <span className="ml-2" style={circleStyle}></span>;
};

export default BeSafeGreenCircle;