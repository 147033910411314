import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

import 'bootstrap/js/src/collapse.js';
import { installConfiguration } from '../../app/crud/tasks.crud';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import {
  SetConfigurationChanges,
  SetInstallationId,
} from '../../store/ducks/configuration.duck';
import { warningToast } from 'utils/toastUtils';
import { getAllDevices } from 'app/crud/devices.crud';
import { necessaryMemoryByModel, memoryByModel } from 'utils/utils';
import { errorToast } from 'utils/toastUtils';
import BeSafeTableWithLoading from 'components/commons/table/BeSafeTableWithLoading';

const InstallChangesModal = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [invalidMemoryDevices, setInvalidMemoryDevices] = useState([]);

  const fetchChangesDevices = () => {
    setLoading(true);
    getAllDevices({ with_monitor: true })
      .then((response) => {
        setDevices(response.data.devices);
        const devicesWithoutValidMemory = [];
        response.data.devices.forEach((device) => {
          if (device.rule_pack !== 'standard') {
            const necessaryMemory =
              necessaryMemoryByModel[device.model === 'VM' ? 'vm' : 'other'][
                device.rule_pack
              ];
            if (necessaryMemory > memoryByModel[device.model]) {
              devicesWithoutValidMemory.push(device);
            }
          }
        });
        setInvalidMemoryDevices(devicesWithoutValidMemory);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const submitInstall = () => {
    props.check((isInstalling) => {
      if (!isInstalling) {
        installConfiguration()
          .then((response) => {
            props.onHide();
            dispatch(SetConfigurationChanges(0));
            dispatch(SetInstallationId(response.data.task.id));
          })
          .catch((err) => {
            errorToast({
              body: err.response?.data?.error || 'DEVICES.ERROR_INSTALLATION',
              intl: intl,
            });
          });
      } else {
        props.onHide();
        warningToast({
          body: 'NAVBAR.WAIT_INSTALL_CONFIGURATION',
          intl: intl,
        });
      }
    });
  };

  return (
    <div>
      <Modal
        size="lg"
        centered
        show={props.showModal}
        onHide={props.onHide}
        onEnter={fetchChangesDevices}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="NAVBAR.INSTALL_CONFIGURATION" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <FormattedMessage id="CONFIGURATION.SUMMARY_CHANGES" />
            <BeSafeTableWithLoading
              loading={loading}
              loadingRows={1}
              className="changes-table"
              borderless
            >
              <thead>
                <tr key="header" style={{ textAlign: 'center' }}>
                  <th>
                    <div>
                      <FormattedMessage id="CONFIGURATION.PENDING_CHANGES" />
                    </div>
                  </th>
                  <th>
                    <div>
                      <FormattedMessage id="CONFIGURATION.AFFECTED_DEVICES" />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <td>{props.changes}</td>
                  <td>{devices.length}</td>
                </tr>
              </tbody>
            </BeSafeTableWithLoading>
            <br></br>
            <FormattedMessage id="CONFIGURATION.CONFIRMATION_QUESTION" />
            {invalidMemoryDevices.length > 0 && (
              <div style={{ 'padding-top': '1rem', color: 'red' }}>
                <ErrorOutlineRoundedIcon />
                <span>
                  <FormattedMessage id="DEVICES.INVALID_MEMORY" />:{' '}
                </span>
                {invalidMemoryDevices
                  .map((device) => `${device.alias_name} (${device.rule_pack})`)
                  .join(', ')}
              </div>
            )}
            <div>
              <Button
                onClick={submitInstall}
                variant="primary"
                style={{ marginTop: '40px', float: 'right' }}
                disabled={loading || invalidMemoryDevices.length > 0}
              >
                <FileDownloadIcon
                  style={{
                    paddingRight: '4px',
                    marginRight: '2px',
                  }}
                />
                <FormattedMessage id="NAVBAR.INSTALL_CONFIGURATION" />
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default connect((store) => ({
  user: store.auth.user,
}))(injectIntl(InstallChangesModal));
