import { ArrowDropDown, ArrowDropUp, DragIndicator } from '@mui/icons-material';
import React, { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import "assets/scss/resizable_table.scss";

const BeSafeColumn = ({ renderType, header, cellRenderer, headerClassName, cellClassName, data, row, sortFunction, resizable, onResize, onStartResize }) => {
  const orderDirections = ['', 'asc', 'desc'];
  const [direction, setDirection] = useState(orderDirections[0]);
  const [active, setActive] = useState(false);
  const [currentX, setCurrentX] = useState(0);
  const [initialWidth, setInitialWidth] = useState(0);
  const ref = useRef();

  const getSortDirectionArrow = () => {
    if (direction === orderDirections[1]) {
      return <ArrowDropUp fontSize="small" />;
    }

    if (direction === orderDirections[2]) {
      return <ArrowDropDown fontSize="small" />;
    }

    return null;
  };

  const getHeader = (header) => {
    return <>{header}{getSortDirectionArrow()}</>;
  };

  const sort = () => {
    if (sortFunction) {
      const index = orderDirections.indexOf(direction);
      const newDirection = orderDirections[(index + 1) % 3];
      setDirection(newDirection);
      
      sortFunction(newDirection);
    }
  };

  const handleSelected = (e) => {
    setActive(true);
    setCurrentX(e.clientX);
    setInitialWidth(ref.current.scrollWidth);
    onStartResize();
  }

  const mouseMove = useCallback((e) => {
    e.preventDefault();

    window.event.cancelBubble = true;
    const width = initialWidth + (e.clientX - currentX);
    ref.current.style.width = `${width}px`;
    onResize(e.clientX - currentX);
  }, [currentX, initialWidth, onResize]);

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActive(false);
    removeListeners();
  }, [setActive, removeListeners]);

  useEffect(() => {
    if (resizable && active) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }

    return () => {
      removeListeners();
    }
  }, [resizable, active, mouseMove, mouseUp, removeListeners]);

  const renderHeader = () => {
    if (resizable) {
      return <th ref={ref} className={(sortFunction ? 'cursor-pointer ' : '') + headerClassName}>
        <div className="d-flex">
          <span onClick={() => sort()} className="w-100">{getHeader(header)}</span>
          <div onMouseDown={(e) => handleSelected(e)} className={`resize-handle ${active ? 'active' : 'idle'}`} >
            <span className={`align-middle handle-icon ${active ? 'active' : 'idle'}`}><DragIndicator /></span>
          </div>
        </div>
      </th>    
    }

    return <th className={(sortFunction ? 'cursor-pointer ' : '') + headerClassName} onClick={() => sort()}>{getHeader(header)}</th>
  };
  
  const render = () => {
    if (renderType === 'header') {
      return renderHeader();
    }

    if (renderType === 'cell') {
      return <td className={'align-middle ' + cellClassName}>{cellRenderer(data, row)}</td>
    }

    return <span>Render type not recognized</span>;
  };

  return render();
}

BeSafeColumn.defaultProps = {
  visible: true,
  headerClassName: '',
  cellClassName: ''
}

export default BeSafeColumn;
