import { Card } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment-timezone";
import { CircularProgress } from "@mui/material";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";

import { getWhereUsed } from "utils/utils";
import { parseCronRangeTime } from "utils/cronUtils";
import { useState } from "react";
import { useEffect } from "react";
import { whereObjectUsed } from "app/crud/networks.crud";
import BeSafeButton from "components/commons/BeSafeButton";
import { Delete, Edit } from "@mui/icons-material";

const ScheduleSubList = ({
  previewLoading,
  schedules,
  selectedSchedule,
  deleteScheduleObject,
  showEditScheduleModal,
}) => {
  const intl = useIntl();
  const [whereUsed, setWhereUsed] = useState([]);

  useEffect(() => {
    whereObjectUsed(selectedSchedule.id)
      .then((response) => {
        setWhereUsed(getWhereUsed(response.data));
      })
      .catch(() => { });
  }, [selectedSchedule.id]);

  const weekDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

  let time = selectedSchedule?.data?.cron_start && selectedSchedule?.data?.cron_stop ?
    parseCronRangeTime(selectedSchedule.data.cron_start, selectedSchedule.data.cron_stop) : {
      periodSelection: "week",
      weekDays: [],
      allDay: false,
      startTime: "00:00",
      stopTime: "24:00",
      timezone: moment.tz.guess(),
    };

  return (
    <>
      {previewLoading ? (
        <div className="d-flex card justify-content-center align-items-center w-60 h-100 mr3">
          <CircularProgress />
        </div>
      ) : schedules.length <= 0 ? (
        <span className="card w-60 h-100 mr-3 p-3 text-center pt-5">
          <FormattedMessage id="GENERAL.NO_DATA" />
        </span>
      ) : (
        <Card className="w-60 h-100 mr-3">
          <Card.Header>
            <Card.Title className="d-flex justify-content-between py-0 my-0 text-cus-heading">
              <span className="my-auto">
                <LanOutlinedIcon className="mr-2" />
                {selectedSchedule.name}
              </span>
              {selectedSchedule.editable && (
                <div>
                  <BeSafeButton
                    variant="transparent"
                    className="p-0 px-1"
                    onClick={showEditScheduleModal}
                    icon={<Edit className="icon-table-md" />}
                    tooltip="GENERAL.EDIT_SMALL"
                  />
                  <BeSafeButton
                    variant="transparent"
                    className="p-0 px-1"
                    onClick={deleteScheduleObject}
                    icon={<Delete className="icon-table-md" />}
                    tooltip="GENERAL.DELETE_SMALL"
                  />
                </div>
              )}
            </Card.Title>
          </Card.Header>
          <Card.Body className="overflow-auto">
            <div className="d-flex py-2">
              <span className="font-weight-bold">
                <FormattedMessage id="GENERAL.DESCRIPTION" />
              </span>
              <span>{`: ${selectedSchedule?.description || ""}`}</span>
            </div>
                {
                  <>
                    <div className="d-flex py-2">
                      <span className="font-weight-bold">
                        <FormattedMessage id="TIME.DAYS_OF_WEEK" />
                      </span>
                      <span>{`:
                        ${time.weekDays
                          .map((day) =>
                            intl.formatMessage({
                              id: `TIME.MEDIUM_${weekDays[day].toUpperCase()}`
                            })
                          )
                        }
                      `}</span>
                    </div>

                    <div className="d-flex py-2">
                      <span className="font-weight-bold">
                        <FormattedMessage id="OBJECT_EXPLORER.SCHEDULE" />
                      </span>:&nbsp;
                      <span>
                        {
                          time.allDay ? <FormattedMessage id="OBJECT_EXPLORER.ALL_DAY" />
                            : `${time.startTime} - ${time.stopTime}`
                        }
                      </span>
                    </div>

                    <div className="d-flex py-2">
                      <span className="font-weight-bold">
                        <FormattedMessage id="TIME.TIMEZONE" />
                      </span>
                      <span>{`:
                        ${time.timezone}
                      `}</span>
                    </div>
                  </>
                }
          </Card.Body>
          <Card.Footer className="obj-where-used">
            <p className="font-weight-bold my-0">
              <FormattedMessage id="GENERAL.WHERE_USED" />:
            </p>
            <span>
              {whereUsed?.length <= 0 ? (
                <FormattedMessage id="GENERAL.NOT_IN_USE" />
              ) : (
                whereUsed?.map((r) => r).join(", ")
              )}
            </span>
          </Card.Footer>
        </Card>
      )}
    </>
  );
};

export default ScheduleSubList;
