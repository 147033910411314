import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Table, Button, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';

import {
  getNetworks,
  getNetworksGroups,
  deleteNetwork,
  deleteNetworksGroup,
} from 'app/crud/networks.crud';
import {
  objectDatas,
  networkObjectDatas,
  serviceObjectDatas,
  recordObjectDatas,
  filterDnsRecords,
} from 'utils/utils';
import NoDataDisplay from 'components/commons/NoDataDisplay';
import TableLoader from 'components/commons/TableLoader';
import BeSafeButton from 'components/commons/BeSafeButton';
import BeSafeTooltip from 'components/commons/BeSafeTooltip';
import BeSafeSearchBar from 'components/commons/BeSafeSearchBar';
import AddObjectModal from './AddObjectModal';
import { successToast, errorToast, warningToast } from 'utils/toastUtils';
import { ConfirmationPopup } from 'components/ConfirmDialog';
import { Add, Delete, Edit } from '@mui/icons-material';
import BeSafeSeparator from 'components/commons/BeSafeSeparator';

const NetworksListSelect = (props) => {
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(
    props.initialSelected || []
  );
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(
    props.objectType ||
      (props.allowedNetworks.length > 0 ? props.allowedNetworks[0] : '')
  );
  const [allowedNetworks, setAllowedNetworks] = useState([]);
  const [query, setQuery] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const ipProtocol = props.ipProtocol ?? 'ipv4';
  const usedList = [];

  const intl = useIntl();

  const selectRows = (item) => {
    if (
      _.findIndex(selectedItems, (i) => {
        return i.id === item.id;
      }) >= 0
    ) {
      setSelectedItems((prev) =>
        selectedItems.filter((si) => si.id !== item.id)
      );
    } else {
      if (props.limit !== 0 && selectedItems.length >= props.limit) {
        return;
      }
      setSelectedItems((prev) => [...prev, item]);
    }
  };

  const handleSubmit = () => {
    if (_.isFunction(props.changeItems)) {
      props.changeItems(selectedItems);
      handleHide();
    }
  };

  const handleHide = () => {
    setQuery('');
    setSelectedItems([]);
    props.onHide();
  };

  const filterNetworkGroups = useCallback(
    (groups) => {
      return groups.filter((n) => n.ip_protocol === ipProtocol);
    },
    [ipProtocol]
  );

  const filterNetworks = useCallback(
    (networks) => {
      if (ipProtocol === 'both') {
        return networks;
      } else if (ipProtocol === 'ipv4') {
        return networks.filter((n) => n.target && n.target.length > 1);
      }

      return networks.filter(
        (n) => n.target_secondary && n.target_secondary.length > 1
      );
    },
    [ipProtocol]
  );

  const filterNetworksByMask = (networks, networkMaskFilter) => {
    return networks.filter((network) => {
      let result =
        Number(network?.ip_address.split('/')[1]) === networkMaskFilter;
      if (networkMaskFilter === 32) {
        result =
          result ||
          (network?.ip_address !== '' &&
            typeof network?.ip_address?.split('/')[1] === typeof undefined) ||
          Number(network?.ipv6_address?.split('/')[1]) === 128;
      }
      return result;
    });
  };

  const fetchItems = useCallback(
    (query) => {
      setLoading(true);
      if (props.showModal) {
        if (selectedType.includes('group')) {
          getNetworksGroups({ type: selectedType.split('-group')[0], q: query })
            .then((response) => {
              let groups = response.data.objects.map((n) => {
                n.type = `${selectedType}-group`;
                return n;
              });

              if (
                selectedType.match(
                  /^(network-group|network_range-group|user-group)$/
                )
              ) {
                groups = filterNetworkGroups(groups);
              }

              setItems(groups);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          const params = { type: selectedType, q: query };
          if (props.onlyEncryptedServices) params.encrypted = true;
          getNetworks(params)
            .then((response) => {
              let networks = response.data.objects.map((n) => {
                n.type = selectedType;
                return n;
              });

              if (selectedType.match(/^(network|network_range|user)$/)) {
                networks = filterNetworks(networks);
              } else if (selectedType.match(/^dns_record$/)) {
                networks = filterDnsRecords(
                  networks,
                  props.search_protection_config
                );
              }

              if (
                typeof props.networkMaskFilter !== typeof undefined &&
                selectedType === 'network'
              ) {
                networks = filterNetworksByMask(
                  networks,
                  props.networkMaskFilter
                );
              }
              setItems(networks);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    },
    [
      selectedType,
      filterNetworkGroups,
      filterNetworks,
      props.showModal,
      props.networkMaskFilter,
      props.search_protection_config,
      props.onlyEncryptedServices,
    ]
  );

  useEffect(() => {
    if (props.showModal) fetchItems(query);
  }, [selectedType, props.showModal, fetchItems, query]);

  useEffect(() => {
    if (props.showModal && props.initialSelected)
      setSelectedItems(props.initialSelected);
  }, [props.showModal, props.initialSelected]);

  const changeObject = (e) => {
    setSelectedType(e.target.value);
  };

  function getAllowedNetworksText(selectType, item) {
    if (selectType.match(/^(source|destination)$/)) {
      return networkObjectDatas[item]?.text;
    } else if (selectType.match(/^service$/)) {
      return serviceObjectDatas[item]?.text;
    } else if (selectType.match(/^record$/)) {
      return recordObjectDatas[item]?.text;
    } else if (selectType === 'tcp_service') {
      return serviceObjectDatas['tcp_service']?.text;
    } else {
      return '';
    }
  }

  const selectItemOnClick = (item) => {
    const prevItems = [...selectedItems];
    if (_.includes(prevItems, item)) {
      const newItems = _.without(prevItems, item);
      setSelectedItems(newItems);
    } else {
      if (props.limit !== 0 && selectedItems.length >= props.limit) {
        return;
      }
      setSelectedItems([...prevItems, item]);
    }
  };

  const getItemName = (item) => {
    if (['network', 'network_range'].includes(item.type)) {
      if (item.network_type === 'lan') {
        return `${
          item.name +
          ', ' +
          intl.formatMessage({ id: 'NETWORK_OBJECTS.TYPE' }) +
          ': LAN'
        }`;
      } else {
        return `${
          item.name +
          ', ' +
          intl.formatMessage({ id: 'NETWORK_OBJECTS.TYPE' }) +
          ': No-LAN'
        }`;
      }
    }

    if (item.type === 'mac') {
      return `${
        item.name +
        ', ' +
        intl.formatMessage({ id: 'NETWORK_OBJECTS.MAC_ADDRESS' }) +
        `: ${item.mac_address}`
      }`;
    }
    return item.name;
  };

  useEffect(() => {
    setAllowedNetworks(props.allowedNetworks);
    setSelectedType(
      props.objectType ||
        (props.allowedNetworks.length > 0 ? props.allowedNetworks[0] : '')
    );
  }, [props.allowedNetworks, props.objectType]);

  const getTitle = (tag) => {
    let title = 'GENERAL.DATA';

    if (tag === 'portrange') {
      title = 'NETWORK_OBJECTS.PORT';
    } else if (tag.includes('target')) {
      title = 'NETWORK_OBJECTS.IP';
    }

    return title;
  };

  const getTooltip = (item, tags) => {
    let tooltips = [];

    tags.forEach((tag) => {
      const title = getTitle(tag);
      const obj = tag.includes('target') ? item[tag] : item.data?.[tag];

      if (obj) {
        tooltips.push(`${intl.formatMessage({ id: title })}: ${obj}`);
      }
    });

    if (tooltips.length === 0) {
      return '';
    }

    return (
      <>
        {tooltips.map((tooltip) => (
          <span className="d-block">{tooltip}</span>
        ))}
      </>
    );
  };

  const isItemDisabled = (item) => {
    return item.object_type === 'dns_record' && item.data?.search_engine;
  };

  const showEditObjectModal = (network) => {
    setEditMode(true);
    setShowModal(true);
    setSelectedObject(network);
  };

  const showNewObjectModal = () => {
    setEditMode(false);
    setShowModal(true);
  };

  const deleteObject = (item) => {
    if (usedList.length > 0) {
      warningToast({
        body: 'NETWORK_OBJECTS.ALREADY_IN_USE',
        intl: intl,
      });
    } else {
      ConfirmationPopup({
        title: intl.formatMessage({
          id: 'GENERAL.WARNING',
        }),
        description: intl.formatMessage({
          id: 'GENERAL.CONFIRM_DELETE',
        }),
        okLabel: intl.formatMessage({
          id: 'GENERAL.OK',
        }),
        cancelLabel: intl.formatMessage({
          id: 'GENERAL.CANCEL',
        }),
        okAction: () => {
          const delNetwork = item.object_type
            ? deleteNetwork(item.id)
            : deleteNetworksGroup(item.id);
          delNetwork
            .then((response) => {
              let vals = items.filter((nw) => nw.id !== item.id);
              setItems(vals);
              successToast({
                body: 'NETWORK_OBJECTS.DELETE_SUCCESS',
                intl: intl,
              });
            })
            .catch((err) => {
              if (err.response.data.already_in_used) {
                errorToast({
                  body: 'NETWORK_OBJECTS.ALREADY_IN_USE',
                  intl: intl,
                });
              } else {
                errorToast({
                  body: err.response.data?.error,
                  intl: intl,
                });
              }
            });
        },
      });
    }
  };

  const onCreateObject = (updatedObject) => {
    fetchItems(query);
    setShowModal(false);
    if (Object.keys(selectedObject).length !== 0) {
      const selectedItem = selectedItems.find(
        (item) => item.id === selectedObject.id
      );
      if (selectedItem) {
        selectedItem.name = updatedObject.name;
      }
    }
  };

  const onHide = () => {
    setEditMode(false);
    setSelectedObject({});
    setShowModal(false);
  };

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={handleHide}
        size="lg"
        centered
        dialogClassName="modal-shadow-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id={`NETWORK_OBJECTS.${props.selectType.toUpperCase()}_SELECT`}
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="pt-3">
          <div className="w-100 d-flex align-items-center">
            <div className="w-100">
              <BeSafeSearchBar onSearch={setQuery} />
            </div>
            {!props.objectType && (
              <>
                {selectedType !== 'geolocation' && (
                  <BeSafeButton
                    variant="transparent"
                    className="py-0 ml-auto px-1"
                    onClick={showNewObjectModal}
                    icon={<Add />}
                    tooltip="GENERAL.ADD"
                  />
                )}
                <Form.Group className="mr-2 mb-0">
                  <Form.Control
                    className="w-auto"
                    as="select"
                    value={selectedType}
                    onChange={(e) => changeObject(e)}
                  >
                    {allowedNetworks.map(
                      (item, index) =>
                        (props.selectType !== 'destination' ||
                          !item.match(/^(user|user-group)$/)) && (
                          <option key={index} value={item}>
                            {intl.formatMessage({
                              id: getAllowedNetworksText(
                                props.selectType,
                                item
                              ),
                            })}
                          </option>
                        )
                    )}
                  </Form.Control>
                </Form.Group>
              </>
            )}
          </div>
          <BeSafeSeparator />
          <TableLoader rows={8} visible={loading} />
          <NoDataDisplay visible={!loading && items.length <= 0} />
          {!loading && items.length > 0 && (
            <div className="modal-selection-list overflow-auto">
              <Table className="mb-0 table-borderless" hover>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={index}>
                      <td className="border-bottom">
                        <div className="d-flex justify-content-between">
                          <Form.Group className="mb-0 d-flex justify-content-between align-items-center w-100">
                            <Form.Check
                              id={item.id}
                              className="mb-0"
                              type="checkbox"
                              onClick={() => selectItemOnClick(item)}
                            >
                              <Form.Check.Input
                                type="checkbox"
                                isValid
                                value={selectedItems.some(
                                  (si) =>
                                    si.id === item.id && si.name === item.name
                                )}
                                checked={selectedItems.some(
                                  (si) =>
                                    si.id === item.id && si.name === item.name
                                )}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => selectRows(item)}
                                disabled={isItemDisabled(item)}
                              />
                              <Form.Check.Label
                                onClick={(e) => e.stopPropagation()}
                              >
                                <i
                                  className={`${
                                    objectDatas[item.type]
                                      ? objectDatas[item.type].icon
                                      : objectDatas['default'].icon
                                  } mr-1`}
                                ></i>
                                {props.tooltipData ? (
                                  <BeSafeTooltip
                                    tooltip={getTooltip(
                                      item,
                                      props.tooltipData
                                    )}
                                  >
                                    <span className="d-inline-block">
                                      {getItemName(item)}
                                    </span>
                                  </BeSafeTooltip>
                                ) : (
                                  <span>{getItemName(item)}</span>
                                )}
                                {item.type === 'application-site-category' &&
                                item.name === 'Very Low Risk' ? (
                                  <div className="badge badge-success ml-2">
                                    1
                                  </div>
                                ) : null}
                                {item.type === 'application-site-category' &&
                                item.name === 'Low Risk' ? (
                                  <div className="badge badge-warning ml-2">
                                    2
                                  </div>
                                ) : null}
                                {item.type === 'application-site-category' &&
                                item.name === 'Medium Risk' ? (
                                  <div className="badge badge-medium ml-2">
                                    3
                                  </div>
                                ) : null}
                                {item.type === 'application-site-category' &&
                                item.name === 'High Risk' ? (
                                  <div className="badge badge-danger ml-2">
                                    4
                                  </div>
                                ) : null}
                                {item.type === 'application-site-category' &&
                                item.name === 'Critical Risk' ? (
                                  <div className="badge badge-very-high ml-2">
                                    5
                                  </div>
                                ) : null}
                              </Form.Check.Label>
                            </Form.Check>
                            {item.editable && (
                              <div>
                                {selectedType !== 'fqdn' &&
                                  selectedType !== 'mac' && (
                                    <BeSafeButton
                                      variant="transparent"
                                      className="p-0 px-1"
                                      onClick={() => showEditObjectModal(item)}
                                      icon={<Edit className="icon-table-md" />}
                                      tooltip="GENERAL.EDIT_SMALL"
                                    />
                                  )}
                                <BeSafeButton
                                  variant="transparent"
                                  className="p-0 px-1"
                                  onClick={() => deleteObject(item)}
                                  icon={<Delete className="icon-table-md" />}
                                  tooltip="GENERAL.DELETE_SMALL"
                                />
                              </div>
                            )}
                          </Form.Group>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button variant="transparent" onClick={props.onHide}>
            <FormattedMessage id="OBJECT_MODAL.CANCEL" />
          </Button>
          <BeSafeButton
            variant="primary"
            type="button"
            onClick={handleSubmit}
            text={<FormattedMessage id="GENERAL.SAVE" />}
          />
        </Modal.Footer>
      </Modal>
      <AddObjectModal
        type={selectedType}
        editMode={editMode}
        showModal={showModal}
        onHide={onHide}
        onCreate={() => onCreateObject()}
        onUpdate={(updatedObject) => onCreateObject(updatedObject)}
        selectedObject={selectedObject}
        selectType={props.selectType}
      />
    </>
  );
};

export default NetworksListSelect;
