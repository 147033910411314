const flatten = require('flat');
const messages = {
  GENERAL: {
    ACTIONS: 'Acciones',
    ACTION: 'Acción',
    ACTIVATE: 'Activar',
    ADD_MULTI_INPUT: 'Añade una entrada',
    ADD: 'Añadir',
    ALL: 'Todo',
    ANY: 'Todos',
    APPEND: 'AGREGAR',
    APPLICATIONS: 'Aplicaciones',
    APPLY: 'Filtrar',
    BACK: 'Atrás',
    BOTH: 'Ambos',
    BROWSE: 'Seleccionar',
    CANCEL: 'Cancelar',
    CASB_RULES: 'Reglas CASB',
    CLEAR_FILTERS: 'Limpiar Filtros',
    CONFIGURATION: 'Configuración',
    CONFIRM_CONTINUE: '¿Está seguro de que quiere continuar?',
    CONFIRM_DELETE: '¿Está seguro de que quiere eliminar esto?',
    CONFIRM_ERROR: 'La confirmación es incorrecta',
    COPY: 'COPIAR',
    CREATED: 'Creado',
    CTRL_CLICK: 'Ctrl + click para seleccionar múltiples',
    CUSTOM: 'Personalizado',
    DEFAULT: 'Por defecto',
    DELETE_COMPLETELY: 'Eliminar completamente',
    DELETE_SMALL: 'Eliminar',
    DELETE_SUCCESS: 'Eliminado correctamente',
    DELETE: 'ELIMINAR',
    DESCRIPTION_PLACEHOLDER: 'Descripción...',
    DESCRIPTION: 'Descripción',
    DETAILS: 'Detalles',
    DISCARD_SESSION:
      '¿Está seguro? Esto descartará los cambios de otros usuarios',
    DOWNLOAD: 'Descargar',
    DUPLICATED_NAME: 'Nombre ya usado',
    INVALID_NAME: 'El formato del nombre no es válido',
    ERROR_NAME_LENGTH: 'La longitud del nombre no es válida',
    ERROR_NAME_LENGTH_CUSTOM:
      'La longitud del nombre debe ser entre {min_length} y {max_length}',
    DYNAMIC_IP: 'IP dinámica',
    EDIT_OBJECT: 'Editar objeto',
    EDIT_SMALL: 'Editar',
    EDIT: 'EDITAR',
    EMAIL: 'Email',
    ENTER_ADD: 'Añade un uuid y presiona enter para añadir',
    ERROR: 'Error',
    EXPORT: 'Exportar',
    GENERAL: 'General',
    GENERATE: 'Generar',
    GLOBAL_SETTINGS: 'Configuración global',
    HOSTNAME: 'Nombre de host',
    IP: 'Ip',
    ID: 'ID',
    DOMAIN_NAME: 'Nombre de dominio',
    DOMAIN_NAME_PLACEHOLDER: 'video.google.com',
    SEARCH_ENGINE: 'Motor de búsqueda',
    SEARCH_ENGINE_PLACEHOLDER: 'google',
    INFORMATION: 'Información',
    INSTALLING: 'Instalando configuración',
    INSTANCE: 'Instancia',
    INTERNAL_ERROR: 'Error interno',
    INVALID: 'Inválido',
    INVALID_CONFIRM_PASSWORD: 'La contraseña no coincide',
    INVALID_DNS: 'El DNS no es válido',
    MISSING_DNS: 'Añade un DNS válido',
    INVALID_API_KEY: 'Formato de API Key no válido',
    INVALID_EMAIL: 'Email inválido',
    INVALID_FORMAT_EMAIL: 'Formato de Email no válido',
    INVALID_FIELD: 'Campo inválido',
    INVALID_IP: 'La IP no es válida',
    INVALID_DOMAIN_NAME: 'El nombre de dominio no es válido',
    INVALID_OBJECT_NAME: 'Nombre de objeto inválido',
    INVALID_PASSWORD:
      'La contraseña debe contener al menos una mayuscula y un numero',
    INVALID_PORT: 'El puerto no es válido',
    INVALID_URL: 'Formato de URL no válido',
    LOAD_FILE: 'Cargar archivo',
    LOADING_MORE: 'Cargando...',
    LOADING: 'Cargando',
    LOCKED: 'Bloqueado',
    MAX_NAME_SIZE: 'El nombre debe tener un máximo de 64 caracteres',
    MIN_LENGTH: 'La longitud mínima debería ser {length}',
    NAME_PLACEHOLDER: 'Nombre...',
    NAME: 'Nombre',
    NEXT: 'Siguiente',
    NO_DATA: 'No hay información para mostrar',
    NO_FILE: 'Ningún archivo seleccionado',
    NO: 'No',
    NONE: 'Ninguno/a',
    NOT_IN_USE: 'No usado',
    NOW: 'Ahora',
    OK: 'Ok',
    OTHERS: 'Otros',
    PENDING: 'Pendiente',
    PLACEHOLDER_SEARCH: 'Buscar...',
    PLACEHOLDER_URL: 'https://www.exaple.com',
    PREVIOUS: 'Anterior',
    PROPERTY: 'Propiedad',
    REFRESH: 'Actualizar',
    REQUIRED_FIELD: 'Campo requerido',
    RESET: 'REINICIAR',
    RETURN: 'Volver',
    SAVE_SMALL: 'Guardar',
    SAVE_SUCCESS: 'Guardado correctamente',
    SAVE: 'GUARDAR',
    SEARCH: 'Buscar',
    SELECT_FILE: 'Seleccionar archivo',
    SELECT: '-- Seleccione una opción --',
    SPECIFY: 'Específico',
    STATIC_IP: 'IP estática',
    STATUS: 'Estado',
    SUCCESS: 'Correcto',
    SUPPORT: 'Contacta soporte',
    TENANTS: 'Clientes',
    TIME: 'Tiempo',
    TOTAL: 'TOTAL',
    TOTAL_SELECTED: 'Total seleccionados',
    UNKNOWN: 'Desconocido',
    UPDATE_SUCCESS: 'Actualizado correctamente',
    UPDATE: 'ACTUALIZAR',
    UPLOAD: 'Cargar',
    UPDATED: 'ACTUALIZADO',
    WARNING: 'Advertencia',
    WHERE_USED: 'Donde se usa',
    WRONG_FILE_FORMAT: 'El formato del archivo es incorrecto',
    YES: 'Sí',
    DATA: 'Dato',
    INVALID_UUID: 'UUID inválido',
    DUPLICATED_UUID: 'UUID duplicado',
    COMMENTS: 'Comentarios',
    DUPLICATED_INTERFACE_NAME: 'Nombre de interfaz ya usado',
    FOUND_ERRORS: 'Errores encontrados',
    NEXT_ERROR: 'Siguiente error',
    PREVIOUS_ERROR: 'Error anterior',
  },
  SIDEBAR: {
    HOME: 'Inicio',
    ANALYTIC: 'Analítica',
    DASHBOARD: 'Dashboard',
    ADMINISTRATION: 'Administración',
    DEVICES: 'Dispositivos',
    DEVICE: 'Dispositivo',
    INSTANCE_CONFIGURATION: 'Instance Configuration',
    CONFIGURATION: 'Configuración',
    REPORTS: 'Informes',
    LOGS: 'Logs de tráfico',
    REPORT_TEMPLATES: 'Plantillas de informes',
    LOGS_REPORTS: 'Log & Informes',
    SCHEDULE: 'Programación',
    XDR: 'XDR',
    NO_TITLE: 'Sin título',
    USER_LOGINS: 'Logins de usuario',
  },
  NAVBAR: {
    LOGOUT: 'Cerrar sesión',
    CHANGES: 'Cambios',
    VIEW_CHANGES: 'Ver cambios',
    OBJECT_EXPLORER: 'Explorador de objetos',
    INSTALL_CONFIGURATION: 'Instalar configuración',
    INSTALLING: 'Instalando...',
    SUCCESS_INSTALL_CONFIGURATION: 'Configuración instalada correctamente',
    ERROR_INSTALL_CONFIGURATION: 'Error al instalar la configuración',
    WAIT_INSTALL_CONFIGURATION:
      'Espera hasta que termine la instalación en curso',
    DISCARD_CHANGES: 'Descartar los cambios',
    SUCCESS_DISCARD_CHANGES: 'Cambios descartados correctamente',
    CONFIRM_DISCARD_CHANGES:
      '¿Está seguro que desea descartar los cambios pendientes?',
    PROFILE: 'Perfil',
    API_KEY: 'API KEY',
    NOTIFICATIONS: 'Notificaciones',
    VIEW_NOTIFICATIONS: 'Ver notificaciones',
    UTM_LICENSE_EXCEPTION:
      'Error al instalar la configuración. Licencia de UTM <{license}> no encontrada o expirada para el dispositivo <{device}> y el perfil <{profile}>',
    CLOUD_LICENSE_EXCEPTION:
      'Error al instalar la configuración. Licencia de Cloud <{license}> no encontrada o expirada para el perfil <{profile}>',
    INTERFACE_EXCEPTION:
      'Error al instalar la configuración. La interfaz <{interface}> no se encuentra en el dispositivo <{device}> para el perfil <{profile}>',
    UTM_PROFILE_EXCEPTION:
      'Error al instalar la configuración. No hay perfil configurado en dispositivo <{device}>',
    MANUAL: 'Manual',
  },
  ADMIN_USERS: {
    ACTIONS: 'Acciones',
    ADD_TENANT: 'Añadir cliente',
    ADD_USER_ERROR: 'Error al crear usuario',
    ADD_USER_SUCCESS: 'Usuario añadido correctamente',
    ADD_USER: 'Añadir administrador',
    ADMINISTRATORS: 'Administradores',
    AZURE: 'Azure',
    BE_ACTIVE: 'Be Active',
    BE_ACTIVE_ALIAS: 'Alias',
    BE_ACTIVE_CONFIGS: 'Configuraciones Be Active',
    BE_ACTIVE_CONFIG_SAVE_SUCCESS: 'Configuración Be Active guardada con éxito',
    BE_ACTIVE_CONFIG_EDIT_SUCCESS:
      'Configuración Be Active actualizada con éxito',
    BE_ACTIVE_DELETE_CONFIG_SUCCESS:
      'Configuración Be Active eliminada con éxito',
    BE_ACTIVE_EDIT: 'Editar configuración Be Active',
    BE_ACTIVE_INVALID_CREDETIALS:
      'Credenciales no válidas. Revise sus datos de conexión',
    BE_ACTIVE_NEW: 'Nueva configuración Be Active',
    BE_ACTIVE_PASSWORD: 'Contraseña',
    BE_ACTIVE_TIME_SYNC: 'Tiempo de sincronización',
    BE_ACTIVE_TIME_SYNC_INFO: 'Rango de 1 a 180 minutos',
    BE_ACTIVE_URL: 'URL',
    BE_ACTIVE_URL_PLACEHOLDER: 'https://beactive.com',
    BE_ACTIVE_URL_ERROR: 'URL no válida',
    BE_ACTIVE_URL_REQUIRED: 'URL requerida',
    BE_ACTIVE_USER: 'Usuario',
    CONFIRM_DELETE_TENANT: '¿Está seguro de que quiere eliminar el cliente?',
    CONFIRM_DELETE: '¿Está seguro de que quiere eliminar el usuario?',
    CONFIRM_ERROR: 'La confirmación es incorrecta',
    CONNECTION_SETTINGS: 'Ajustes de conexión',
    DELETE_SUCCESS: 'Usuario eliminado correctamente',
    DESCRIPTION: 'Usuarios',
    EDIT_TENANT: 'Editar cliente',
    EMAIL_PLACEHOLDER: 'nombre@empresa.com',
    EMAIL: 'Email',
    FIRST_NAME: 'Nombre',
    INACTIVE_TIME: 'Tiempo máximo inactivo (entre 2 y 60 minutos)',
    LAST_NAME_PLACEHOLDER: 'Moreno Martín',
    LAST_NAME: 'Apellido',
    LOAD_ERROR: 'Error al cargar Usuarios',
    NAME_PLACEHOLDER: 'Antonio',
    NEW_USER: 'Añadir usuarios',
    PASSWORD_PLACEHOLDER: 'Welcome123$',
    PASSWORD: 'Contraseña',
    PROTECTED: 'Protegido',
    RECEIVE_MAILS: 'Recibir mails',
    REPEAT_PASSWORD: 'Repetir Contraseña',
    SERVER_CONNECTIONS: 'Conexiones de servidor',
    TENANT_ID: 'ID del cliente',
    TENANT_NAME: 'Nombre del cliente',
    TENANT_TYPE: 'Tipo del cliente',
    TYPE: 'Rol',
    USER: 'Usuario',
    WRONG_INACTIVE_TIME:
      'El máximo tiempo inactivo debe estar entre {min} y {max} minutos',
    AZURE_AD_CONFIG: 'Configuración Azure AD',
    AD_TENANT_NAME: 'Nombre del Tenant AD',
    AD_TENANT_ID: 'ID del Tenant AD',
    AD_CLIENT_ID: 'ID del Cliente AD',
    AD_CLIENT_SECRET: 'Código secreto del Client AD',
    ADD_AZURE_AD_CONFIG: 'Añadir Configuración Azure AD',
    EDIT_AZURE_AD_CONFIG: 'Editar Configuración Azure AD',
    AD_CONFIG_SAVE_SUCCESS: 'Configuración Azure AD guardada con éxito',
    AD_CONFIG_EDIT_SUCCESS:
      'Configuración Azure AD Configuration actualizada con éxito',
    DELETE_AD_CONFIG: '¿Está seguro de que quiere eliminar esta configuración?',
    DELETE_AD_CONFIG_SUCCESS: 'Configuración Azure AD eliminada con éxito',
    AGENT_CONFIG_PULL_INTERVAL:
      'Configuración intervalo Pull del Cliente VPN (minutos)',
    WRONG_MIN_CONFIG_INTERVAL:
      'El intervalo del Agente debe ser mayor o igual que 1',
    WRONG_MAX_CONFIG_INTERVAL:
      'El intervalo del Agente debe ser menor o igual que 99',
    WINDOWS_BUILD_CONFIRM:
      '¿Está seguro de que quiere construir el ejecutable de Windows?',
    WINDOWS_EXE_EMAIL_SUCCESS: 'Email enviado con éxito',
    WINDOWS_EXE_DOWNLOAD_SUCCESS: 'Ejecutable de Windows descargado con éxito',
    SUCCESS_BUILD:
      'Build iniciado satisfactoriamente. El ejecutable estará disponible en unos minutos. Por favor, espera a que el proceso se complete.',
    BUILD_STATUS:
      'Un build está actualmente en progreso. Por favor, intenta la creación en masa después de 5 minutos.',
    PAGE_RELOAD:
      'Por favor, recarga o refresca la alerta para aplicar los cambios',
    CONFIRM_LOG_AS_USER:
      '¿Está seguro de que quiere iniciar sesión como este usuario?',
    ERROR_LOG_AS_USER: 'Error al iniciar sesión como usuario',
    ERROR_NO_USER: 'No se encontró el usuario',
    ERROR_NOT_ALLOWED:
      'Este usuario no tiene permisos para iniciar sesión con otro usuario',
    ERROR_SAME_USER: 'No puedes iniciar sesión como el mismo usuario',
    RESELLER_TENANT_SELECT: 'Seleccionar cliente',
    RESELLER_TENANT_IN_USE:
      'Al menos uno de los clientes ya se encuentra en uso',
    TENANT_HAS_RESELLER: 'tiene integrador',
  },
  ADMIN_IP_WHITELISTS: {
    IP_WHITELISTS: 'Lista blanca IPs',
    NAME: 'Nombre',
    ROLE: 'Rol',
    ORIGIN: 'Origen',
    ERROR_FETCH: 'Error obteniendo lista blanca',
    SUCCESS_DELETE: 'Registro eliminado con éxito',
    ERROR_DELETE: 'Error al eliminar registro',
    SUCCESS_CREATE: 'Registro creado con éxito',
    ERROR_CREATE: 'Error al crear registro',
    SUCCESS_UPDATE: 'Registro actualizado con éxito',
    ERROR_UPDATE: 'Error al actualizar registro',
    EDIT_IP_WHITELIST: 'Editar lista blanca',
    ADD_IP_WHITELIST: 'Crear lista blanca',
    INVALID_WHITELIST: 'Datos de lista blanca inválidos',
    ERROR_INDEX: 'Error al obtener datos',
    ACCOUNT: 'Tenant',
    ORIGIN_HELP: '* para cualquiera o lista de IPv4s separadas por ;',
  },
  ACCOUNT: {
    UPDATE_PORTS_SUCCESS: 'Puertos actualizados correctamente',
    UPDATE_PORTS_ERROR: 'Error al actualizar los puertos',
  },
  ADMINISTRATION: {
    PERFORMANCE_MODE: 'Modo rendimiento',
    PERFORMANCE_SETTINGS: 'CONFIGURACIÓN RENDIMIENTO',
    PERFORMANCE_WARNING: 'Está por cambiar el modo de rendimiento',
    AUDIT: 'Auditoría',
    AUDIT_LOG_AS: '{impersonator} como {user}',
    TITLE: 'Prevención de amenazas',
    PROFILE_PROTECTION: 'Perfil de protección',
    TECHNOLOGIES: 'Tecnologías',
    SECURITY_PROTECTION:
      'Seguridad optimizada para los usuarios. Evita los ciberataques. Incluye protección para usuarios que navegan por la web, trabajan con aplicaciones de escritorio, acceden a aplicaciones corporativas, comparten archivos a través de FTP o usan otros protocolos de red. Este es el perfil recomendado para múltiples protecciones en el tráfico entre usuarios y aplicaciones.',
    PROTECTION: 'protección',
    DESCRIPTION: 'Descripción',
    DETAILS: 'Detalles',
    'WEB-FILTER': 'Filtro Web',
    'WEB-FILTER_DESCRIPTION':
      'Proteja su negocio con un filtro web y anti-malware. Puede bloquear el acceso a webs por categorías, paises e incluso bloquear dominios concreto.',
    ANTIVIRUS: 'Antivirus y Anti C&C',
    SSL: 'Inspección SSL',
    'SSL-VPN': 'Vpn SSL',
    'IPSEC-VPN': 'Vpn IPSEC',
    IPS_GROUP: 'Protección IPS',
    IPS: 'Protección IT',
    OT: 'Protección OT',
    DLP: 'DLP',
    APPLICATIONS: 'Control de aplicaciones',
    CONFIGURATION: 'Configuración',
    ACTIVATE_PROTECTION: 'Activar protección',
    ZTNA: 'ZTNA',
    ZTNA_DESCRIPTION: 'Conectar con otros ordenadores',
    'REMOTE-ACCESS': 'Acceso Remoto',
    'REMOTE-ACCESS_DESCRIPTION': 'Conectar con otros ordenadores',
    ANTIVIRUS_DESCRIPTION:
      'Previene, detecta y responde a todas las amenazas que puedan afectar a cualquiera de los activos de su organización.',
    SSL_DESCRIPTION:
      'Descifra el tráfico SSL para evitar amenazas encriptadas entrante a las aplicaciones o saliente de los usuarios a internet.',
    IPS_DESCRIPTION:
      'Sistema de detección y prevención de intrusiones. Monitorización del tráfico en tiempo real.',
    DLP_DESCRIPTION:
      'Aplique reglas para evitar que salga de su organización información sensible a internet.',
    APPLICATIONS_DESCRIPTION:
      'Restringa el acceso a aplicaciones específicas en su organización.',
    ADVANCED_CONFIGURATION: 'Abrir configuración avanzada',
    FIREWALL_DESCRIPTION: 'Protección Firewall',
    ANTISPAM_DESCRIPTION: 'Descripción Antispam',
    TYPE: 'Tipo',
    ACTION: 'Acción',
    DATE: 'Fecha',
    LICENSES: 'Licencias',
    LICENSE: 'Licencia',
    LICENSE_GENERATOR: 'Generador de licencias',
    VALID_LICENSE: 'Licencia Válida',
    INVALID_LICENSE: 'Licencia Inválida',
    TIME_EXPIRED: 'Fecha expirada',
    REMAINING_DAYS: 'Días restantes',
    VERSIONS: 'Control versiones',
    FIREWALL: 'Firewall',
    SETTINGS: 'Ajustes',
    NETWORK: 'Network',
    THREAT_PREVENTION: 'Prevención de amenazas',
    ANTISPAM: 'Antispam',
    CONTENT_CONTROL: 'Control de contenido',
    CASB: 'CASB',
    CASB_DESCRIPTION: 'Configuración para CASB',
    'USER-IDENTIFICATION': 'Identificación de usuarios',
    'USER-IDENTIFICATION_DESCRIPTION':
      'Configuración para identificar a los usuarios',
    'VPN-TUNNEL': 'Tunel VPN',
    'VPN-TUNNEL_DESCRIPTION': 'Configuración para Tunel VPN',
    'DNS-FILTER': 'Filtro DNS',
    'DNS-FILTER_DESCRIPTION': 'Descripción DNS filter',
    GLOBAL_SETTINGS: 'Ajustes Globales',
    IP_WHITELISTS: 'Lista blanca IPs',
    CNM_SETTINGS: 'Ajustes de CNM',
    CNM_CONNECTION: 'Conexión CNM',
    API_KEY: 'Clave API',
    ENDPOINT: 'Endpoint',
    EMAIL_SETTINGS: 'Ajustes de Email',
    INACTIVITY_SETTINGS: 'Ajustes de Inactividad',
    NAT: 'NAT',
    LOOK_AND_FEEL: 'Look and feel',
    COLOR: 'Color',
    LOGO: 'Logo',
    REPORT_SETTINGS: 'Ajustes de Informe',
    AUDIT_SETTINGS: 'Ajustes de Auditoría',
    ADD_USER_LOGS_AUDIT: 'Añadir logs de usuario a la Auditoría',
    SYSLOG_SETTINGS: 'Ajustes de Syslog',
    UPLOAD_CERTIFICATE: 'Subir certificado',
    VPN_USERS: 'Usuarios',
    SERVER_ADDRESS: 'Dirección del servidor',
    PORT: 'Puerto',
    DOMAIN: 'Dominio',
    AUTHENTICATION: 'Autentificación',
    USERNAME: 'Nombre de usuario',
    PASSWORD: 'Contraseña',
    NONE: 'Ninguno/a',
    PLAIN: 'PLAIN',
    LOGIN: 'Inicio de sesión',
    CRAM_MD5: 'CRAM MD5',
    PEER: 'Peer',
    OPENSSL: 'Modo verificación OpenSSL',
    STARTTLS: 'Habilitar y usar STARTTLS',
    SMTP: 'Habilitar SMTP sobre TLS',
    CERTIFICATES: {
      TITLE: 'Certificados',
      INFO: 'El fichero cifrado debe contener el certificado y la clave privada',
      PASSWORD_INFO: 'Parámetro opcional',
      ADD: 'Añadir certificado',
      EDIT: 'Editar certificado',
      SUBJECT: 'Sujeto',
      ISSUER: 'Emisor',
      CERTIFICATE_TYPE: 'Tipo de certificado',
      CIPHERED: 'Cifrado',
      UNCIPHERED: 'Sin cifrar',
      PASSWORD: 'Contraseña',
      EXPIRATION_DATE: 'Fecha de expiración',
      VALIDITY: 'Validez en años',
      VALIDITY_WRONG:
        'La validez del certificado debe estar entre {min_year} y {max_year} años',
      SOURCE: 'Origen',
      LOCAL: 'Local',
      CUSTOM: 'Personalizado',
      UPLOAD_CIPHERED_CERTIFICATE: 'Subir un certificado PKCS#12',
      UPLOAD_CERTIFICATE: 'Subir un certificado X-509',
      UPLOAD_KEY: 'Subir la clave privada del certificado',
      ADD_ERROR: 'Error al crear el certificado',
      ADD_SUCCESS: 'Certificado creado con éxito',
      EDIT_ERROR: 'Error al actualizar el certificado',
      EDIT_SUCCESS: 'Certificado actualizado con éxito',
      DELETE_ERROR: 'Error al eliminar el certificado',
      DELETE_SUCCESS: 'Certificado eliminado con éxito',
      NAME_ERROR: 'El nombre no es válido',
      SOURCE_ERROR: 'El origen no es válido',
      VALIDITY_ERROR: 'La validez del certificado no es válida',
      CERT_X509_ERROR: 'El certificado no es válido',
      KEY_RSA_ERROR: 'La clave no es válida',
      VERIFY_ERROR: 'El certificado no puede ser verificado',
      FILE_ERROR: 'Los ficheros de certificado y clave no pueden estar vacíos',
      PKCS12_INVALID_PASSWORD: 'La clave del fichero no es correcta',
      CONFIRM_DELETE: '¿Está seguro de que quiere eliminar este certificado?',
      IN_USE: 'El certificado no se puede eliminar porque está siendo usando',
      NO_CA_ERROR: 'El certificado no es una Autoridad Certificadora (CA)',
      ERROR_CLIENT: 'Error al generar los certificados del cliente',
      ERROR_SERVER: 'Error al generar los certificados del servidor',
      ERROR_DH: 'Error al generar los parámetros DH',
    },
    VPN_SETTINGS: 'Ajustes VPN',
    AD_SETTINGS: 'Ajustes AD',
    AD_SYNC_INTERVAL: 'Syncronizar usuarios de AD (horas)',
    WRONG_MIN_CONFIG:
      'El intervalo de syncronización AD debe ser mayor o igual que 1',
    WRONG_MAX_CONFIG:
      'El intervalo de syncronización AD debe ser menor o igual que 24',
    LOGIN_SETTINGS: 'Inicio de sesión',
    ENABLE_2FA:
      'Activar Doble Factor de Autenticación con una OTP App como Google Authenticator',
    CONFIRM_ENABLE_2FA:
      'Esta acción puede tardar varios minutos. ¿Desea continuar?',
    THREAT_INTEL: 'Teldat Threat Labs',
  },
  APPLICATIONS: {
    ALL_CATEGORIES: 'Todas las categorías',
    TABLE: {
      NAME: 'Nombre',
      DESCRIPTION: 'Descripción',
      ACTIONS: 'Acciones',
      FAMILY: 'Categoría',
    },
    UNRANKED: 'No clasificado',
    TITLE: 'Applications',
    REDIRECT_TO_APPLICATION: 'Go to Applications',
  },
  CNM_CONNECTION: {
    INFO: 'Solo se puede cambiar si no tiene dispositivos registrados',
    TEST_CONNECTION: 'Probar conexión',
    SUCCESS: 'Conexión con éxito',
    AUTHENTICATION_ERROR: 'Error de autenticación',
    CONNECTION_ERROR: 'Error de conexión',
    READ_TIMEOUT: 'Tiempo de lectura de respuesta agotado',
    OPEN_TIMEOUT: 'Tiempo de apertura de conexión agotado',
    BAD_REQUEST: 'Petición incorrecta',
    UNKNOWN_HOST: 'Host desconocido',
    EMPTY_VALUES: 'Campos vacíos',
  },
  SSL_INSPECTION: {
    DOWNLOAD_CERTIFICATE_ERROR: 'Error al descargar el certificado',
    MODE_BASIC: 'Básico',
    MODE_FULL: 'Completo',
    MODE: 'Tipo de inspección SSL',
    SEARCH: 'Buscar',
    SELECT_YOUR_SSL: 'SELECCIONA EL TIPO DE INSPECCIÓN SSL',
    ERROR_FETCH: 'Error al cargar las opciones de la inspección SSL',
    SSL_EXCEPTION_ADD_ERROR: 'Error al crear excepción SSL',
    SSL_EXCEPTION_ADD_SUCCESS: 'Excepción SSL creada con éxito',
    SSL_EXCEPTION_ADD: 'Añadir nueva excepción SSL',
    SSL_EXCEPTION_ANY: 'Todos',
    SSL_EXCEPTION_CONFIRM_DELETE:
      '¿Está seguro de que quiere eliminar esta excepción SSL?',
    SSL_EXCEPTION_DELETE_ERROR: 'Error al eliminar excepción SSL',
    SSL_EXCEPTION_DELETE_SUCCESS: 'Excepción SSL eliminada con éxito',
    SSL_EXCEPTION_EDIT_ERROR: 'Error al actualizar excepción SSL',
    SSL_EXCEPTION_EDIT_SUCCESS: 'Excepción SSL actualizada con éxito',
    SSL_EXCEPTION_EDIT: 'Editar Excepción SSL',
    SSL_EXCEPTION_NAME_PLACEHOLDER: 'Nombre de la excepción SSL...',
    SSL_EXCEPTION: 'Excepciones SSL',
    SSL_MODE_UPDATE_SUCCESS: 'Modo de inspección SSL actualizado con éxito',
    SSL_MODE_UPDATE_ERROR: 'Error al actualizar el modo de inspección SSL',
    CERTIFICATE_UPDATE_SUCCESS: 'Certificado actualizado con éxito',
    CERTIFICATE_UPDATE_ERROR: 'Error al actualizar el certificado',
    CERTIFICATE_ERROR_FETCH: 'Error al cargar las opciones del certificado',
    TITLE: 'Inspección SSL',
    CERTIFICATE: 'Certificado',
    TABLE: {
      ID: 'ID',
      ACTION: 'Acción',
      NAME: 'Nombre',
      SOURCE: 'Origen',
      DESTINATION: 'Destino',
      STATUS: 'Estado',
      ACTIONS: 'Acciones',
    },
  },
  IDENTITY_AWARENESS: {},
  ACCOUNTS: {},
  USERS: {
    ADDITIONAL: 'Información adicional',
    CASB_ID: 'ID de CASB',
    NEW_USER: 'Añadir nuevo usuario',
    NAME: 'Nombre',
    GROUP: 'Grupo',
    EMAIL: 'Email',
  },
  NETWORK_USERS: {
    GENERATE_FILE_PROMPT:
      'Para generar un archivo con los usuarios exportados de tu Directorio Activo, ejecuta el siguiente comando en un terminal Powershell dentro de tu servidor administrador de Directorios Activos',
    DESCRIPTION:
      'Cuando el archivo se cargue, sólo se procesarán las filas válidas.',
  },
  USER_GROUPS: {
    NEW_GROUP: 'Añadir nuevo grupo',
    USERS_LIST: 'Lista de usuarios',
    NAME: 'Nombre',
  },
  LOGS: {
    IPS_MODAL: {
      TYPE: 'Tipo',
      ACTION: 'Acción',
      CATEGORY: 'Categoría',
      ALIAS_NAME: 'Nombre Dispositivo',
      SERIAL_NUMBER: 'Serial',
      SOURCE_IP: 'IP Origen',
      SOURCE_PORT: 'Puerto Origen',
      PROTOCOL: 'Protocolo',
      DEST_IP: 'IP Destino',
      DEST_PORT: 'Puerto Destino',
      TX_BYTES: 'Bytes Transmitidos',
      DATE: 'Fecha',
      SEVERITY: 'Gravedad',
      DESCRIPTION: 'Descripción',
      WEBFILTER_SUBCATEGORY: 'Subcategoría Filtro Web',
      URL: 'URL',
    },
    TABLE: {
      DATE: 'Fecha',
      EVENT: 'Evento',
      SOURCE_IP: 'IP Origen',
      SOURCE_PORT: 'Puerto Origen',
      DEST_IP: 'IP Destino',
      DEST_PORT: 'Puerto Destino',
      PROTOCOL: 'Protocolo',
      ACTION: 'Acción',
      DESCRIPTION: 'Descripción',
      SERIAL: 'Serial',
      ALIAS_NAME: 'Nombre Dispositivo',
    },
  },
  ADMIN: {},
  SESSIONS: {},
  FIREWALLS: {},
  CLUSTERS: {},
  PROFILE: {
    ADD_DEVICE_ERROR: 'Error al asociar dispositivos',
    ADD_DEVICE_SUCCESS: 'Dispositivos asociados con éxito',
    ADD_DEVICES: 'Asociar dispositivos',
    ADD: 'Añadir nuevo perfil',
    API_KEY: 'Api key',
    CLIENT: 'Cliente',
    DEVICES: 'Dispositivos',
    PORTS: 'Puertos de protocolos',
    CONFIRM_DELETE: '¿Desea borrar el perfil?',
    CONFIRM_ERROR: 'Ha ocurrido un error en la confirmación',
    CONFIRM_REFRESH_API_KEY: '¿Está seguro de que desea refrescar la api key?',
    DELETE_SUCCESS: 'Perfil borrado con éxito',
    DEVICES_WITH_FW_PROFILE:
      'Algunos dispositivos tienen instalado un perfil de firewall diferente',
    DEVICES_WITH_PROFILE: 'Hay dispositivos con un perfil asignado',
    DUPLICATE_COPY: 'Copia',
    DUPLICATE_NAME: 'Introducir el nombre del perfil duplicado',
    DUPLICATE: 'Duplicar perfil',
    DUPLICATED_NAME: 'Nombre de perfil ya se encuentra en uso',
    IN_USE: 'No es posible eliminar. Perfil en uso',
    INFO_PROFILE: 'Detalles de la cuenta',
    MAXIMUM_ONE_CLOUD: 'Sólo se permite un perfil Cloud',
    MAXIMUM_REACHED: 'Máximo número de perfiles alcanzado',
    MAXIMUM_UTM_REACHED: 'Máximo número de perfiles UTM alcanzado',
    NAME_MAXIMUM_LENGTH:
      'Solo se permiten 16 caracteres para el nombre del perfil',
    NAME_PLACEHOLDER: 'Nombre del perfil',
    NAME: 'Introducir el nuevo nombre del perfil',
    REFRESH_API_KEY_SUCCESS: 'La api key fue refrescada correctamente',
    SETTINGS: 'Configuración del perfil',
    MISSING_LICENSES: 'No hay licencias disponibles o han expirado',
  },
  DEVICES: {
    ACTIONS: 'Acciones',
    ADD_DEVICE_SUCCESS: 'Dispositivo creado con éxito',
    ADD_DEVICE: 'Añadir nuevo dispositivo',
    ADD_SUBNETWORKS: 'Pulsa Tab para añadir sub-redes',
    ADDRESSING_MODE: 'Modo direccionamiento',
    ADVANCED: 'Avanzado',
    ANTI_SPOOFING_HELP:
      'Activar la verificación de la dirección de origen para habilitar la protección contra IP Spoofing',
    ANTI_SPOOFING_INPUT: 'Habilitar:',
    ANTI_SPOOFING: 'Anti Spoofing',
    ANTIVIRUS_PROFILE: 'Perfil Antivirus',
    APPLICATION_PROFILE: 'Perfil Aplicaciones',
    AUTHENTICATION: 'Autenticación',
    AVAILABLE_LICENSES: 'Licencias disponibles',
    BATCH_HELP: 'Carga masiva',
    TUNNEL_HELP: 'Configuración de túnel',
    HELP: 'Ayuda de dispositivos',
    BATCH_LOAD: 'Carga masiva',
    CASB_PROFILE: 'Perfil CASB',
    AUTO_IKE: 'IKE Automático',
    CLI_EXTENSION: 'Extensión de CLI',
    COMPARE_BASE: 'Base',
    COMPARE_BASE_CLI: 'Lineas de Cli Base',
    COMPARE_TO: 'Comparar con',
    COMPARE_TO_CLI: 'Lineas de Cli Comparación',
    COMPARE_OPTION_CANDIDATE: 'Candidata',
    COMPARE_OPTION_CURRENT: 'Actual',
    COMPARE_OPTION_ROLLBACK: 'Última funcional',
    CONECTION: 'Conexión',
    CONECTION_INFO: 'Información de conexión',
    CONFIGURATION_NOT_SYNCHED: 'Configuración no sincronizada',
    CONFIGURATION_SYNCHED: 'Configuración sincronizada',
    CONFIRM_DELETE: '¿Está seguro de que quiere eliminar este dispositivo?',
    CONFIRM_DISABLE_DNS_FORWARDER:
      'Esta acción puede ocasionar la desactivación del DNS Forwarder. ¿Quieres deshabilitarlo si es posible?',
    CONFIRM_DISABLE_DNS_FORWARDER_ERROR:
      'Error al deshabilitar el DNS Forwarder',
    KEEP_DNS_FORWARDER_ENABLED: 'Mantenerlo habilitado',
    CONNECTION_PROTOCOL: 'Tipo de protocolo',
    CONNECTION: 'Conexión',
    CSV_DVC_EMPTY: 'El campo DVC de la fila {row} está vacío',
    CSV_ERROR_CREATE: 'La creación del dispositivo {name} ha retornado error',
    CSV_IP_DUPLICATED_FILE:
      'El campo IP de la fila {row} ya está siendo usado en el fichero',
    CSV_IP_DUPLICATED: 'El campo IP de la fila {row} ya está en uso',
    CSV_IP_INVALID: 'El campo IP de la fila {row} no es válido',
    CSV_LICENSES_EMPTY: 'El campo Licenses de la fila {row} está vacío',
    CSV_LICENSES_INVALID: 'El campo Licenses de la fila {row} no es válido',
    CSV_MODEL_EMPTY: 'El campo Model de la fila {row} está vacío',
    CSV_MODEL_INVALID: 'El campo Model de la fila {row} no es válido',
    CSV_NAME_DUPLICATED_FILE:
      'El campo Name de la fila {row} ya está siendo usado en el fichero',
    CSV_NAME_DUPLICATED: 'El campo Name de la fila {row} ya está en uso',
    CSV_NAME_EMPTY: 'El campo Name de la fila {row} está vacío',
    CSV_NOT_ENOUGH_COLUMNS:
      'El número de columnas de la fila {row} es incorrecto',
    CSV_SERIAL_EMPTY: 'El campo Serial Number de la fila {row} está vacío',
    CSV_SERIAL_INVALID: 'El campo Serial Number de la fila {row} no es válido',
    CSV_TYPE_INVALID: 'El campo Type de la fila {row} no es válido',
    CSV_CORE_EMPTY: 'El campo Core Number de la fila {row} está vacío',
    CSV_SHOW_INVALID: 'El campo Show de la fila {row} no es válido',
    CSV_NETWORKS_INVALID:
      'Se debe rellenar el campo Objects o Groups de la fila {row}',
    CSV_PRE_SHARED_KEY_INVALID:
      'El campo Pre Shared Key de la fila {row} no es válido',
    CSV_PEER_ID_INVALID: 'El campo Peer ID de la fila {row} no es válido',
    CONFIRM_EVENTS: 'Eliminar también los logs asociados a este dispositivo',
    CONFIRM_ERROR: 'Error al borrar el dispositivo',
    CORE_NUMBER: 'Número de núcleos',
    DELETE_SUCCESS: 'Dispositivo borrado con éxito',
    DESCRIPTION_STEP1:
      'Nombre del dispositivo y tipo son campos obligatorios. Los parámetros opcionales pueden ser configurados posteriormente.',
    DESCRIPTION_STEP2:
      'Nombre del dispositivo y tipo son campos obligatorios. Los parámetros opcionales pueden ser configurados posteriormente.',
    DESCRIPTION_STEP4: 'Selecciona una licencia para asignar al dispositivo',
    DESCRIPTION: 'Lista de dispositivos',
    DEVICE_ALREADY_EXISTS: 'Dispositivo ya existe',
    DEVICE_NAME: 'Nombre de dispositivo',
    DEVICES_GROUP: 'Grupo de dispositivos',
    DEVICES: 'Dispositivos',
    DHCP_CONFIG: 'Servidor DHCP',
    DISMISS: 'Ignorar',
    DLP_PROFILE: 'Perfil DLP',
    DUPLICATE_IP: 'IP ya se encuentra registrada',
    DUPLICATE_NAME: 'Nombre ya registrado',
    DUPLICATE_SERIAL_NUMBER: 'Número de serie ya está registrado',
    E_AUTH_TOKEN_ERROR: 'Sin autorización',
    EDIT_DEVICE: 'Editar dispositivo',
    EMPTY_DVC: 'DVC no puede ser vacio',
    EMPTY_NAME: 'Nombre no puede ser vacío',
    EMPTY_SERIAL: 'Número de serie no puede ser vacio',
    ERROR_CORE_NUMBER: 'Error al actualizar el número de núcleos',
    ERROR_EMPTY: 'Error: Campo vacío',
    ERROR_FIREWALL_DEPTH:
      'Ha ocurrido un error al actualizar la calidad del motor de firewall',
    ERROR_INSTALLATION: 'Error al instalar configuración',
    ERROR_PROFILE: 'Ha ocurrido un error al actualizar el perfil',
    FEATURE_PROFILES: 'Perfiles de funcionalidades',
    FILTER: 'Dispositivos',
    FIREWALL_CLI_HELP:
      "Este fragmento de código tiene parámetros entre '<' y '>'. Personalícelos antes de pegar la configuración en su router.",
    FIREWALL_CLI: 'Firewall CLI',
    FIREWALL_DEPTH: 'Calidad del motor de firewall de próxima generación',
    FIREWALL_DEPTH_HIGH: 'Mejorado',
    FIREWALL_DEPTH_LOW: 'Por defecto',
    SELECT_FIREWALL_PROFILE: 'Seleccione perfil de firewall',
    GENERAL: 'General',
    GROUP_NAME: 'Nombre del grupo',
    IMPORTED_SUCCESS: 'El fichero ha sido importado con éxito',
    IN_PROCESS: 'Tarea en progreso',
    INCORRECT_FILE: 'Fichero incorrecto',
    INSTALL: 'Instalar configuración candidata',
    INTERFACES: 'Interfaces',
    INVALID_AVAILABLE_MEMORY:
      'El pack de reglas no puede ser seleccionado, no hay memoria libre suficiente.\nMemoria libre: {available_memory} MB\nMemoria necesaria: {necessary_memory} MB',
    INVALID_CORE_NUMBER:
      'Número de núcleos no válido. Debe ser un número entre 1 y 64',
    INVALID_DVC: 'DVC inválido',
    INVALID_IP: 'Formato de ip, no es valido',
    INVALID_HOSTNAME: 'Formato de hostname no es válido',
    INVALID_NAME: 'El formato del nombre no es válido',
    INVALID_PRE_SHARED_KEY:
      'El formato de la Pre shared key no es válido. Debe tener un mínimo de 8 de los siguientes carácteres: a-z A-Z 0-9 - + & ! @ # %% ^ * ( ) , . : _',
    NAME_TOO_LONG: 'Longitud de nombre mayor de 63 caracteres',
    NAME_TOO_SHORT: 'Longitud de nombre menor de 2 caracteres',
    INVALID_MEMORY:
      'Los siguientes dispositivos no tienen memoria suficiente para los paquetes seleccionados',
    INVALID_TOTAL_MEMORY:
      'El pack de reglas no puede ser seleccionado, la memoria del equipo no es suficiente.\nMemoria total de {model}: {total_memory} MB\nMemoria necesaria: {necessary_memory} MB',
    IP_ADDRESS: 'Dirección IP',
    IP_DYNAMIC_EXTERNAL: 'Servicio externo IP dinámica',
    IP_DYNAMIC_INTERNAL: 'Servicio Teldat IP dinámica',
    IP_HOSTNAME: 'IP / Hostname',
    IP_STATIC: 'IP Estática',
    IP_TUNNEL: 'Túnel IP',
    IPS_PROFILE: 'Perfil IPS',
    LAST_CONNECTION: 'Última conexión',
    LICENSE: 'Licencia',
    MANUFACTERER: 'Fabricante',
    MODEL: 'Modelo',
    MODEL_OTHER: 'Otro',
    MONITOR: 'Monitor',
    NAME: 'Nombre',
    NETWORK: 'Redes',
    NETWORKS: 'Redes',
    NO_PROFILE: 'Sin perfil de firewall asignado',
    NOT_FOUND: 'Dispositivo no encontrado',
    OT_PROFILE: 'Perfil OT',
    PROFILE: 'Configuración de perfil',
    FIREWALL_PROFILE: {
      TITLE: 'Perfil de firewall',
      CURRENT: 'Perfil actual',
      MODAL_TITLE:
        'Seleccione los disposivitos en los que instalar el perfil de firewall {name}',
    },
    PROFILES: {
      NONE: 'Ninguno',
      MODAL_TITLE:
        'Seleccione los disposivitos en los que instalar el perfil {name}',
    },
    VPN_TUNNEL_PROFILE: 'Perfil de VPN L2L',
    VPN_RA_PROFILE: 'Perfil de VPN Acceso Remoto',
    RECORDS: 'Registros',
    RECORDS_HELP:
      'Aparecerá un listado predeterminado de registros cuando se encuentre asociado un perfil de Filtro Web con Protección de búsquedas activada con el perfil de Firewall del dispositivo',
    REGISTER: 'Añadir dispositivo',
    RESOLVE_HOSTNAME: 'Obtener nombre de host',
    RESOLVED_IP_ADDRESS: 'IP asignada',
    RESTORE_DEVICE_CONFIG: 'Instalar última configuración funcional',
    RESTORE_DEVICE_CONFIG_WARNING:
      'Devolver el dispositivo a su configuración anterior puede causar que las políticas de FW no se apliquen correctamente, dejando el dispositivo fuera de sincronización. Es importante revisar los últimos cambios en las reglas y la funcionalidad, y volver a aplicar para sincronizar el dispositivo.',
    RESTORE_DEVICE_CONFIG_EMPTY:
      'No se ha encontrado configuración previa para instalar',
    ROUTES: 'Enrutamiento',
    RULE_NAME: 'Nombre de regla',
    SERIAL_NUMBER: 'Número de serie',
    SHARED_KEY: 'Clave Compartida',
    SSL_INSPECTION_PROFILE: 'Perfil Inspección SSL',
    STATIC_ROUTES: 'Rutas Estáticas',
    STATUS: 'Estado gestión',
    SUB_NETWORKS: 'Sub-redes',
    SUCCESS_CORE_NUMBER: 'Número de núcleos actualizado correctamente',
    SUCCESS_FIREWALL_DEPTH:
      'Calidad de motor de firewall actualizado correctamente',
    SUCCESS_PROFILE: 'Perfil actualizado con éxito',
    SYNCHED: 'Sincronizado',
    SYSLOG: 'Syslog',
    SYSTEM_EVENTS: 'Eventos de sistema',
    TOTAL_LICENSES: 'Licencias totales',
    TUNNEL: 'Conexión SASE',
    TYPE: 'Tipo de dispositivo',
    UNKNOWN_MEMORY_WARNING:
      'Memoria disponible desconocida, la memoria libre podria no ser suficiente para el pack de reglas seleccionadas',
    UPDATE_SUCCESS: 'Dispositivo actualizado con éxito',
    UPLOAD: 'Cargar',
    WEB_FILTER_PROFILE: 'Perfil Filtro Web',
    DELETE_CNM_DEVICE: 'Eliminar dispositivo del CNM',
    DNS: 'DNS',
    DNS_FORWARDER: 'DNS FORWARDER',
    DNS_RESOLVER: 'DNS RESOLVER',
    DNS1: 'DNS primario',
    DNS2: 'DNS secundario',
    REQUIRED_INTERFACES: 'Se requiere al menos un interfaz',
    DNS_SAVED_SUCCESS: 'La configuración DNS se ha guardado con éxito',
    NO_MONITOR_DATA: 'Sin datos de monitorización',
    DISCONNECTED_DEVICE: 'El dispositivo no está conectado',
    GENERAL_CONFIGURATION: 'Config general',
    RULE_PACK: 'Precisión de IPS/WebFilter/Antivirus',
    SUCCESS_RULE_PACK:
      'Precisión de IPS/WebFilter/Antivirus actualizado correctamente',
    ERROR_RULE_PACK:
      'Error al actualizar la precisión de IPS/WebFilter/Antivirus',
    STANDARD_PACKAGE: 'Estándar',
    EXTENDED_PACKAGE: 'Extendido',
    EXTREME_PACKAGE: 'Extremo',
    CLI: 'CLI',
    DIFFERENCES: 'Mostrar/Ocultar diferencias',
    CURRENT_CLI: 'Configuración actual',
    NEW_CLI: 'Nueva configuración',
    HARDWARE_OFFLOADING: 'Descarga de trabajo hardware',
    SUCCESS_HARDWARE_OFFLOADING:
      'Descarga de trabajo hardware actualizada correctamente',
    ERROR_HARDWARE_OFFLOADING:
      'Error al actualizar la Descarga de trabajo hardware',
    CLOUD_STATUS: 'Estado de la nube',
    HOSTNAME: 'Hostname',
    NEVER_CONNECTED: 'Nunca conectado',
    PEER_ID: 'IKE Peer ID',
    PRESHARED_KEY: 'Pre-shared key',
    SASE_TUNNEL_STATUS: 'Estado de túnel SASE',
    TUNNEL_FULL_UP: 'Completamente levantado',
    TUNNEL_UP: 'Levantado',
    TUNNEL_DOWN: 'Caído',
    VALIDATE_LENGTH_IKE_PEER_ID:
      'El IKE Peer ID debe estar entre 6 y 16 caracteres',
    REMOTE_ACCESS_SSL_VPN: 'Perfil de SSL VPN',
  },
  INTERFACES: {
    ADD: 'Añadir interfaz',
    ADDRESS_RANGE: 'Rango de direcciones',
    ADDRESSING_MODE: 'Modo de direccionamiento',
    AUTOMATIC: 'Automático (dhcp)',
    CONFIRM_CLEAN_INTERFACE: '¿Quieres limpiar la interfaz?',
    CONFIRM_DELETE_INTERFACE: '¿Quieres eliminar la interfaz?',
    DEFAULT_ROUTER: 'Router por defecto',
    DESCRIPTION: 'Descripción',
    DESCRIPTION_PLACEHOLDER: 'Descripción',
    DESCRIPTION_MAX_VALUE: 'Solo se permite un máximo de {maxValue} carácteres',
    DHCP_SERVER: 'Servidor DHCP',
    DHCP: 'DHCP',
    DUPLICATED_IP: 'Ip ya se encuentra en uso',
    DUPLICATE_VLAN_ID: 'VLAN id ya se encuentra en uso, dentro de la Ethernet',
    EDIT: 'Editar interfaz',
    END_IP: 'IP Fin',
    ERROR_CREATE: 'Error al crear interfaz',
    ERROR_CLEAN: 'Error al limpiar interfaz',
    ERROR_DELETE: 'Error al borrar interfaz',
    ERROR_DELETE_ETHERNET: 'No se puede borrar, interfaz del tipo Ethernet',
    ERROR_FETCH: 'Error al obtener interfaces',
    ERROR_UPDATE: 'Error al actualizar interfaz',
    ETHERNET_BRIDGE_LIST: 'Listado de Interfaces Ethernet sin configuración',
    ETHERNET_JUST_VM:
      'No se puede crear interface ethernet en este dispositivo',
    GENERAL: 'General',
    ID: 'ID',
    INTERFACE: 'Interfaz',
    INTERFACES: 'Interfaces',
    INVALID_RANGE_BRIDGE_ID: 'El rango debe estar entre 0 y 1000',
    INVALID_RANGE_VLAN_ID: 'El rango debe estar entre 1 y 4096',
    INVALID_RANGE_ETH_ID: 'El rango debe estar entre 0 y 1000',
    IP_MASK: 'Dirección IP / Máscara',
    IP: 'IP',
    MANUAL: 'Manual',
    MONITOR: 'Monitor',
    NAME: 'Nombre',
    PRIMARY_DNS_SERVER: 'Servidor DNS primario',
    RANGE: 'IP Inicio / IP Fin',
    REQUIRE_SUBNET: 'La subnet es obligatoria',
    SECONDARY_DNS_SERVER: 'Servidor DNS secundario',
    SECURITY: 'Seguridad',
    START_IP: 'IP Inicio',
    STATUS: 'Estado',
    SUBNETWORK: 'Subred',
    SUCCESS_CREATE: 'Interfaz creada con éxito',
    SUCCESS_CLEAN: 'Interfaz limpiada con éxito',
    SUCCESS_DELETE: 'Interfaz borrada con éxito',
    SUCCESS_UPDATE: 'Interfaz actualizada con éxito',
    TYPE: 'Tipo de interfaz',
    BRIDGE_NAME_DUPLICATED: 'El Bridge ya está en uso',
    VLAN_DUPLICATED: 'La VLAN ya está en uso',
    ETHERNET_NAME_DUPLICATED: 'La Ethernet ya está en uso',
    ETHERNET_NAME_VALID:
      'Asegúrese que el nombre de interfaz, coincide con una interfaz disponible en el equipo',
    BRIDGE_IP_DUPLICATED: 'La IP ya se encuentra en uso en otro Bridge',
    VLAN_IP_DUPLICATED: 'La IP ya se encuentra en uso en otra VLAN',
    ETH_IP_DUPLICATED: 'La IP ya se encuentra en uso en otra Ethernet',
  },
  FILTERS: {
    MORE: 'Más',
    PAST_5MIN: 'Últimos 5 minutos',
    PAST_HOUR: 'Última hora',
    PAST24HOURS: 'Últimas 24 horas',
    PAST30DAYS: 'Últimos 30 días',
    PAST5YEARS: 'Últimos 5 años',
    PAST7DAYS: 'Últimos 7 días',
    PASTYEAR: 'Último año',
    RANGE: 'Rango Personalizado',
    TODAY: 'Hoy',
    YESTERDAY: 'Ayer',
    ACTION: {
      ALERT: 'Alert',
      ALLOWED: 'Permitido',
      BLOCKED: 'Bloqueado',
      DROP: 'Drop',
      NAME: 'Acción',
      PASS: 'Pass',
      DETECT: 'Detectar',
      PERMIT: 'Permitir',
      BLOCK: 'Bloquear',
      CUSTOM: 'Custom',
      OFF: 'Apagar',
      DEFAULT: 'Default',
    },
    ADD_MULTI_INPUT: {
      SRC_IP: 'Añade una red o un rango de redes',
    },
    APPLICATION: {
      NAME: 'Aplicación',
    },
    APPLICATIONS: {
      NAME: 'Aplicaciones',
      SAFARI: 'Safari',
    },
    BRIGHTCLOUD_ID: {
      NAME: 'Subcategoría',
    },
    FAMILY: {
      NAME: 'Family',
    },
    CATEGORY: {
      NAME: 'Categoría',
    },
    CREATED_AT: {
      NAME: 'Fecha creación',
    },
    DATE: {
      'LAST DAY': 'Último día',
      'LAST MONTH': 'Últimos 30 días',
      'LAST WEEK': 'Últimos 7 días',
      CUSTOM: 'Personalizado',
      NAME: 'Fecha',
      TODAY: 'Hoy',
      YESTERDAY: 'Ayer',
    },
    DESCRIPTION: {
      NAME: 'Descripción',
    },
    DEST_COUNTRY_CODE: {
      NAME: 'País de destino',
    },
    DEST_COUNTRY_NAME: {
      NAME: 'País',
    },
    DEST_IP: {
      NAME: 'IP destino',
    },
    FEATURE: {
      ANOMALY: 'Anomalía',
      AV: 'Antivirus',
      APPLICATIONS: 'Aplicaciones',
      APPS: 'Aplicaciones',
      DLP: 'DLP',
      IPS: 'IPS',
      NAME: 'Funcionalidad',
      VPN: 'VPN',
      WEBFILTER: 'Filtro web',
    },
    FILE_NAME: {
      NAME: 'Nombre de archivo',
    },
    FILE_SIZE: {
      NAME: 'Tamaño de archivo',
    },
    FILE_MAGIC: {
      NAME: 'Tipo de archivo',
    },
    HTTP_METHOD: {
      DELETE: 'DELETE',
      GET: 'GET',
      NAME: 'Método HTTP',
      POST: 'POST',
      PUT: 'PUT',
    },
    OS: {
      ANDROID: 'Android',
      IOS: 'iOS',
      LINUX: 'Linux',
      MACOS: 'macOS',
      NAME: 'OS',
      WINDOWS: 'Windows',
    },
    PERIOD: {
      DAY: 'Día',
      HOUR: 'Hora',
      MINUTE: 'Minuto',
      MONTH: 'Mes',
      NAME: 'Periodo',
      SECOND: 'Segundo',
      WEEK: 'Semana',
      YEAR: 'Año',
    },
    PROTO: {
      ICMP: 'ICMP',
      IGMP: 'IGMP',
      NAME: 'Protocolo',
      TCP: 'TCP',
      UDP: 'UDP',
    },
    PROTOCOL: {
      ICMP: 'ICMP',
      IGMP: 'IGMP',
      NAME: 'Protocolo',
      TCP: 'TCP',
      UDP: 'UDP',
    },
    RECEIVED: {
      NAME: 'Bytes recibidos',
    },
    REPUTATION: {
      NAME: 'Reputación',
    },
    SEVERITY: {
      1: 'Critical',
      2: 'Major',
      3: 'Minor',
      4: 'Audit',
      5: 'Informational',
      AUDIT: 'Audit',
      CRITICAL: 'Critical',
      INFORMATIONAL: 'Informational',
      MAJOR: 'Major',
      MINOR: 'Minor',
      NAME: 'Gravedad',
    },
    SRC_COUNTRY_CODE: {
      NAME: 'País de origen',
    },
    SRC_IP: {
      INVALID: 'Se ha detectado una red inválida en el filtro de IP origen',
      NAME: 'IP origen',
    },
    TARGET: {
      BOTH: 'Both',
      NAME: 'Target',
      TO_CLIENT: 'To client',
      TO_SERVER: 'To server',
    },
    TRANSMITTED: {
      NAME: 'Bytes transmitidos',
    },
    TYPE: {
      ANTIVIRUS: 'Antivirus',
      APPLICATIONS: 'Aplicaciones',
      IPS: 'IPS',
      NAME: 'Tipo',
    },
    TYPE_IPS: {
      CUSTOM: 'Custom',
      DEFAULT: 'Default',
      NAME: 'Type',
    },
    UPDATED_AT: {
      NAME: 'Update at',
    },
    URL: {
      NAME: 'URL',
    },
    RESULT: {
      NAME: 'Resultado',
      SUCCESS: 'Éxito',
      FAIL: 'Intento fallido',
    },
  },
  DASHBOARD: {
    TOP_PROTECTION: 'Top Protecciones',
    FEATURES: 'Funcionalidades',
    BLOCKED: 'Bloqueado',
    TRAFFIC_BLOCKED: 'Tráfico Bloqueado',
    TRAFFIC_ALLOWED: 'Tráfico Permitido',
    MALWARE_REGIONS: 'Malware detectado por regiones',
    SEVERITY_ALERTS: 'Eventos por Gravedad',
    FEATURE_ALERTS: 'Eventos por Funcionalidad',
    NAME: 'Nombre',
    SEVERITY: 'Severidad',
    DETECTIONS: 'Detecciones',
    TRAFFIC_STATS: 'Estadísticas de tráfico',
    ACTION: 'Acción',
    BY: 'Por ',
    ALERTS: 'Eventos',
    TRAFFIC: 'Tráfico',
    SEVERITY_BY_SRC_IP: 'Gravedad por IP Origen',
    SEVERITY_BY_DST_IP: 'Gravedad por IP Destino',
    TRAFFIC_BY_ACTION: 'Tráfico por Acción',
    TOP_TEN_EVENTS_BY_SRC_IP: 'Top 10 Eventos por IP origen',
    TOP_TEN_EVENTS_BY_DEST_IP: 'Top 10 Eventos por IP destino',
    XDR_EVENTS: 'Eventos XDR',
    TRAFFIC_BY_APPLICATION_AND_IP: 'Tráfico por Aplicación y Origen',
    DLP_EVENTS: 'Eventos DLP',
    TOP_TEN_DLP_EVENTS_BY_FILE_NAME: 'Top 10 Eventos por Nombre de Archivo',
  },
  CONFIGURATION: {
    IpsProtection: 'Protección IPS',
    Ssl: 'SSL',
    Dlp: 'DLP',
    Applications: 'Control de Aplicaciones',
    RULES: 'Lista de reglas',
    Vpn: 'VPN',
    WebFilter: 'Filtro web',
    Antivirus: 'Antivirus',
    OtProtection: 'Protección OT',
    PENDING_CHANGES: 'Cambios para persistir',
    AFFECTED_DEVICES: 'Equipos afectados',
    SUMMARY_CHANGES: 'Resumen de cambios que quieres instalar:',
    CONFIRMATION_QUESTION: '¿Quieres aplicar estos cambios?',
    CASB: 'CASB',
    CASB_MISSING_SSL:
      'Perfil de Inspección SSL necesario para que CASB funcione correctamente',
    CASB_SSL_FULL_REQUIRED:
      'Perfil de Inspección SSL configurado a "COMPLETO" necesario para que CASB funcione correctamente',
  },
  VPN: {
    ACTIVATE_IPSEC: 'Activar VPN IPsec',
    ACTIVATE_SSL: 'Activar VPN SSL',
    GROUPS: 'Grupos VPN',
  },
  DLP: {
    ADD_RULE: 'Añadir nueva regla DLP',
    NAME: 'Nombre',
    REGEX: 'Expresión Regular',
    RULES: 'Reglas',
    TABLE: {
      NAME: 'Nombre',
      REGEX: 'Expresión Regular',
      ACTIONS: 'Acciones',
      DESCRIPTION: 'Descripción',
    },
    DETAILS: 'Detalles',
    FILE_TYPES: 'Tipos de archivos',
    FILE_NAME: 'Nombre de archivo',
    FILE_NAMES: 'Nombres de archivo',
    CONTENT: 'Contenido',
    CONTENT_TOOLTIP:
      'Buscar los siguientes elementos dentro del contenido del archivo y del tráfico',
    FILE_TYPE_GROUPS: 'Grupos de tipos de archivo',
    FILE_NAME_GROUPS: 'Grupos de nombres de archivo',
    CONTENT_GROUPS: 'Grupos de contenidos de protocolo',
    FILE_TYPES_HELP: 'Seleciona los tipos de archivo permitidos',
    BASIC_MODE: 'Modo básico',
    REGEX_MODE: 'Modo regex',
    INSERT_BASIC_MODE: 'Inserta el nombre del archivo',
    INSERT_REGEX_MODE: 'Inserta la expresion regular del nombre del archivo',
    EXCLUDE_NAMES: 'Nombres excluidos',
    FILES: 'Archivos',
    HELP: 'Click para más información',
    PROTOCOL: 'Protocolo',
    RULE_NAME: 'Nombre de regla',
    ACTION: 'Acción',
    OPTIONS: 'Opciones',
    EDIT_RULE_TITLE: 'Editar regla DLP',
    ADD_RULE_TITLE: 'Añadir regla DLP',
    CONFIRM_DELETE: '¿Está seguro que desea borrar esta regla DLP?',
    SUCCESS_DELETE: 'Regla DLP eliminada correctamente',
    FILE_TYPES_PLACEHOLDER: 'Tipos de archivo...',
    FILE_NAMES_PLACEHOLDER: 'Nombres de archivo...',
    CONTENT_PLACEHOLDER: 'Contenido...',
    RULE_NAME_MISSING: 'Añada un nombre a la regla',
    CRITERIA_MISSING: 'Añada como mínimo un elemento a la regla',
    ADD_RULE_SUCCESS: 'Regla DLP creada correctamente',
    EDIT_RULE_SUCCESS: 'Regla DLP actualizada correctamente',
  },
  IPS_PROTECTION: {
    IPS_IDS_PROTECTION: 'Protección IPS/IDS',
    IDS_PROTECTION: 'Protección IDS',
    IPS_PROTECTION: 'Protección IPS',
    ANTIDOS_PROTECTION: 'Protección AntiDoS',
    TABLE: {
      DESCRIPTION: 'Descripción',
      SEVERITY: 'Gravedad',
      OS: 'SO',
      ACTION: 'Acción',
      AFFECTED_APPS: 'Aplicaciones Afectadas',
      CVE_ID: 'ID CVE',
      UPDATED_AT: 'Fecha actualización',
      CREATED_AT: 'Fecha creación',
      TARGET: 'Destino',
      TYPE: 'Tipo',
      RULE_PACK: 'Pack de reglas',
      ACTIONS: 'Acciones',
    },
    ANTIDOS_TABLE: {
      NAME: 'Nombre',
      STATUS: 'Estado',
      ACTION: 'Acción',
      THRESHOLD: 'Umbral',
      COUNT: 'Cantidad',
      INTERVAL: 'Periodo',
    },
    ANTIDOS_FORM: {
      ADD_RULE: 'Añadir nueva regla AntiDoS',
      ADD_SUCCESS: 'Regla AntiDoS creada con éxito',
      ADD_ERROR: 'Error al crear regla AntiDoS',
      EDIT_RULE: 'Editar regla AntiDoS',
      EDIT_SUCCESS: 'Regla AntiDoS actualizada con éxito',
      EDIT_ERROR: 'Error al actualizar regla AntiDoS',
      CONFIRM_DELETE: '¿Está seguro de que quiere eliminar esta regla AntiDoS?',
      DELETE_SUCCESS: 'Regla AntiDoS eliminada con éxito',
      DELETE_ERROR: 'Error al eliminar regla AntiDoS',
      NAME: 'Nombre',
      STATUS: 'Estado',
      ACTION: 'Acción',
      PROTOCOL: 'Protocolo',
      TYPE: 'Tipo',
      TYPE_ICMP_ERROR:
        'El campo Servicios debe estar vacío al seleccionar el tipo ICMP flood en las reglas de AntiDoS',
      SOURCE: 'Origen',
      DESTINATION: 'Destino',
      TRACK_BY: 'Rastrear por',
      TRACK_BY_SOURCE: 'Origen',
      TRACK_BY_DESTINATION: 'Destino',
      THRESHOLD: 'Umbral',
      THRESHOLD_INVALID: 'Umbral solo permite valores entre {min} y {max}',
      INTERVAL_INVALID: 'Periodo solo permite valores entre {min} y {max}',
      ACTIONS: 'Acciones',
      ANY: 'Todos',
      MAX_NETWORKS_ERROR:
        'Se ha excedido el máximo número de redes y puertos permitidos en una regla AntiDoS',
    },
    ACTIVATE_OTSIGNATURES: 'Activar firmas OT',
    EXCEPTIONS: 'Excepciones',
    CHANGE_PROTECTION_TYPE: 'Cambiar tipo de proteccion',
    ADD_EXCEPTIONS: 'Añadir excepciones',
    CONFIRM_DELETE: '¿Está seguro de que quiere eliminar esta regla?',
    DELETE_SUCCESS: 'Regla eliminada correctamente',
    ADD_SUCCESS: 'Regla añadida correctamente',
    ADD_ERROR: 'Error al crear regla',
    CONFIRM_EXCEPTION_DELETE:
      '¿Está seguro de que quiere eliminar esta excepción?',
    DELETE_EXCEPTION_SUCCESS: 'Excepción eliminada correctamente',
    ADD_EXCEPTION_SUCCESS: 'Excepción añadida correctamente',
    CHECK_SYNTAX: 'Comprueba la sintasis de la regla',
    ADD_RULE: 'Añadir nueva regla',
    ADD_RULE_HELP: 'Añadir las reglas de una en una',
    EDIT_RULE: 'Editar regla',
    CHANGE_ACTION: 'Cambiar Acción',
    EDIT_SUCCESS: 'Regla modificada correctamente',
    EDIT_ERROR: 'Error al modificar la regla',
    DUPLICATED_DESCRIPTION: 'La descripción de la regla ya se encuentra en uso',
  },
  ERRORS: {
    IP_REGISTERED: 'IP ya registrado',
    HOSTNAME_INVALID: 'Nombre de host inválido',
    IP_NOT_RESOLVED: 'Dirección IP no obtenida',
  },
  ANTIVIRUS: {
    TITLE_ANTIVIRUS: 'Antivirus',
    TITLE_ANTI_CNC: 'Anti Command & Control',
    TITLE_SANDBOX: 'Enviar archivo a Sandbox',
    TITLE_FILE_BLACKLIST: 'Lista negra de archivos',
    MD5_HASHES_FILE_BLACKLIST: 'Lista de hashes MD5',
    MD5_HASHES_FILE_BLACKLIST_ERROR: 'Hashes MD5 no válidos',
    SUCCESS_UPDATE: 'Configuración actualizada correctamente',
    SUCCESS_ANTIVIRUS:
      'El estado del Antivirus se ha actualizado correctamente',
    SUCCESS_ANTI_CNC: 'El estado del Anti C&C se ha actualizado correctamente',
    SUCCESS_FILE_BLACKLIST:
      'El estado de la lista negra de archivos se ha actualizado correctamente',
    SUCCESS_MD5_HASHES:
      'El estado de la lista de hashes MD5 se ha actualizado correctamente',
    ERROR_INVALID_MD5_HASH: 'Hash MD5 no válido',
    ERROR_FETCH: 'Error al cargar las opciones de Antivirus y Anti C&C',
    ERROR_UPDATE:
      'Error mientras se modificaban las opciones de Antivirus y Anti C&C',
  },
  TIME: {
    FROM: 'Desde',
    TO: 'Hasta',
    AT: 'A',
    JANUARY: 'Enero',
    FEBRUARY: 'Febrero',
    MARCH: 'Marzo',
    APRIL: 'Abril',
    MAY: 'Mayo',
    JUNE: 'Junio',
    JULY: 'Julio',
    AUGUST: 'Agosto',
    SEPTEMBER: 'Septiembre',
    OCTOBER: 'Octubre',
    NOVEMBER: 'Noviembre',
    DECEMBER: 'Diciembre',
    MONDAY: 'Lunes',
    MEDIUM_MONDAY: 'Lun',
    SHORT_MONDAY: 'L',
    TUESDAY: 'Martes',
    MEDIUM_TUESDAY: 'Mar',
    SHORT_TUESDAY: 'M',
    WEDNESDAY: 'Miércoles',
    MEDIUM_WEDNESDAY: 'Mie',
    SHORT_WEDNESDAY: 'X',
    THURSDAY: 'Jueves',
    MEDIUM_THURSDAY: 'Jue',
    SHORT_THURSDAY: 'J',
    FRIDAY: 'Viernes',
    MEDIUM_FRIDAY: 'Vie',
    SHORT_FRIDAY: 'V',
    SATURDAY: 'Sábado',
    MEDIUM_SATURDAY: 'Sáb',
    SHORT_SATURDAY: 'S',
    SUNDAY: 'Domingo',
    MEDIUM_SUNDAY: 'Dom',
    SHORT_SUNDAY: 'D',
    NEXT_MONTH: 'Mes siguiente',
    PREV_MONTH: 'Mes anterior',
    OPEN_MONTH: 'Abrir selector de mes',
    OPEN_YEAR: 'Abrir selector de año',
    CLOSE_MONTH: 'Cerrar selector de mes',
    CLOSE_YEAR: 'Cerrar selector de año',
    DEFAULT_PLACEHOLDER: 'Seleccionar...',
    FIRST_DAY_OF_WEEK: 'Lunes',
    DAYS: 'Días',
    TIMEZONE: 'Zona horaria',
    DAILY: 'Diario',
    WEEKLY: 'Semanal',
    MONTHLY: 'Mensual',
    YEARLY: 'Anual',
    DAYS_OF_WEEK: 'Días de la semana',
    MONTHS: 'Meses',
    WEEKS: 'Semanas',
    DAYS_OF_MONTH: 'Días del mes',
    LAST_DAY_OF_MONTH: 'Último día',
    HOUR: 'Hora',
    YEARS: 'Años',
  },
  LICENSES: {
    TYPE: 'Tipo',
    NUMBER: 'Número',
    VALIDITY: 'Validez',
    VALIDITY_INVALID: 'Validez no válida',
    AVAILABLE: 'Disponible',
    TOTAL: 'Total',
    CLOSE_TO_EXPIRY: 'Cerca de caducar',
    EXPIRED: 'Caducados',
    USED: 'Usados',
    TIME_CONSUMPTION: 'Tiempo consumido',
    LICENSE_TYPE: 'Tipo de licencia',
    NUMBER_LICENSES: 'Número de dispositivos',
    CREATED: 'Licencia creada con éxito',
    ADD_LICENSE: 'Añadir licencia',
    LOAD_FILE: 'Cargar archivo',
    ACTIVATE: 'Activar licencia',
    UPLOAD: 'Subir archivo .txt',
    EXPIRATION_DATE: 'Fecha de expiración',
    ASSIGN_DATE: 'Fecha de asignación',
    NO_LICENSES_AVAILABLE: 'Sin licencias',
    ADD: 'Añadir licencia',
    DEVICE_CLOUD_UUID: 'Dispositivo cloud',
    TRIAL_PERIOD: 'Periodo de prueba',
    EXPIRED_TOOLTIP: 'Esta licencia ha caducado',
    CLOSE_TO_EXPIRY_TOOLTIP: 'Esta licencia caducará en un mes o menos',
    CONFIRM_ERROR: 'Error al eliminar la licencia caducada',
    CONFIRM_DELETE:
      '¿Está seguro de que quiere eliminar esta licencia caducada?',
    SUCCESS_DELETE: 'Licencia caducada eliminada correctamente',
    SELECT_ALL_UTM: 'Seleccionar todas las licencias UTM',
    SELECT_ALL_CLOUD: 'Seleccionar todas las licencias Cloud',
    DELETE_ALL_EXPIRED_LICENSES: 'Elimina todas las licencias expiradas',
    DELETE_ALL_CONFIRM_ERROR: 'Error al eliminar todas las licencias caducadas',
    DELETE_ALL_CONFIRM_DELETE:
      '¿Está seguro de que quiere eliminar todas las licencias caducadas?',
    DELETE_ALL_SUCCESS_DELETE: 'Licencias caducadas eliminadas correctamente',
    LICENSE_DETECTED: 'Licencia detectada',
    LICENSE_NOT_FOUND: 'Licencia no encontrada',
    FILE_ERROR: 'El fichero tiene errores',
    LICENSE_INVALID: 'Licencia no válida',
    LICENSE_EMPTY: 'El tipo de la licencia no puede estar vacío',
    TENANT_INVALID: 'La licencia no corresponde a este tenant',
    DATA_INVALID: 'La licencia tiene datos no válidos',
    ALREADY_ACTIVATED: 'Licencia ya activada',
    SSE_USERS: 'Usuarios SSE',
  },
  REPORTS: {
    FILTER: 'Filtro',
    FROM: 'Desde',
    TO: 'Hasta',
    FEATURES: 'Características',
    GENERAL: 'General',
    IPS: 'IPS',
    DLP: 'DLP',
    THROUGHPUT: 'Rendimiento',
    WEBFILTER: 'Filtro Web',
    APPLICATIONS: 'Aplicaciones',
    INTERFACES: 'Interfaces',
    ANTIVIRUS: 'Antivirus',
    VIEW: 'Ver',
    HIDE: 'Esconder',
    DOWNLOAD: 'Descargar',
    SCHEDULE: 'Programar',
    SCHEDULE_NEW_REPORT: 'Programar nuevo informe',
    SCHEDULED_REPORTS: 'Informes Programados',
    TASK_NAME: 'Nombre de tarea',
    TASK_NAME_PLACEHOLDER: 'Nombre de tarea...',
    PERIOD: 'Periodo',
    TIMEFRAME: 'Periodo de tiempo',
    ALL: 'Todo',
    GENERATE: 'Generar',
    USER: 'Usuarios',
    NETWORK: 'Redes',
    NETWORK_RANGE: 'Rangos de Red',
    GEOLOCATION: 'Geolocalizaciones',
    FQDN: 'FQDN',
    MAC: 'MAC',
    TOP_SUBCATEGORY_ACTION: 'Top 10 Subcategorías por Acción',
    TOP_SUBCATEGORY_SRC_IP: 'Top 10 IPs de origen por Subcategoría',
    REPORTS_TEMPLATES: 'Plantillas de informes',
    TRAFFIC_BY_APPLICATION: 'Tráfico por Aplicación',
    REPORT_PREVIEW: 'Previsualización de informe',
  },
  REPORTS_TEMPLATES: {
    NAME: 'Nombre',
    FROM: 'Desde',
    TO: 'Hasta',
    SOURCES: 'Origen',
    DESTINATIONS: 'Destino',
    DESTINATION_PORT: 'Puerto de destino',
    APPLICATIONS: 'Aplicaciones',
    HOSTNAME: 'Nombre del host',
    CATEGORIES: 'Categorías',
    FEATURES: 'Características',
    ADD: 'Añadir plantilla de informe',
    EDIT: 'Editar plantilla de informe',
    ADD_SUCCESS: 'Plantilla de informe creada correctamente',
    ADD_ERROR: 'Error al crear una plantilla de informe',
    EDIT_SUCCESS: 'Plantilla de informe actualizada correctamente',
    EDIT_ERROR: 'Error al editar una plantilla de informe',
    DELETE_SUCCESS: 'Plantilla de informe eliminada correctamente',
    DELETE_ERROR: 'Error al eliminar una plantilla de informe',
  },
  EMAIL: {
    TO: 'Para',
    CC: 'CC',
    BCC: 'CCO',
    SUBJECT: 'Asunto',
    CONTENT: 'Contenido',
  },
  SCHEDULED_REPORTS: {
    CONFIRM_DELETE:
      '¿Está seguro de que quiere eliminar este informe programado?',
    CRON: 'Programación',
    DISCARD: 'Descartar',
    EDIT_SCHEDULED: 'Editar informe programado',
    EMAILS: 'Correos',
    ERROR_DELETE: 'Error al eliminar informe programado',
    ERROR_FETCH: 'Error al obtener informes programados',
    LAST_EXEC: 'Última ejecución',
    NAME: 'Nombre',
    NEW_SCHEDULED: 'Programar nuevo informe',
    NEXT_EXEC: 'Siguiente ejecución',
    SAVE: 'Guardar cambios',
    STATUS: 'Estado',
    SUCCESS_DELETE: 'Informe programado eliminado correctamente',
  },
  STATIC_ROUTES: {
    ADD: 'Añadir nueva Ruta Estática',
    CONFIRM_DELETE: '¿Está seguro de que quiere eliminar esta ruta estática?',
    DISTANCE: 'Distancia',
    DISTANCE_RANGE: 'La distancia debe estar entre 1 y 255',
    DUPLICATE_NAME: 'Nombre ya se encuentra en uso',
    EDIT: 'Editar Ruta Estática',
    ERROR_CREATE: 'Error al crear la ruta estática',
    ERROR_DELETE: 'Error al eliminar ruta estática',
    ERROR_UPDATE: 'Error while updating static route',
    ERROR_FETCH: 'Error obteniendo las rutas estáticas',
    GATEWAY: 'Puerta de Enlace',
    NAME: 'Nombre',
    NETWORK: 'Red',
    REQUIRE_SUBNET: 'La subnet es obligatoria',
    SUCCESS_CREATE: 'Ruta estática creada correctamente',
    SUCCESS_DELETE: 'Ruta estática eliminada correctamente',
    SUCCESS_UPDATE: 'Ruta estática actualizada correctamente',
  },
  LICENSE_TYPES: {
    BS: 'Cloud',
    UTM_R: 'UTM para RS y TE-100 series',
    UTM_M: 'UTM para M, Celer, Regesta, TE-200 y TE-220 series',
    UTM_A: 'UTM para Atlas, H5 y TE-400 series',
    UTM_V: 'UTM para VM series',
    UTM_S: 'UTM para SDE series',
    UTM_F: 'UTM para Ares y TE-600 series',
    AS: 'Antispam',
    AV: 'Antivirus',
    AC: 'Control de aplicaciones',
    DLP: 'DLP / Filtro de contenido',
    FW: 'Firewall',
    IPS: 'IDS/IPS',
    IPSOT: 'IDS/IPS OT',
    SSL: 'Inspección SSL',
    ZTNA: 'ZTNA',
    RA: 'Acceso Remoto',
    RA_N: 'Acceso Remoto ({n} users)',
    UF: 'Filtro Web',
    CB: 'CASB',
    DNS: 'Filtro DNS',
    UID: 'Identificación de usuario',
    VPNTUN: 'Túnel VPN',
    SSE: 'SSE Basado en Usuario',
    USER_BASED: '{license} Basado en Usuario',
    SSE_RA_MAX_USERS_ERROR:
      'Los usuarios de la licencia SSE Acceso Remoto solo pueden ser añadidos en bloques de {maxUsers}',
    SSE_RA_MULTIPLIER_ERROR:
      'El multiplicador de la licencia SSE Acceso Remoto no puede exceder {maxMultiplier}',
  },
  COUNTRIES: {
    '-': 'Desconocido',
    US: 'Estados Unidos de América',
    CN: 'China',
    AU: 'Australia',
    JP: 'Japón',
    TH: 'Tailandia',
    IN: 'India',
    MY: 'Malasia',
    KR: 'Corea (República de)',
    SG: 'Singapur',
    HK: 'Hong Kong',
    TW: 'Taiwan (Provincia de China)',
    KH: 'Camboya',
    PH: 'Filipinas',
    VN: 'Viet Nam',
    NO: 'Noruega',
    BR: 'Brasil',
    AR: 'Argentina',
    PS: 'Palestina, Estado de',
    ES: 'España',
    FR: 'Francia',
    NL: 'Países Bajos',
    CZ: 'Chequia',
    DE: 'Alemania',
    AT: 'Austria',
    CH: 'Suiza',
    GB: 'Reino Unido de Gran Bretaña e Irlanda del Norte',
    IT: 'Italia',
    GR: 'Grecia',
    RU: 'Federación de Rusia',
    IE: 'Irlanda',
    DK: 'Dinamarca',
    PT: 'Portugal',
    SE: 'Suecia',
    GH: 'Ghana',
    CM: 'Camerún',
    BE: 'Bélgica',
    ZA: 'Sudáfrica',
    FI: 'Finlandia',
    TR: 'Turquía',
    AE: 'Emiratos Árabes Unidos',
    HU: 'Hungría',
    PL: 'Polonia',
    JO: 'Jordania',
    RO: 'Rumania',
    UG: 'Uganda',
    AM: 'Armenia',
    TZ: 'Tanzania, República Unida de',
    BI: 'Burundi',
    UY: 'Uruguay',
    CL: 'Chile',
    LU: 'Luxemburgo',
    PE: 'Perú',
    BG: 'Bulgaria',
    UA: 'Ucrania',
    EG: 'Egipto',
    CA: 'Canadá',
    IL: 'Israel',
    QA: 'Qatar',
    MD: 'Moldavia (República de)',
    SC: 'Seychelles',
    IQ: 'Irak',
    LV: 'Letonia',
    UZ: 'Uzbekistán',
    SK: 'Eslovaquia',
    KZ: 'Kazajistán',
    GE: 'Georgia',
    EE: 'Estonia',
    HR: 'Croacia',
    AL: 'Albania',
    LT: 'Lituania',
    SA: 'Arabia Saudi',
    MT: 'Malta',
    CR: 'Costa Rica',
    CY: 'Chipre',
    IR: 'Irán (República Islámica de)',
    ID: 'Indonesia',
    BH: 'Baréin',
    MX: 'México',
    CO: 'Colombia',
    SY: 'República Árabe Siria',
    LB: 'Líbano',
    ZM: 'Zambia',
    ZW: 'Zimbabue',
    OM: 'Omán',
    RS: 'Serbia',
    IS: 'Islandia',
    SI: 'Eslovenia',
    MK: 'Macedonia del Norte',
    LI: 'Liechtenstein',
    JE: 'Jersey',
    BA: 'Bosnia y Herzegovina',
    AZ: 'Azerbaiyán',
    KG: 'Kirguistán',
    IM: 'Isla de Man',
    GG: 'Guernsey',
    GI: 'Gibraltar',
    LY: 'Libia',
    YE: 'Yemen',
    BY: 'Bielorrusia',
    RE: 'Reunión',
    MQ: 'Martinica',
    KW: 'Kuwait',
    LK: 'Sri Lanka',
    SZ: 'Suazilandia',
    CD: 'Congo (República Democrática del)',
    GP: 'Guadalupe',
    BD: 'Bangladesh',
    BT: 'Bután',
    BN: 'Brunei Darussalam',
    PM: 'San Pedro y Miquelón',
    PA: 'Panamá',
    LA: 'Lao (República Democrática Popular)',
    GU: 'Guam',
    MP: 'Islas Marianas del Norte',
    DO: 'República Dominicana',
    NG: 'Nigeria',
    NZ: 'Nueva Zelanda',
    EC: 'Ecuador',
    VE: 'Venezuela (República Bolivariana de)',
    PR: 'Puerto Rico',
    VI: 'Islas Vírgenes (E.E.U.U.)',
    PK: 'Pakistán',
    MN: 'Mongolia',
    PG: 'Papua Nueva Guinea',
    TL: 'Timor Oriental',
    SB: 'Islas Salomón',
    VU: 'Vanuatu',
    FJ: 'Fiji',
    CK: 'Islas Cook',
    TO: 'Tonga',
    NP: 'Nepal',
    KE: 'Kenia',
    MO: 'Macao',
    NI: 'Nicaragua',
    JM: 'Jamaica',
    TT: 'Trinidad y Tobago',
    LS: 'Lesoto',
    AF: 'Afganistán',
    MA: 'Marruecos',
    GD: 'Granada',
    VG: 'Islas Vírgenes (Británicas)',
    KN: 'San Cristóbal y Nieves',
    AG: 'Antigua y Barbuda',
    VC: 'San Vicente y las Granadinas',
    BS: 'Bahamas',
    DM: 'Dominica',
    KY: 'Islas Caimán',
    LC: 'Santa Lucía',
    MM: 'Birmania',
    BB: 'Barbados',
    PY: 'Paraguay',
    GT: 'Guatemala',
    UM: 'Islas Ultramarinas Menores de los Estados Unidos',
    TM: 'Turkmenistán',
    TK: 'Tokelau',
    MV: 'Maldivas',
    NC: 'Nueva Caledonia',
    WF: 'Wallis y Futuna',
    SM: 'San Marino',
    ME: 'Montenegro',
    SV: 'El Salvador',
    AD: 'Andorra',
    MC: 'Mónaco',
    BO: 'Bolivia (Estado Plurinacional de)',
    GL: 'Groenlandia',
    TJ: 'Tayikistán',
    FO: 'Islas Feroe',
    MF: 'San Martín (Parte Francesa)',
    LR: 'Liberia',
    MU: 'Mauricio',
    BW: 'Botsuana',
    MZ: 'Mozambique',
    TN: 'Túnez',
    MG: 'Madagascar',
    AO: 'Angola',
    NA: 'Namibia',
    CI: 'Costa de Marfil',
    SD: 'Sudán',
    MW: 'Malaui',
    GA: 'Gabón',
    ML: 'Mali',
    BJ: 'Benín',
    CV: 'Cabo Verde',
    RW: 'Ruanda',
    CG: 'Congo',
    GM: 'Gambia',
    GN: 'Guinea',
    BF: 'Burkina Faso',
    SO: 'Somalia',
    SL: 'Sierra Leona',
    NE: 'Niger',
    CF: 'República Centroafricana',
    TG: 'Togo',
    SS: 'Sudán del Sur',
    GQ: 'Guinea Ecuatorial',
    SN: 'Senegal',
    DZ: 'Algeria',
    AS: 'Samoa Americana',
    MR: 'Mauritania',
    DJ: 'Yibuti',
    KM: 'Comoras',
    IO: 'Territorio Británico del Océano Índico',
    TD: 'Chad',
    YT: 'Mayotte',
    NR: 'Nauru',
    WS: 'Samoa',
    FM: 'Micronesia (Estados Federados de)',
    PF: 'Polinesia Francesa',
    HN: 'Honduras',
    BM: 'Bermuda',
    BZ: 'Belice',
    CW: 'Curazao',
    AX: 'Islas Aland',
    GF: 'Guayana Francesa',
    NU: 'Niue',
    TV: 'Tuvalu',
    PW: 'Palaos',
    MH: 'Islas Marshall',
    KI: 'Kiribati',
    KP: 'Corea (República Democrática Popular de)',
    AW: 'Aruba',
    CU: 'Cuba',
    HT: 'Haití',
    SR: 'Surinam',
    GY: 'Guyana',
    VA: 'Santa Sede',
    ST: 'Santo Tomé y Príncipe',
    ET: 'Etiopía',
    ER: 'Eritrea',
    GW: 'Guinea-Bissau',
    FK: 'Islas Malvinas (Falkland)',
    AI: 'Anguila',
    TC: 'Islas Turcas y Caicos',
    SX: 'Sint Maarten (Parte Neerlandesa)',
    BV: 'Isla Bouvet',
    SJ: 'Svalbard y Jan Mayen',
    BL: 'San Bartolomé',
    NF: 'Isla Norfolk',
    MS: 'Montserrat',
    BQ: 'Bonaire, San Eustaquio y Saba',
    GS: 'Islas Georgias del Sur y Sandwich del Sur',
    PN: 'Islas Pitcairn',
    SH: 'Santa Elena, Ascensión y Tristán de Acuña',
  },
  DHCP_CONFIGS: {
    MAXIMUM_REACHED: 'Sólo se permiten 5 rangos DHCP',
    SUCCESS_CREATE: 'Configuración DHCP creada con éxito',
    ERROR_CREATE: 'Error al crear configuración DHCP',
    SUCCESS_UPDATE: 'Configuración DHCP actualizada con éxito',
    ERROR_UPDATE: 'Error al actualizar configuración DHCP',
    SUCCESS_DELETE: 'Configuración DHCP eliminada con éxito',
    ERROR_DELETE: 'Error al eliminar configuración DHCP',
    MISSING_NAME: 'Falta el nombre',
    DUPLICATED_NAME: 'El nombre ya se encuentra en uso',
    MISSING_SUBNET: 'Falta la subred',
    MISSING_START_IP: 'Falta la IP de inicio',
    MISSING_END_IP: 'Falta la IP de fin',
    SUBNET_ERROR: 'La subred es inválida',
    SUBNET_IN_RANGE:
      'La dirección IP no debe estar dentro del rango de direcciones dado',
    START_IP_ERROR: 'La IP de inicio del rango de direcciones es inválida',
    START_IP_NOT_IN_SUBNET_ERROR:
      'La IP de inicio del rango de direcciones no se encuentra dentro de la subred dada',
    START_IP_WRONG_VALUE:
      'La IP de inicio del rango de direcciones no debe ser el inicio de la subred dada',
    END_IP_ERROR: 'La IP de fin del rango de direcciones es inválida',
    END_IP_NOT_IN_SUBNET_ERROR:
      'La IP de fin del rango de direcciones no se encuentra dentro de la subred dada',
    INCORRECT_IP_RANGE: 'El rango de direcciones ha de ser ascendente',
    EQUAL_IP_RANGE:
      'La IP de inicio y la IP de fin del rango de direccion han de ser distintas',
    ROUTER_ERROR: 'El router por defecto es inválido',
    PRIMARY_DNS_ERROR: 'El servidor DNS primario es inválido',
    SECONDARY_DNS_ERROR: 'El servidor DNS secundario es inválido',
    INVALID_RANGES: 'Uno o más rangos inválido/s',
    REQUIRED_START_IP: 'IP Inicio requerida',
    REQUIRED_END_IP: 'IP Fin requerida',
    INVALID_START_IP: 'IP Inicio inválida',
    INVALID_END_IP: 'IP Fin inválida',
    INVALID_INTERFACE: 'Interfaz no valida',
    DEFAULT_ROUTER: {
      BAD_IPV4_BLOCK: 'Default Router is invalid',
      NOT_IN_SUBNET:
        'El Router por defecto no se encuentra dentro de la subred dada',
      IP_ADDRESS_IN_RANGE:
        'El Router por defecto no debe estar dentro del rango de direcciones dado',
      START_IP_WRONG_VALUE:
        'El Router por defecto no debe ser el inicio de la subred dada',
    },
  },
  DHCP_CONFIG_RANGES: {
    MAXIMUM_REACHED: 'Sólo se permiten 5 rangos DHCP',
    START_IP: {
      IP_TAKEN: 'IP inicio ya asignada en otro rango',
      BLANK: 'IP inicio no puede ser vacía',
    },
    END_IP: {
      IP_TAKEN: 'IP fin ya asignada en otro rango',
      BLANK: 'IP fin no puede ser vacía',
    },
  },
  FIREWALL: {
    TITLE: 'Proteccion de Firewall',
    ADD_RULE_TITLE: 'Añadir nueva regla firewall',
    ADD_SUCCESS: 'Regla firewall creada con éxito',
    ADD_ERROR: 'Error al crear regla firewall',
    EDIT_RULE_TITLE: 'Editar regla firewall',
    EDIT_SUCCESS: 'Regla firewall actualizada con éxito',
    EDIT_ERROR: 'Error al actualizar regla firewall',
    NAME: 'Nombre',
    NAME_PLACEHOLDER: 'Nombre de la regla...',
    SOURCE: 'Origen',
    SOURCE_PLACEHOLDER: '2.2.2.2/24:24',
    SOURCE_ERROR: 'Al menos uno de los orígenes es inválido',
    DESTINATION: 'Destino',
    DESTINATION_PLACEHOLDER: '2.2.2.2/24:24',
    DESTINATION_ERROR: 'Al menos uno de los destinos es inválido',
    CONFIRM_DELETE: '¿Está seguro de que quiere eliminar esta regla firewall?',
    DELETE_SUCCESS: 'Regla firewall eliminada con éxito',
    DELETE_ERROR: 'Error al eliminar regla firewall',
    ACTION: 'Acción',
    PASS: 'Permitir',
    DROP: 'Bloquear',
    STATUS: 'Estado',
    ORDER: 'Orden',
    ID: 'ID',
    ACTIONS: 'Opciones',
    ADD_RULE_ABOVE: 'Añadir regla arriba',
    ADD_RULE_BELOW: 'Añadir regla debajo',
    ADD_RULE_TOP: 'Añadir regla al principio',
    ADD_RULE_BOTTOM: 'Añadir regla al final',
    CONFIRM_DELETE_NETWORK: '¿Está seguro de que quiere eliminar la red',
    CONFIRM_DELETE_NETWORKS_GROUP:
      '¿Está seguro de que quiere eliminar el grupo de redes?',
    SERVICES: 'Servicios',
    ANY: 'Todos',
    LOCAL: 'LOCAL',
    PROTOCOL: 'Protocolo',
    INTERFACE: 'Interfaces',
    INCOMING_INTERFACES: 'Interfaces de entrada',
    PROFILES: 'Perfiles',
    SHOW_SYSTEM_RULES: 'Mostrar reglas del sistema',
    SYSTEM_RULES_TITLE: 'Reglas implícitas',
    USER_RULES_TITLE: 'Reglas de usuario',
    INVALID_NAME: 'Nombre inválido',
    INVALID_DESCRIPTION: 'Descripción inválida',
    INVALID_DESCRIPTION_LENGTH: 'Longitud de descripción inválida',
    INVALID_NAME_LENGTH: 'Longitud de nombre debe ser entre 2 y 58 caracteres',
    INVALID_ORDER: 'Orden inválido',
    CHANGE_ORDER: 'Cambiar Orden',
    ENABLE_SCHEDULE: 'Regla activada por el programador.',
    DISABLE_SCHEDULE: 'Regla desactivada por el programador.',
    INVALID_SCHEDULE_LENTGH: 'La regla solo puede tener una programación',
    DEVICES_UNSYNCHRONIZED: 'Dispositivos desincronizados: {devices}',
    SSL_PROFILE_INCOMPATIBLE_INTERFACES:
      'Perfil SSL no se puede usar con interfaces bridge',
    SSL_PROFILE_MISSING_INTERFACES:
      'Perfil SSL debe usarse con interfaces de entrada',
    SSL_PROFILE_PROTOCOL_ERROR:
      'Perfil SSL sólo se puede usar con HTTPS, POP3S y SMTPS',
  },
  NAT: {
    TITLE: 'Reglas NAT',
    ADD_RULE_TITLE: 'Añadir regla NAT',
    EDIT_RULE_TITLE: 'Editar regla NAT',
    NAME: 'Nombre',
    NAME_PLACEHOLDER: 'Nombre de la regla NAT...',
    SOURCE: 'Origen NAT (SNAT)',
    DESTINATION: 'Destino NAT (DNAT)',
    TYPE: 'Tipo NAT',
    SERVICES: 'Puerto de redirección DNAT',
    STATIC: 'Estático',
    DYNAMIC: 'Dinámico',
    AUTO: 'Enmascarado',
    AUTO_DESCRIPTION: 'Usar automáticamente la IP de la interfaz de salida',
    SERVICES_TYPE_ERROR: 'Todos los tipos de servicio deben ser el mismo',
    NAT_SERVICES_TYPE_ERROR:
      'El tipo de los servicios debe ser el mismo que el de los servicios NAT',
    SERVICES_PORT_ERROR:
      'Servicios y servicios NAT deben tener un puerto cada uno',
    NAT_FIELD_REQUIRED:
      'Es obligatorio rellenar el campo origen NAT o destino NAT',
    MAX_SOURCE_NAT: 'El número máximo de origenes NAT es 1',
    MAX_DESTINATION_NAT: 'Max nat destination elements is 1',
    MAX_SERVICE_NAT: 'El número máximo de servicios NAT es 1',
    SERVICE_NAT_GROUPS_ERROR: 'Los servicios NAT no pueden tener grupos',
    INTERNAL_INTERFACE: 'Interfaz de salida',
    EXTERNAL_INTERFACE: 'Interfaz de entrada',
    MAX_INTERNAL_INTERFACE: 'El número máximo de interfaces de salida es 1',
    MAX_EXTERNAL_INTERFACE: 'El número máximo de interfaces de entrada es 1',
    UNDELETED_ENTITY: 'La regla no se ha podido eliminar',
    UNPROCESSED_ENTITY: 'La regla no se ha podido procesar',
  },
  OBJECT_EXPLORER: {
    NETWORK_ELEMENTS: 'Elementos de red',
    NETWORKS: 'Redes',
    NETWORK: 'Red',
    NETWORK_IPV4: 'Red IPv4',
    NETWORK_IPV6: 'Red IPv6',
    NETWORK_GROUPS: 'Grupos de Red',
    NETWORK_GROUP: 'Grupo de Red',
    NETWORK_GROUP_ADD: 'Añadir nuevo Grupo de Red',
    NETWORK_GROUP_EDIT: 'Editar Grupo de Red',
    SERVICES: 'Servicios',
    FQDN: 'FQDN',
    FQDN_PLACEHOLDER: 'FQDN...',
    FQDN_ADD: 'Añadir nuevo FQDN',
    FQDN_GROUP: 'Grupo de FQDN',
    FQDN_GROUP_ADD: 'Añadir nuevo Grupo de FQDN',
    FQDN_GROUP_EDIT: 'Editar Grupo de FQDN',
    GEOLOCATION: 'Geolocalización',
    GEOLOCATION_GROUP: 'Grupo de Geolocalización',
    GEOLOCATION_GROUP_ADD: 'Añadir nuevo Grupo de Geolocalización',
    GEOLOCATION_GROUP_EDIT: 'Editar Grupo de Geolocalización',
    IP: 'Dirección IP',
    IPV4: 'Dirección IPV4',
    IPV4_ERROR: 'La dirección IPV4 no es válida',
    IP_RANGE: 'Rango de Direcciones IP',
    NETWORK_IPV4_RANGE: 'Rango de IPv4',
    NETWORK_IPV6_RANGE: 'Rango de IPv6',
    MAC: 'Direcciones MAC',
    MAC_GROUP_ADD: 'Añadir nuevo Grupo de Direcciones MAC',
    MAC_GROUP_EDIT: 'Editar Grupo de Direcciones MAC',
    USERS: 'Usuarios',
    USERS_GROUP: 'Grupo de Usuarios',
    USER_GROUP_ADD: 'Añadir nuevo Grupo de Usuarios',
    USER_GROUP_EDIT: 'Editar Grupo de Usuarios',
    NETWORK_RANGE: 'Rango de IPs',
    NETWORK_RANGE_GROUP_ADD: 'Añadir nuevo Grupo de Rango de IPs',
    NETWORK_RANGE_GROUP_EDIT: 'Editar  Grupo de Rango de IPs',
    GROUPS: 'Grupos',
    SCHEDULES: 'Programaciones',
    SCHEDULE: 'Programación',
    ALL_DAY: 'Todo el día',
    START_TIME: 'Hora de inicio',
    STOP_TIME: 'Hora de parada',
    WEEKDAYS_ERROR: 'Debes seleccionar al menos un día de la semana',
    TIME_ERROR:
      'Debes seleccionar una hora de parada posterior a la hora de inicio',
    ALREADY_IN_USE:
      "El objeto no se puede eliminar porque se está usando en otros lugares. Por favor, revise la lista 'Donde se usa'.",
    ALREADY_IN_USE_GROUP:
      "El objeto no se puede eliminar porque se está usando en otros lugares. Por favor, revise la lista 'Grupos'.",
    ALREADY_IN_USE_DNS:
      'El objeto no se puede eliminar porque se está usando en una configuración de DNS.',
    OBJECT_LIST: 'Lista de objetos',
    EDIT_NOT_ALLOWED: 'No tienes permitido modificar objetos precargados',
    DELETE_NOT_ALLOWED: 'No tienes permitido eliminar objetos precargados',
    TCP_SERVICE_GROUP_ADD: 'Añadir nuevo Grupo de servicios TCP',
    TCP_SERVICE_GROUP_EDIT: 'Editar Grupo de servicios TCP',
    UDP_SERVICE_GROUP_ADD: 'Añadir nuevo Grupo de servicios UDP',
    UDP_SERVICE_GROUP_EDIT: 'Editar Grupo de servicios UDP',
    ICMP_SERVICE_GROUP_ADD: 'Añadir nuevo Grupo de servicios ICMP',
    ICMP_SERVICE_GROUP_EDIT: 'Editar Grupo de servicios ICMP',
    OTHER_SERVICE_GROUP_ADD: 'Añadir nuevo Grupo de otros servicios',
    OTHER_SERVICE_GROUP_EDIT: 'Editar Grupo de otros servicios',
    GROUP_DETACH_SUCCESS: 'Objeto desasociado del grupo correctamente',
    GROUP_EMPTY_ERROR: 'El grupo no se puede quedarse vacío',
    DELETE_SUCCESS: 'Objeto eliminado correctamente',
    TCP_SERVICE: 'Servicio TCP',
    UDP_SERVICE: 'Servicio UDP',
    ICMP_SERVICE: 'Servicio ICMP',
    OTHER_SERVICE: 'Otro servicio',
    TCP_SERVICE_GROUP: 'Grupo de Servicio TCP',
    UDP_SERVICE_GROUP: 'Grupo de Servicio UDP',
    ICMP_SERVICE_GROUP: 'Grupo de Servicio ICMP',
    OTHER_SERVICE_GROUP: 'Grupo de otros servicios',
    CASB_TENANTS: 'Tenants CASB',
    DLP: 'DLP',
    FILE_TYPES: 'Tipos de archivos',
    FILE_NAMES: 'Nombres de archivos',
    CONTENTS: 'Contenido de protocolo',
    DLP_FILE_NAME_GROUP_ADD: 'Añadir grupo de nombre de archivos',
    DLP_FILE_TYPE_GROUP_ADD: 'Añadir grupo de tipo de archivos',
    DLP_FILE_NAME_GROUP_EDIT: 'Editar grupo de nombre de archivos',
    DLP_FILE_TYPE_GROUP_EDIT: 'Editar grupo de tipo de archivos',
    DNS_RECORD: 'Registro DNS',
    DNS_RECORDS: 'Registros DNS',
    IP_ADDRESS_MODERATE: 'Dirección IP (modo moderado)',
    IP_ADDRESS_STRICT: 'Dirección IP (modo estricto)',
    SSL_PROFILE: 'SSL VPN',
    IPSEC_PROFILE: 'IPSEC VPN',
    APPLICATION_STATUS: 'Estado de la aplicación',
    BUILD: 'Build de Windows',
    USER_BUILD: 'Build de usuario',
    SUCCESS_BUILD:
      'Build iniciado satisfactoriamente. El ejecutable estará disponible en unos minutos. Por favor, espera a que el proceso se complete.',
    RUNNING: 'En proceso',
    INACTIVE: 'Desconectado',
  },
  CASB_TENANT_OBJECTS: {
    TARGET: 'Dominio CASB',
    ADD: 'Añadir Tenant CASB',
    EDIT: 'Editar Tenant CASB',
    EDIT_SUCCESS: 'Tenant CASB actualizado correctamente',
    ADD_SUCCESS: 'Tenant CASB añadido correctamente',
  },
  NETWORK_OBJECTS: {
    CASB_ID: 'CASB ID',
    CASB_ID_PLACEHOLDER: 'CASB ID...',
    IP: 'IP',
    IPV4: 'IPv4',
    IPV4_RANGE: 'Rango IPv4',
    IPV6: 'IPv6',
    IPV6_RANGE: 'Rango IPv6',
    NET_MASK: 'Máscara de Red',
    NET_MASK_IPV4: 'Máscara de Red IPv4',
    NET_MASK_IPV6: 'Máscara de Red IPv6',
    GROUPS: 'Grupos',
    PROTOCOL: 'Protocolo',
    PORT: 'Puerto',
    DETACH: 'Desasociar',
    REMOVE_NETWORK_FROM_GROUP: 'Eliminar red de este grupo',
    GROUP_ADD_SUCCESS: 'Grupo de red creado con éxito',
    GROUP_ADD_ERROR: 'Error al crear el grupo de red',
    GROUP_EDIT_SUCCESS: 'Grupo de red actualizado con éxito',
    GROUP_EDIT_ERROR: 'Error al actualizar el grupo de red',
    GROUP_DELETE_SUCCESS: 'Grupo de red eliminado correctamente',
    GROUP_UPDATE_SUCCESS: 'Grupo de red actualizado correctamente',
    GROUP_ALREADY_IN_USE:
      "El grupo de red no se puede eliminar porque se está usando en otros lugares. Por favor, revise la lista 'Donde se usa'.",
    GROUP_IPV4_ERROR: 'Al menos una de las direcciones IPv4 no es válida',
    GROUP_IPV6_ERROR: 'Al menos una de las direcciones IPv6 no es válida',
    INVALID_IP_MASK: 'Máscara IP inválida',
    TYPE: 'Tipo',
    MAC_ADDRESS: 'Dirección MAC',
    MAC_ADDRESS_ADD: 'Añadir nueva Dirección MAC',
    MAC_ADDRESS_GROUP: 'Grupo de Dirección MAC',
    DOMAIN: 'Dominio',
    DOMAIN_PLACEHOLDER: 'Dominio...',
    COMPUTER: 'Ordenador',
    COMPUTER_PLACEHOLDER: 'Ordenador...',
    GROUP: 'Grupo',
    GROUP_PLACEHOLDER: 'Grupo...',
    LOAD_ERROR: 'Error al cargar objetos de red',
    ALREADY_IN_USE:
      "El objeto de red no se puede eliminar porque se está usando en otros lugares. Por favor, revise la lista 'Donde se usa'.",
    DELETE_SUCCESS: 'Objeto de red eliminado correctamente',
    ADD: 'Añadir nueva Red',
    ADD_SUCCESS: 'Objeto de red creado con éxito',
    ADD_ERROR: 'Error al crear el objeto de red',
    EDIT: 'Editar Red',
    EDIT_SUCCESS: 'Objeto de Red actualizada con éxito',
    EDIT_ERROR: 'Error al actualizar el objeto de red',
    RANGE: 'Rango de Red',
    RANGE_GROUP: 'Grupo de Rango de Red',
    SOURCE_SELECT: 'Seleccionar Origen',
    SOURCES_SELECT: 'Seleccionar Origen',
    DESTINATION_SELECT: 'Seleccionar Destino',
    SERVICE_SELECT: 'Seleccionar Servicio',
    RECORD_SELECT: 'Seleccionar Registros',
    FQDN_SELECT: 'Seleccionar FQDN',
    NETWORK_SELECT: 'Seleccionar Red',
    NETWORK_RANGE_SELECT: 'Seleccionar Rango de red',
    GEOLOCATION_SELECT: 'Seleccionar Geolocalización',
    TCP_SERVICE_SELECT: 'Seleccionar Servicio TCP',
    UDP_SERVICE_SELECT: 'Seleccionar Servicio UDP',
    ICMP_SERVICE_SELECT: 'Seleccionar Servicio ICMP',
    OTHER_SERVICE_SELECT: 'Seleccionar Servicio other',
    MAC_SELECT: 'Seleccionar MAC',
    USER_SELECT: 'Seleccionar Usuario',
    DLP_FILE_TYPE_SELECT: 'Seleccionar Tipo de Archivo DLP',
    DLP_FILE_NAME_SELECT: 'Seleccionar Nombre de Archivo DLP',
    DLP_CONTENT_GROUP_ADD: 'Añadir Contenido DLP',
    DLP_CONTENT_SELECT: 'Seleccionar Contenido DLP',
    FIELD_REQUIRED:
      'Se requiere rellenar al menos uno de los campos IPv4 o IPv6',
    IPV4_ERROR:
      'El campo IPv4 no es válido. Por favor, revise la dirección IP y la máscara de red.',
    IPV6_ERROR:
      'El campo IPv6 no es válido. Por favor, revise la dirección IP y la máscara de red.',
    FIREWALL_IPV4_ERROR:
      "El campo IPv4 no puede estar vacío porque está siendo usado en una regla firewall con protocolo IPv4. Por favor, revise la lista 'Donde se usa'.",
    FIREWALL_IPV6_ERROR:
      "El campo IPv6 no puede estar vacío porque está siendo usado en una regla firewall con protocolo IPv6. Por favor, revise la lista 'Donde se usa'.",
    IPS: 'Listado de IPs',
    CASB_TENANT_PLACEHOLDER: 'Dominio CASB...',
    SOURCE: 'Origen',
    AD_SERVERS: 'Configuraciones Azure',
    EMAIL: 'Email',
    DUPLICATE_EMAIL: 'Duplicate Email',
    CREATE_BUILD: 'Ejecutable del Build de windows',
    DOWNLOAD_BUILD: 'Descargar el Cliente Windows',
    SEND_EMAIL: 'Enviado vía email',
    API_KEY: 'API Key',
    REGENERATE_API_KEY: 'Regenerar la API Key',
    CONFIRM_API_REGENERATE: '¿Está seguro de que quiere regenerar la API Key?',
    API_KEY_REGENERATE_SUCCESS: 'API Key regenerada con éxito',
    SYNC_AZURE_AD_USERS: 'Lista de usuarios Azure AD',
    SYNCED_USERS: 'Usuarios sincronizados',
    SYNCED_SUCCESS: 'Syncronizado con éxito',
    SYNC_FAILED: 'Syncronización fallida',
  },
  NETWORK_RANGE_OBJECTS: {
    ALREADY_IN_USE:
      "El objeto de Rango de IP no se puede eliminar porque se está usando en otros lugares. Por favor, revise la lista 'Donde se usa'.",
    DELETE_SUCCESS: 'Objeto de Rango de IP eliminado correctamente',
    ADD: 'Añadir nuevo Rango de IP',
    ADD_SUCCESS: 'Objeto de Rango de IP creado con éxito',
    ADD_ERROR: 'Error al crear el objeto Rango de IP',
    EDIT: 'Editar Rango de IP',
    EDIT_SUCCESS: 'Objeto de Rango de IP actualizado con éxito',
    EDIT_ERROR: 'Error al actualizar el objeto Rango de IP',
    FIELD_REQUIRED:
      'Se requiere rellenar al menos uno de los campos de rango IPv4 o IPv6',
    IPV4_FORMAT_ERROR:
      'El campo IPv4 debe tener un formato de rango como X.X.X.X - X.X.X.X',
    IPV6_FORMAT_ERROR:
      'El campo IPv6 debe tener un formato de rango como ::X - ::X',
    IPV4_ERROR:
      'Al menos una de las direcciones IPv4 no es válida. Por favor, revise las direcciones IP. Formato: X.X.X.X - X.X.X.X.',
    IPV6_ERROR:
      'Al menos una de las direcciones IPv6 no es válida. Por favor, revise las direcciones IP. Formato: ::X - ::X.',
  },
  FQDN_OBJECTS: {
    ADD_SUCCESS: 'Objeto FQDN creado con éxito',
    ADD_ERROR: 'Error al crear el objeto FQDN',
  },
  MAC_OBJECTS: {
    ADD_SUCCESS: 'Objeto de Dirección MAC creado con éxito',
    ADD_ERROR: 'Error al crear el objeto de Dirección MAC',
  },
  USER_OBJECTS: {
    ADD: 'Añadir nuevo Usuario',
    ADD_SUCCESS:
      'El objeto de usuario se ha creado o actualizado correctamente',
    ADD_ERROR: 'Error al crear el objeto Usuario',
    EDIT: 'Editar Usuario',
    EDIT_SUCCESS: 'Objeto de Usuario actualizado con éxito',
    EDIT_ERROR: 'Error al actualizar objeto de Usuario',
    NO_DATA: 'No data available',
    NO_AZURE_CONFIG: 'No configuration selected',
  },
  SERVICES: {
    ALREADY_IN_USE:
      "El objeto de Servicio no se puede eliminar porque se está usando en otros lugares. Por favor, revise la lista 'Donde se usa'.",
    ADD_SUCCESS: 'Objeto de Servicio creado con éxito',
    ADD_ERROR: 'Error al crear el objeto Servicio',
    EDIT_SUCCESS: 'Objecto de Servicio actualizado con éxito',
    EDIT_ERROR: 'Error al actualizar el objeto de Servicio',
    DELETE_SUCCESS: 'Objeto de Servicio eliminado correctamente',
    TCP_SERVICE: 'Servicio TCP',
    UDP_SERVICE: 'Servicio UDP',
    ICMP_SERVICE: 'Servicio ICMP',
    OTHER_SERVICE: 'Otros servicios',
    ADD: 'Añadir nuevo Servicio',
    EDIT: 'Editar Servicio',
    SELECT: 'Seleccionar Servicios',
    PORTS: 'Puertos',
    PORTS_PLACEHOLDER: '80,443,7000-7009,514:512-1023',
    ICMP_TYPE: 'Tipo ICMP',
    ICMP_TYPE_PLACEHOLDER: 'Tipo ICMP...',
    ICMP_CODE: 'Código ICMP',
    ICMP_CODE_PLACEHOLDER: 'Código ICMP...',
    PROTOCOL_NUMBER: 'Número de protocolo',
    PROTOCOL_NUMBER_PLACEHOLDER: 'Número de protocolo...',
    LOAD_ERROR: 'Error al cargar objetos de servicio',
    PORTS_ERROR: 'Al menos uno de los puertos es inválido',
    ICMP_TYPE_ERROR: 'Tipo ICMP es inválido',
    ICMP_CODE_ERROR: 'Código ICMP es inválido',
    PROTOCOL_NUMBER_ERROR: 'Número de protocolo es inválido',
    ICMP_GROUP_ALL_ERROR:
      'No puedes seleccionar otros servicios ICMP cuando ALL_ICMP o ALL_ICMP6 ya esté seleccionado',
    ICMP_VERSIONS_ERROR: 'No puedes mezclar servicios ICMP e ICMP6',
  },
  SCHEDULE_OBJECTS: {
    LOAD_ERROR: 'Error al cargar objetos de programación',
    ALREADY_IN_USE:
      "El objeto no se puede eliminar porque se está usando en otros lugares. Por favor, revise la lista 'Donde se usa'.",
    SCHEDULE_OBJECT_DELETED_SUCCESS:
      'Objeto de Programación eliminado correctamente',
    ADD: 'Añadir nueva Programación',
    EDIT: 'Editar Programación',
    SELECT: 'Seleccionar Programación',
  },
  DNS_RECORD_OBJECTS: {
    LOAD_ERROR: 'Error al cargar objetos de registro DNS',
    ALREADY_IN_USE:
      "El objeto no se puede eliminar porque se está usando en otros lugares. Por favor, revise la lista 'Donde se usa'.",
    DNS_RECORD_OBJECT_DELETED_SUCCESS:
      'Objeto de registro DNS eliminado correctamente',
    ADD: 'Añadir nuevo registro DNS',
    EDIT: 'Editar registro DNS',
    SELECT: 'Seleccionar registro DNS',
    CONFIRM_DELETE: '¿Está seguro de que quiere eliminar este registro DNS?',
    DESELECT_NOT_ALLOWED:
      'Los registros DNS iniciales no pueden ser desmarcados',
    ERROR_DUPLICATED:
      'El objeto de registro DNS no puede tener el mismo nombre y dirección IP que otro registro DNS existente',
  },
  OBJECT_MODAL: {
    ADD: 'Añadir',
    CANCEL: 'Cancelar',
    EDIT: 'Edit',
  },
  CASB: {
    MICROSOFT365: 'Microsoft 365',
    SALESFORCE: 'Salesforce',
    GOOGLE_DOCUMENTS: 'Google Cloud',
    BOX: 'BOX',
    ACTION: 'Estado',
    RULE_NAME: 'Nombre de la regla',
    SOURCE: 'Usuarios',
    APPLICATION: 'Aplicación',
    TRUST: 'Confianza',
    CONTENT: 'Perfil DLP',
    ACTIVITY: 'Actividad',
    CASB_TENANT: 'Tenant CASB',
    EDIT_RULE_TITLE: 'Editar regla de CASB',
    ADD_RULE_TITLE: 'Añadir regla de CASB',
  },
  XDR: {
    TITLE: 'Politicas XDR',
    ADD: 'Añadir politica XDR',
    CONFIRM_DELETE: '¿Está seguro de que quiere eliminar esta regla?',
    DELETE_SUCCESS: 'Regla eliminada correctamente',
    ADD_SUCCESS: 'Regla añadida correctamente',
    COMPROMISED: 'Dispositivo comprometido',
    CPU: 'Alto uso de CPU',
    MEMORY: 'Alto uso de memoria',
    ISOLATE: 'Aislar dispositivo',
    EMAIL: 'Enviar email',
    SDWAN: 'Aislar dispositivo en SDWAN',
    REDIRECT: 'Redirigir tráfico',
    NAME: 'Nombre',
    EVENT: 'Evento',
    ACTION: 'Acción',
    NAME_PLACEHOLDER: 'Nueva política XDR',
    TRIGGER: 'Eventos XDR',
    POLICY_NAME: 'Nombre de política',
    USER_NAME: 'Nombre de usuario',
    ACKNOWLEDGE: 'Reparar',
  },
  INACTIVITY_CONTROL: {
    INACTIVE_SESSION: 'Sesión inactiva',
    INACTIVITY_WARNING:
      'La sesión expirará en {s} segundos debido a la inactividad.',
    STAY_LOGGED_IN: 'Permanecer conectado',
  },
  WEB_FILTER: {
    BASIC: 'Básico',
    MEDIUM: 'Medio',
    HIGH: 'Alto',
    CUSTOM: 'Personalizado',
    ADS: 'Anuncios',
    PARKED_DOMAINS: 'Dominios parqueados',
    WEB_ADVERTISEMENTS: 'Anuncios web',
    DANGEROUS: 'Peligroso',
    GAMBLING: 'Apuestas',
    HACKING: 'Hacking',
    OPEN_HTTP_PROXIES: 'HTTP Proxies libres',
    PAY_TO_SURF: 'Pagar para navegar',
    PEER_TO_PEER: 'Peer to Peer',
    FREE_TIME: 'Tiempo libre',
    COMPUTER_AND_INTERNET_INFO: 'Noticias sobre Ordenadores e Internet',
    ENTERTAINMENT_AND_ARTS: 'Entretenimiento y Arte',
    FASHION_AND_BEAUTY: 'Moda y Belleza',
    FOOD_AND_DINING: 'Comida y Bebida',
    HOME_AND_GARDEN: 'Casa y Jardin',
    HUNTING_AND_FISHING: 'Caza y Pesca',
    INTERNET_PORTALS: 'Portales de Internet',
    KIDS: 'Contenido Infantil',
    LOCAL_INFORMATION: 'Información local',
    MOTOR_VEHICLES: 'Automoción',
    ONLINE_GREETING_CARDS: 'Tarjetas de felicitación',
    PERSONAL_SITES_AND_BLOGS: 'Sitios personales y Blogs',
    PHILOSOPHY_AND_POLITICAL_ADVOCACY: 'Filosofía y Política',
    QUESTIONABLE: 'Contenido cuestionable',
    REAL_ESTATE: 'Inmuebles',
    RECREATION_AND_HOBBIES: 'Hobbies',
    RELIGION: 'Religión',
    SHOPPING: 'Compras',
    SOCIETY: 'Sociedad',
    SPORTS: 'Deportes',
    TRAVEL: 'Viajes',
    MALWARE_THREATS: 'Eventos de Malware',
    BOT_NETS: 'Bot Nets',
    CONFIRMED_SPAM_SOURCES: 'Fuentes de spam confirmadas',
    KEYLOGGERS_AND_MONITORING: 'Keyloggers y Monitorización',
    MALWARE_SITES: 'Sitios de Malware',
    PHISHING_AND_OTHER_FRAUDS: 'Phising y otros fraudes',
    PROXY_AVOIDANCE_AND_ANONYMIZERS: 'Elusión de proxies y anonimizadores',
    SPAM_URLS: 'Spam URLs',
    SPYWARE_AND_ADWARE: 'Spyware y Adware',
    UNCONFIRMED_SPAM_SOURCES: 'Fuentes de spam no confirmadas',
    MEDIA: 'Multimedia',
    MUSIC: 'Música',
    NEWS_AND_MEDIA: 'Noticias y medios de información',
    SOCIAL_NETWORKING: 'Redes sociales',
    STREAMING_MEDIA: 'Contenido en streaming',
    OTHERS: 'Otros',
    UNCATEGORIZED: 'Sin categorizar',
    UNRANKED: 'Sin clasificar',
    UNRANKED_TRAFFIC: 'Tráfico sin clasificar',
    SEARCH_ENGINES: 'Motores de búsqueda',
    IMAGE_AND_VIDEO_SEARCH: 'Buscadores de imágen y vídeo',
    JOB_SEARCH: 'Buscadores de empleo',
    TECHNOLOGY: 'Tecnología',
    COMPUTER_AND_INTERNET_SECURITY: 'Seguridad de Ordenadores e Internet',
    CONTENT_DELIVERY_NETWORKS: 'Redes de distribución de contenidos',
    DYNAMICALLY_GENERATED_CONTENT: 'Contenido generado dinámicamente',
    INDIVIDUAL_STOCK_ADVICE_AND_TOOLS: 'Herramientas para la gestión de Stock',
    INTERNET_COMMUNICATIONS: 'Comunicaciones en Internet',
    PERSONAL_STORAGE: 'Almacenamiento personal',
    SHAREWARE_AND_FREEWARE: 'Shareware y Freeware',
    TRANSLATION: 'Traducción',
    WEB_HOSTING: 'Web hosting',
    WEB_BASED_EMAIL: 'Web Email',
    UNWANTED_WEBSITES: 'Sitios web no deseados',
    ABORTION: 'Aborto',
    ABUSED_DRUGS: 'Drogas',
    ADULT_AND_PORNOGRAPHY: 'Contenido Adulto y Pornografía',
    ALCOHOL_AND_TOBACCO: 'Alcohol y Tacabo',
    AUCTIONS: 'Subastas',
    CHEATING: 'Trampas',
    CULT_AND_OCCULT: 'Sectas y sobrenatural',
    DATING: 'Citas',
    DEAD_SITES: 'Sitios perdidos',
    GAMES: 'Juegos',
    GROSS: 'Contenido grosero',
    HATE_AND_RACISM: 'Odio y Racismo',
    ILLEGAL: 'Contenido ilegal',
    MARIJUANA: 'Marihuana',
    MILITARY: 'Militar',
    NUDITY: 'Desnudos',
    PRIVATE_IP_ADDRESSES: 'Direcciones IP privadas',
    SEX_EDUCATION: 'Educación sexual',
    SWIMSUITS_AND_INTIMATE_APPAREL: 'Bañadores y ropa íntima',
    VIOLENCE: 'Violencia',
    WEAPONS: 'Armas',
    WORK: 'Trabajo',
    BUSINESS_AND_ECONOMY: 'Negocios y Economía',
    EDUCATIONAL_INSTITUTIONS: 'Instituciones educativas',
    FINANCIAL_SERVICES: 'Servicios Financieros',
    GOVERNMENT: 'Gobierno',
    HEALTH_AND_MEDICINE: 'Salud y Medicina',
    LEGAL: 'Jurídico',
    REFERENCE_AND_RESEARCH: 'Investigación y Bibliografía',
    TRAINING_AND_TOOLS: 'Herramientas educativas',
    SELF_HARM: 'Autolesiones',
    DNS_OVER_HTTPS: 'DNS sobre HTTPS',
    LOW_THC_CANNABIS_PRODUCTS:
      'Productos de cannabis con bajo contenido de THC',
    GENERATIVE_AI: 'IA Generativa',
    CATEGORIES: 'Categorías',
    CUSTOM_CATEGORIES: 'Categorías personalizadas',
    CATEGORY_NAME: 'Nombre',
    CATEGORY_COUNT: 'Cuenta',
    CATEGORY_STATE: 'Estado',
    ERROR_INDEX: 'Error al obtener categorías',
    SUCCESS_LEVEL_SET: 'Nivel de protección actualizado correctamente',
    ERROR_LEVEL_SET: 'Error al actualizar el nivel de protección',
    ERROR_UPDATING_CATEGORY: 'Error al actualizar categoría',
    ERROR_UPDATING_SUBCATEGORY: 'Error al actualizar subcategoría',
    BASIC_BLOCKED:
      'CATEGORÍAS BLOQUEADAS: Sitios Malware, Phising y otros Fraudes, Spyware y Adware, Elusión de proxies y anonimizadores, Fuentes de spam confirmadas, Apuestas, Contenido grosero, Keyloggers y Monitorización, Engaños, Contenido Ilegal, Violencia, Bot Nets, Hacking, Armas',
    MEDIUM_BLOCKED:
      'CATEGORÍAS BLOQUEADAS: Sitios Malware, Phising y otros Fraudes, Spyware y Adware, Elusión de proxies y anonimizadores, Fuentes de spam confirmadas, Apuestas, Contenido grosero, Keyloggers y Monitorización, Engaños, Contenido Ilegal, Violencia, Bot Nets, Hacking, Viajes, Drogas, Juegos, Marihuana, Spam URLs, Dominios Parqueados, Armas',
    HIGH_BLOCKED:
      'CATEGORÍAS BLOQUEADAS: Sitios Malware, Phising y otros Fraudes, Spyware y Adware, Elusión de proxies y anonimizadores, Fuentes de spam confirmadas, Apuestas, Contenido grosero, Keyloggers y Monitorización, Engaños, Contenido Ilegal, Violencia, Bot Nets, Hacking, Viajes, Drogas, Juegos, Marihuana, Spam URLs, Dominios Parqueados, Subastas, Compras, Sectas y sobrenatural, Streaming, Armas, Tarjetas Regalo, Odio y Racismo, Alcohol y Tabaco, Redes Sociales',
    WILDCARD: 'Wildcard',
    REGEX: 'Expresión regular',
    EXPRESSION_TYPE: 'Tipo de expresión',
    URLS: 'Urls',
    ADD_CUSTOM_CATEGORY: 'Crear categoría personalizada',
    EDIT_CUSTOM_CATEGORY: 'Editar categoría personalizada',
    SUCCESS_DELETE: 'Categoría personalizada eliminada con éxito',
    ERROR_DELETE: 'Error al eliminar categoría personalizada',
    SUCCESS_CREATE: 'Categoría personalizada creada con éxito',
    ERROR_CREATE: 'Error al crear categoría personalizada',
    SUCCESS_UPDATE: 'Categoría personalizada actualizada con éxito',
    ERROR_UPDATE: 'Error al actualizar categoría personalizada',
    CONTENT_FILTER: 'Filtro de contenido',
    WEB_CONTENT_FILTERS: 'Filtros de contenido web',
    SUCCESS_CONTENT_FILTER_SET: 'Filtro de contenido actualizado correctamente',
    ERROR_CONTENT_FILTER_SET: 'Error al actualizar filtro de contenido',
    ERROR_INDEX_CONTENT_FILTER: 'Error al obtener filtros de contenido web',
    ERROR_UPDATE_CONTENT_FILTER: 'Error al actualizar filtro de contenido web',
    SUCCESS_UPDATE_CONTENT_FILTER:
      'Filtro de contenido web actualizado correctamente',
    SUCCESS_DELETE_CONTENT_FILTER:
      'Filtro de contenido web eliminado correctamente',
    ERROR_DELETE_CONTENT_FILTER: 'Error al eliminar filtro de contenido web',
    SUCCESS_CREATE_CONTENT_FILTERS:
      'Filtro de contenido web creado correctamente',
    ERROR_CREATE_CONTENT_FILTERS: 'Error al crear filtro de contenido web',
    EDIT_CONTENT_FILTER: 'Editar filtro de contenido web',
    ADD_CONTENT_FILTER: 'Crear filtro de contenido web',
    REPUTATION: 'Reputación mínima permitida',
    SUCCESS_REPUTATION_SET: 'Reputación actualizada correctamente',
    ERROR_REPUTATION_SET: 'Error al actualizar reputación',
    ALLOW_ALL: 'Permitir todo',
    URLS_DESCRIPTION:
      'Wildcard *: URL con el caracter wildcard de forma opcional al comienzo o final de la URL. El caracter wildcard representa 0 o más caracteres variables o desconocidos\nRegex (.*): Expresión regular de una URL siguiendo el estandar PCRE',
    BAD_WILDCARD_EXPRESSION:
      'Expresión wildcard incorrecta: si el caracter wildcard (*) esta presente, debe estar al comienzo o final de la expresión',
    SEARCH_PROTECTION:
      'Protección de búsquedas de Google®, Bing®, Yahoo® y Youtube®',
    SEARCH_PROTECTION_HELP:
      'Active la protección de búsquedas de Google® y Bing® para limitar los resultados de búsqueda (páginas web, imágenes y contenido), sólo se mostrarán los resultados seguros y apropiados. Pornografía y otros contenidos indeseados serán excluidos de los resultados de búsqueda.',
    SUCCESS_SEARCH_PROTECTION_SET:
      'Protección de búsquedas actualizado correctamente',
    ERROR_SEARCH_PROTECTION_SET: 'Error al actualizar protección de búsquedas',
    MODERATE: 'Moderado',
    STRICT: 'Estricto',
    YOUTUBE_MODE: 'Restricción de Youtube',
    SUCCESS_YOUTUBE_MODE_SET:
      'Nivel de restricción de Youtube actualizado con éxito',
    ERROR_YOUTUBE_MODE_SET:
      'Error al actualizar el nivel de restricción de Youtube',
  },
  IPS_EXCEPTION: {
    ORIGIN_IP: 'IP Origen',
    PORT: 'Puerto',
    DESTINATION_IP: 'IP Destino',
    ORIGIN: 'Origen',
    DESTINATION: 'Destino',
    ACTIONS: 'Acciones',
  },
  TEMPLATES: {
    TITLE: 'Extensión de CLI',
    SUCCESS_CREATE: 'Plantilla created con éxito',
    ERROR_CREATE: 'Error al crear plantilla',
    SUCCESS_UPDATE: 'Plantilla actualizada con éxito',
    ERROR_UPDATE: 'Error al actualizar plantilla',
    TEMPLATE: 'Plantilla',
    EDIT: 'Editar plantilla',
    ADD: 'Crear plantilla',
    SUCCESS_CLI: 'Extension de CLI actualizada con éxito',
    ERROR_CLI: 'Error al actualizar extensión de CLI',
    SUCCESS_DELETE: 'Plantilla borrada con éxito',
    ERROR_DELETE: 'Error al borrar plantilla',
  },
  SYSLOGS: {
    DUPLICATED_NAME: 'El nombre no puede estar duplicado',
    WRONG_PORT: 'El puerto debe ser un número entre 1 y 65535',
    WRONG_PROTOCOL: 'El protocolo debe ser TCP o UDP',
    WRONG_HOST: 'Host incorrecto',
    MAXIMUM_REACHED: 'Sólo se permiten 4 entradas de syslog',
    NAME: 'Nombre',
    HOST: 'Host',
    PROTOCOL: 'Protocolo',
    PORT: 'Puerto',
    ACTIONS: 'Acciones',
    SUCCESS_DELETE: 'Syslog borrado con éxito',
    ERROR_DELETE: 'Error al borrar syslog',
    EDIT: 'Editar syslog',
    ADD: 'Crear syslog',
    ERROR_UPDATE: 'Error al actualizar syslog',
    SUCCESS_UPDATE: 'Syslog actualizado con éxito',
    ERROR_CREATE: 'Error al crear syslog',
    SUCCESS_CREATE: 'Syslog creado con éxito',
    CA_FILE: 'Archivo CA',
    CA_FILE_HELP:
      'Este campo puede estar vacío si tienes un certificado firmado por una Autoridad Certificadora (CA)',
    UPLOAD_CA_FILE: 'Subir un certificado CA',
    REQUIRE_CA_FILE: 'Se requiere un archivo CA para esta configuración',
    ENCRYPTED: 'Activar cifrado',
    ENCRYPTED_COLUMN: 'Cifrado',
    TRUST_UNKNOWN_CA: 'Confiar en CAs desconocidas',
    MUTUAL_TLS: 'Activar TLS mutuo',
    MUTUAL_TLS_COLUMN: 'TLS Mutuo',
    MUTUAL_TLS_HELP:
      'Se requiere un certificado de cliente para usar el TLS mutuo',
    CLIENT_CERT: 'Certificado de cliente',
    CLIENT_KEY: 'Clave de cliente',
    UPLOAD_CLIENT_CERT: 'Subir un certificado de cliente',
    REQUIRE_CLIENT_CERT:
      'Se requiere un certificado de cliente para esta configuración',
    UPLOAD_CLIENT_KEY: 'Subir una clave de cliente',
    REQUIRE_CLIENT_KEY:
      'Se requiere una clave de cliente para esta configuración',
  },
  FORWARD_SYSLOGS: {
    MAXIMUM_REACHED: 'Sólo se permiten 3 entradas de syslog',
    ENCRYPTED: 'Cifrado',
    ENCRYPTED_HELP:
      'Se requiere un certificado para usar el cifrado. Puedes añadir uno en la sección de Administración.',
  },
  REMOTE_ACCESS: {
    ONLINE_USERS: 'Usuarios online',
    CONCURRENT_USERS_WARNING:
      'Se ha sobrepasado el número máximo de usuarios concurrentes que permiten las licencias',
  },
  VPNTUNNEL: {
    CONFIRM_DELETE: '¿Desea eliminar el túnel?',
    DELETE_SUCCESS: 'Túnel borrado con éxito',
    DELETE_ERROR: 'Error al borrar túnel',
    NAME: 'Nombre',
    SOURCE: 'IP Pool',
    INTERFACE: 'Interfaz',
    PORT: 'Puerto',
    PROTOCOL: 'Protocolo',
    ENABLE_SPLIT_TUNNELING: 'Activar división de túnel',
    SPLIT_TUNNELING: 'Túnel dividido',
    SUCCESS_CREATE: 'Túnel creado con éxito',
    ERROR_CREATE: 'Error al crear túnel',
    SUCCESS_UPDATE: 'Túnel actualizado con éxito',
    ERROR_UPDATE: 'Error al actualizar túnel',
    EDIT_TUNNEL: 'Editar túnel',
    ADD_TUNNEL: 'Crear túnel',
    ROUTING_ADDRESS: 'Dirección de enrutamiento',
    DNS1: 'DNS primario',
    DNS2: 'DNS secundario',
    AUTHENTICATION: 'Autenticación',
    CONNECTION_SETTINGS: 'Configuración de conexión',
    TUNNEL_AUTHENTICATION: 'Modo túnel y autenticación',
    DNS: 'DNS',
    UPLOAD_CLIENT_CERT: 'Cargar certificado de cliente',
    USERS: 'Usuarios',
    SERVER_DEFINITION: 'Definición de servidor',
    DESTINATION_SERVER: 'Servidor de destino SSL',
    IP: 'IP',
    HOSTNAME: 'Hostname',
    INVALID_PORT: 'Puerto no válido',
    INVALID_PROTOCOL: 'Protocolo no válido',
    INVALID_DNS: 'DNS no válido',
    INVALID_IP: 'IP no válida',
    INVALID_HOSTNAME: 'Hostname no válido',
    NO_USERS_ERROR: 'No hay usuarios asignados al túnel',
    ANY_NETWORK_PRESENT_ERROR: 'La red Any no puede estar presente en el túnel',
    DISABLED_APPLICATIONS: 'Aplicaciones deshabilitadas',
    IP_POOL_ROUTING_ADDRESS_ERROR:
      'La dirección de enrutamiento no puede ser la misma que la IP Pool',
  },
  SSLVPN: {
    CONFIRM_DELETE: '¿Desea eliminar el túnel?',
    DELETE_SUCCESS: 'Túnel borrado con éxito',
    DELETE_ERROR: 'Error al borrar túnel',
    NAME: 'Nombre',
    SOURCE: 'IP Pool',
    INTERFACES: 'Interfaces',
    PORT: 'Puerto',
    ENABLE_SPLIT_TUNNELING: 'Activar división de túnel',
    SPLIT_TUNNELING: 'Túnel dividido',
    TITLE: 'SSL VPN',
    SUCCESS_CREATE: 'Túnel creado con éxito',
    ERROR_CREATE: 'Error al crear túnel',
    SUCCESS_UPDATE: 'Túnel actualizado con éxito',
    ERROR_UPDATE: 'Error al actualizar túnel',
    EDIT_TUNNEL: 'Editar túnel',
    ADD_TUNNEL: 'Crear túnel',
    ROUTING_ADDRESS: 'Dirección de enrutamiento',
    DNS1: 'DNS primario',
    DNS2: 'DNS secundario',
    AUTHENTICATION: 'Método de autenticación',
    CONNECTION_SETTINGS: 'Configuración de conexión',
    TUNNEL_AUTHENTICATION: 'Modo túnel y autenticación',
    DNS: 'DNS',
    UPLOAD_CLIENT_CERT: 'Cargar autoridad de certificación',
    BUILD_EXE: 'Build de Windows',
    SUCCESS_BUILD:
      'Build iniciado satisfactoriamente. El ejecutable estará disponible en unos minutos. Por favor, espera a que el proceso se complete.',
    BUILD_STATUS:
      'Un build está actualmente en progreso. Por favor, intenta la creación en masa después de 5 minutos.',
    NO_USERS: 'No hay usuarios asignados al tunel',
    CLIENT_CERT: 'Certificado de Usuario',
    USERS: 'Credenciales de Usuario',
    USER_LIST: 'Lista de Usuarios',
  },
  IPSECVPN_REMOTE: {
    CONFIRM_DELETE: '¿Desea eliminar el túnel?',
    DELETE_SUCCESS: 'Túnel borrado con éxito',
    DELETE_ERROR: 'Error al borrar túnel',
    NAME: 'Nombre',
    SOURCE: 'IP Pool',
    INTERFACES: 'Interfaces',
    PORT: 'Puerto',
    ENABLE_SPLIT_TUNNELING: 'Activar división de túnel',
    SPLIT_TUNNELING: 'Túnel dividido',
    TITLE: 'IPSEC Vpn',
    SUCCESS_CREATE: 'Túnel creado con éxito',
    ERROR_CREATE: 'Error al crear túnel',
    SUCCESS_UPDATE: 'Túnel actualizado con éxito',
    ERROR_UPDATE: 'Error al actualizar túnel',
    EDIT_TUNNEL: 'Editar túnel',
    ADD_TUNNEL: 'Crear túnel',
    BUILD_EXE: 'Build de Windows',
    SUCCESS_BUILD:
      'Build iniciado satisfactoriamente. El ejecutable estará disponible en unos minutos. Por favor, espera a que el proceso se complete.',
    BUILD_STATUS:
      'Un build está actualmente en progreso. Por favor, intenta la creación en masa después de 5 minutos.',
    NO_USERS: 'No hay usuarios asignados al tunel',
  },
  VPN_USERS: {
    TITLE: 'Usuarios',
    SUCCESS_CREATE: 'Usuario creado con éxito',
    ERROR_CREATE: 'Error al crear usuario',
    SUCCESS_UPDATE: 'Usuario actualizado con éxito',
    ERROR_UPDATE: 'Error al actualizar usuario',
    EDIT_USER: 'Editar Usuario',
    ADD_USER: 'Crear Usuario',
    CONFIRM_DELETE: '¿Desea eliminar el usuario?',
    DELETE_SUCCESS: 'Usuario borrado con éxito',
    DELETE_ERROR: 'Error al borrar usuario',
    EMAIL: 'Email',
    GROUPS: 'Grupos',
    GLOBAL_SETTINGS: 'Configuración de usuarios',
    LOCAL: 'Usuarios locales',
  },
  TENANT: {
    ID: 'ID',
    DUPLICATED_EMAIL: 'Correo electrónico ya esta en uso',
    DUPLICATED_NAME: 'Nombre de cliente ya esta en uso',
    IPS_TYPE_ADVANCED: 'Avanzado',
    IPS_TYPE_BASIC: 'Básico',
    IPS_TYPE: 'Tipo de IPS',
    SUCCESS: 'El cliente ha sido creado con éxito',
    TYPE: 'Tipo',
    DETAILS: 'Detalles del cliente',
    ADMINISTRATOR: 'Administrador',
    CLOUD_DEVICES: 'Dispositivos Cloud',
    UUID: 'UUID',
    ADD_CLOUD_DEVICE: 'Agregar dispositivo Cloud',
    CLOUD_IP: 'IP',
    UPDATE_SUCCESS: 'El cliente ha sido actualizado con éxito',
    UPDATE_ERROR: 'Error al actualizar el cliente',
    NAME: 'Nombre',
  },
  VPN_USER_GROUPS: {
    TITLE: 'Grupos de usuarios',
    SUCCESS_CREATE: 'Grupo creado con éxito',
    ERROR_CREATE: 'Error al crear grupo',
    SUCCESS_UPDATE: 'Grupo actualizado con éxito',
    ERROR_UPDATE: 'Error al actualizar grupo',
    EDIT_GROUP: 'Editar Grupo',
    ADD_GROUP: 'Crear Grupo',
    CONFIRM_DELETE: '¿Desea eliminar el grupo?',
    DELETE_SUCCESS: 'Grupo borrado con éxito',
    DELETE_ERROR: 'Error al borrar grupo',
    LOCAL_GROUPS: 'Grupos de usuario',
    USERS: 'Usuarios',
  },
  HELP_MODAL: {
    DEVICE_EXPECTED_HEADERS: 'Cabeceras esperadas (separadas por coma)',
    DEVICE_EXPECTED_ROWS: 'Filas (separadas por coma)',
    DEVICE_ROWS:
      'Nombre de dispositivo,Teldat,Número de serie de dispositivo,Descripción,Modelo de dispositivo*,DVC de dispositivo,ip,licencias**',
    DEVICE_POSSIBLE_MODELS:
      '* Posibles valores de modelo: RS420, Celer, M2, M10, Regesta, Atlas 840, H5-Rail, H5-Auto, VM',
    DEVICE_LICENSES_LEGEND: '** Leyenda de licencias:',
    DEVICE_POSSIBLE_LICENSES:
      "Valores posibles (separados por ';'): UTM_R_AS, UTM_M_AS, UTM_A_AS, UTM_V_AS, UTM_R_AV, UTM_M_AV, UTM_A_AV, UTM_V_AV, UTM_R_AC, UTM_M_AC, UTM_A_AC, UTM_V_AC, UTM_R_DLP, UTM_M_DLP, UTM_A_DLP, UTM_V_DLP, UTM_R_FW, UTM_M_FW, UTM_A_FW, UTM_V_FW, UTM_R_IPS, UTM_M_IPS, UTM_A_IPS, UTM_V_IPS, UTM_R_IPSOT, UTM_M_IPSOT, UTM_A_IPSOT, UTM_V_IPSOT, UTM_R_SSL, UTM_M_SSL, UTM_A_SSL, UTM_V_SSL, UTM_R_ZTNA, UTM_M_ZTNA, UTM_A_ZTNA, UTM_V_ZTNA, UTM_R_RA, UTM_M_RA, UTM_A_RA, UTM_V_RA, UTM_R_UF, UTM_M_UF, UTM_A_UF, UTM_V_UF, UTM_R_CB, UTM_M_CB, UTM_A_CB, UTM_V_CB, UTM_R_DNS, UTM_M_DNS, UTM_A_DNS, UTM_V_DNS, UTM_R_UID, UTM_M_UID, UTM_A_UID, UTM_V_UID, UTM_R_VPNTUN, UTM_M_VPNTUN, UTM_A_VPNTUN, UTM_V_VPNTUN",
  },
  DNS_FORWARDER: {
    INVALID_DNS_1: 'El DNS Forwarder primario no es válido',
    INVALID_DNS_2: 'El DNS Forwarder secundario no es válido',
    BLOCK_DISABLE_SAFE_SEARCH:
      'El DNS Forwarder no puede deshabilitarse mientras el dispositivo tenga asociado un perfil Firewall con la protección de búsquedas activada',
  },
  DNS_RESOLVER: {
    INVALID_DNS_1: 'El DNS Resolver primario no es válido',
    INVALID_DNS_2: 'El DNS Resolver secundario no es válido',
    BLOCK_DISABLE: 'El DNS Resolver no puede deshabilitarse',
  },
  TRUST: {
    VERY_LOW: 'Muy Bajo',
    LOW: 'Bajo',
    MEDIUM: 'Medio',
    HIGH: 'Alto',
    VERY_HIGH: 'Muy Alto',
  },
  ACTIONS: {
    ALLOW: 'Permitir',
    DETECT: 'Detectar',
    BLOCK: 'Bloquear',
  },
  CASB_RULES: {
    ADD: 'Creada',
    CONFIRM_DELETE: '¿Está seguro de borrar esta regla CASB?',
    DUPLICATED_NAME: 'Nombre ya registrado',
    EDIT: 'Actualizada',
    ERROR_ADD: 'Error al crear la regla CASB',
    ERROR_EDIT: 'Error al actualizar la regla CASB',
    SUCCESS_ADD: 'Regla CASB creada correctamente',
    SUCCESS_DELETE: 'Regla CASB eliminada correctamente',
    SUCCESS_EDIT: 'Regla CASB actualizada correctamente',
  },
  DLP_OBJECTS: {
    NAME: 'Nombre',
    DESCRIPTION: 'Descripción',
    EXPRESSION: 'Expresión',
    EDIT: 'Editar objeto DLP',
    ADD: 'Añadir objeto DLP',
    EDIT_SUCCESS: 'Objeto DLP actualizado correctamente',
    EDIT_ERROR: 'Error al actualizar objeto DLP',
    ADD_SUCCESS: 'Objeto DLP añadido correctamente',
    ADD_ERROR: 'Error al añadir objeto DLP',
    LOAD_ERROR: 'Error al cargar los objetos DLP',
    ALREADY_IN_USE: 'Ya en uso',
    DELETE_SUCCESS: 'Objeto DLP borrado correctamente',
  },
  DLP_RULES: {
    EDIT_SUCCESS: 'Regla DLP actualizada correctamente',
    EDIT_ERROR: 'Error al actualizar regla DLP',
    ADD_SUCCESS: 'Regla DLP añadida correctamente',
    ADD_ERROR: 'Error al añadir regla DLP',
    LOAD_ERROR: 'Error al cargar las reglas DLP',
    DELETE_SUCCESS: 'Regla DLP borrada correctamente',
    NAME: 'Reglas DLP',
  },
  PROTOCOL_PORTS: {
    UNWANTED_CHARACTERS:
      'Caracteres no deseados. Asegure emplear sólo números, comas y espacios.',
    NOT_IN_RANGE: 'Uno o más puertos de los especificados no es válido',
  },
  SSL_EXCEPTION: {
    DUPLICATE_NAME: 'Nombre ya registrado',
    EMPTY_NAME: 'Nombre no puede ser vacío',
  },
  IPSEC_VPN: {
    TITLE: 'Tuneles VPN IPSec L2L',
    PHASE1: 'Fase 1',
    ENCRYPTION: 'Cifrado',
    HASH: 'Hash',
    MAXIMUM_PROPOSALS_REACHED: 'Sólo se permiten 20 Proposiciones',
    MAX_SECOND_PHASES: 'Sólo se permiten 10 Segundas fases',
    CONFIRM_DELETE: '¿Desea eliminar el túnel?',
    DELETE_SUCCESS: 'Túnel borrado con éxito',
    DELETE_ERROR: 'Error al borrar túnel',
    SUCCESS_CREATE: 'Túnel creado con éxito',
    ERROR_CREATE: 'Error al crear túnel',
    SUCCESS_UPDATE: 'Túnel actualizado con éxito',
    ERROR_UPDATE: 'Error al actualizar túnel',
    EDIT_TUNNEL: 'Editar túnel',
    ADD_TUNNEL: 'Crear túnel',
    REMOTE_PEER: 'Peer Remoto',
    LOCAL_INTERFACE: 'Interfaz Local',
    HOSTNAME: 'Nombre del host',
    IP: 'IP',
    IKE: 'Versión IKE',
    DEAD_PEER_DETECTION: 'Dead peer detection',
    ACTION: 'Acción',
    INTERVAL: 'Intervalo (1-86400 segs)',
    TIMEOUT: 'Timeout (1-86400 segs)',
    AUTHENTICATION: 'Autenticación',
    PRESHARED_KEY: 'Clave pre-compartida',
    LOCAL_ID: 'ID local',
    REMOTE_ID: 'ID remoto',
    PROPOSALS: 'Proposal',
    DIFFIE_HELLMAN_GROUPS: 'Grupos Diffie Hellman',
    IKE_LIFETIME: 'Tiempo de vida del IKE (30-86400)',
    IKE_MODE: 'Modo IKE',
    PHASE2: 'Fase 2',
    LOCAL_SELECTOR: 'Selector Local',
    REMOTE_SELECTOR: 'Selector Remoto',
    PERFECT_FORWARD_SECRECY: 'Perfect Forward Secrecy',
    MODE: 'Modo',
    LIFETIME_TYPE: 'Tipo de tiempo de vida',
    LIFETIME: 'Tiempo de vida',
    COMPRESSION: 'Compresión',
    TUNNEL: 'Tunel',
    TRANSPORT: 'Transporte',
    SECONDS: 'Segundos',
    MB: 'MB',
    PACKETS: 'Paquetes',
    AES128: 'Cifrado AES-128 con CBC *',
    AES192: 'Cifrado AES-192 con CBC *',
    AES256: 'Cifrado AES-256 con CBC *',
    AES128GCM128: 'Cifrado AES-128 con Galois Counter Mode 128-bit',
    AES192GCM128: 'Cifrado AES-192 con Galois Counter Mode 128-bit',
    AES256GCM128: 'Cifrado AES-256 con Galois Counter Mode 128-bit',
    AES128GMAC: 'Cifrado Null con AES-128 Galois Message Authentication Code',
    AES192GMAC: 'Cifrado Null con AES-192 Galois Message Authentication Code',
    AES256GMAC: 'Cifrado Null con AES-256 Galois Message Authentication Code',
    AES192GCM64: 'Cifrado AES-192 con GCM and 64 bit ICV',
    AES128CCM64: 'Cifrado AES-128 con CCM and 64 bit ICV',
    AES192CCM64: 'Cifrado AES-192 con CCM and 64 bit ICV',
    AES256CCM64: 'Cifrado AES-256 con CCM and 64 bit ICV',
    '3DES': 'Cifrado 3DES',
    CHACHA20POLY1305: 'Cifrado ChaCha20-Poly1305',
    NULL: 'Cifrado Null',
    MD5: 'Hash MD5',
    SHA1: 'Hash SHA1',
    SHA256: 'Hash SHA2-256',
    SHA384: 'Hash SHA2-384',
    SHA512: 'Hash SHA2-512',
    ENCRYPTION_NOTE: 'Algoritmo de cifrado acelerado por hardware',
    ADD_TUNNEL_ABOVE: 'Añadir tunel arriba',
    ADD_TUNNEL_BELOW: 'Añadir tunel debajo',
    ADD_TUNNEL_TOP: 'Añadir tunel al principio',
    ADD_TUNNEL_BOTTOM: 'Añadir tunel al final',
    PHASE_2_REMOTE_PREFIX: 'Prefijo remoto de la fase 2',
    WRONG_INTERVAL: 'El intervalo debe ser un valor entre 1 y 86400',
    WRONG_TIMEOUT: 'El timeout debe ser un valor entre 1 y 86400',
    WRONG_IKE_LIFETIME:
      'El tiempo de vida de IKE debe ser un valor entre 30 y 86400',
    WRONG_LIFETIME: 'El tiempo de vida debe ser un valor entre 0 y 4294967295',
    IPSEC_MODE: 'Modo IPSec',
    ROUTE_BASED: 'Basado en rutas',
    POLICY_BASED: 'Basado en políticas',
    VIRTUAL_TUNNEL_INTERFACE_NAME: 'Nombre de interfaz de túnel virtual',
    ADD_ROUTE_AUTO: 'Crear VPN PBR automáticamente',
    CONNECTION_TYPE: 'Tipo de conexión',
    ON_DEMAND: 'Bajo demanda',
    INITIATE: 'Iniciador',
    RESPOND: 'Respondedor',
    ANY: '%any',
    ANY_TOOLTIP: "Sólo activo cuando el tipo de conexión es 'Responder'",
  },
  USER_LOGINS: {
    EMAIL: 'Email de usuario',
    DATE: 'Fecha',
    RESULT: 'Resultado',
    REMOTE_ADDRESS: 'Dirección remota',
    LOGIN_ERROR: 'El inicio de sesión ha fallado',
  },
  LIST_MODAL: {
    SOURCE_SELECT: 'Seleccionar Origen',
    CERTIFICATE_SELECT: 'Seleccionar Certificado',
  },
  USER_MANUAL: {
    CARRIER_FILENAME: 'v1.1.0_carrier_es.pdf',
    UTM_FILENAME: 'v1.1.2_utm_es.pdf',
  },
  THREAT_INTEL: {
    VERSION: 'Versión',
    LAST_UPDATE: 'Última actualización',
    LAST_CHECK: 'Última comprobación',
    CLOUD: 'Cloud',
    MANAGED_DEVICES: 'Dispositivos gestionados',
  },
};

export default flatten(messages);
