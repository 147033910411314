import axios from "axios";

const BASE_URL_BESAFE_OBJECTS = "/besafe_objects";

export function getSchedules(params) {
  return axios.get(`${BASE_URL_BESAFE_OBJECTS}`, { params });
}

export function deleteSchedule(id) {
  return axios.delete(`${BASE_URL_BESAFE_OBJECTS}/${id}`);
}

export function createSchedule(params) {
  return axios.post(`${BASE_URL_BESAFE_OBJECTS}`, params);
}

export function updateSchedule(params) {
  return axios.patch(`${BASE_URL_BESAFE_OBJECTS}/${params.id}`, params);
}