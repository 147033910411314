import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const events = [
  'mousemove',
  'click',
  'keypress'
];

const WARNING_TIME_MSECS = 1000 * 60;
const DEFAULT_MAX_INACTIVE_MSECS = 15 * 60 * 1000;

const InactivityModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [remaining, setRemaining] = useState(WARNING_TIME_MSECS);
    const [intervalId, setIntervalId] = useState(null)
    const navigate = useNavigate();
    const { max_inactive_time } = useSelector(
      ({ auth }) => ({
        max_inactive_time: auth?.user?.max_inactive_time || DEFAULT_MAX_INACTIVE_MSECS
      })
    );

    const warning_time = max_inactive_time - WARNING_TIME_MSECS;

    let idleEvent = useRef(null); 
    let idleLogoutEvent = useRef(null);

    const logOut = useCallback(() => {
      setIsOpen(false)
      navigate('/logout', { replace: true })
    }, [navigate])

    let removeEventListeners

    const openModal = useCallback(() => {
      setIsOpen(true)
      const interval = setInterval(() => {
        setRemaining((prev) => {
          const newPrev = prev - 1000
          if (newPrev === 0) {
            logOut()
          }
          return newPrev
        })
      }, 1000)
      setIntervalId(interval)
      removeEventListeners()
    }, [removeEventListeners, logOut])

    const sessionTimeout = useCallback(() => {
      if(!!idleEvent.current) clearTimeout(idleEvent.current);
      if(!!idleLogoutEvent.current) clearTimeout(idleLogoutEvent.current);
    
      idleEvent.current = setTimeout(openModal, warning_time);
      idleLogoutEvent.current = setTimeout(logOut, max_inactive_time);
    }, [logOut, max_inactive_time, warning_time, openModal]);
    
    const addEventListeners = useCallback(() => {
      for (let e in events) {
        window.addEventListener(events[e], sessionTimeout);
      }
    }, [sessionTimeout])

    removeEventListeners = useCallback(() => {
      for (let e in events) {
        window.removeEventListener(events[e], sessionTimeout);
      }
    }, [sessionTimeout])

    useEffect(() => {
      addEventListeners()
    
      return () => {
        clearInterval(intervalId)
        removeEventListeners()
      }}, [intervalId, addEventListeners, removeEventListeners]
    );

    const closeModal = useCallback(() => {
      setIsOpen(false)
      clearInterval(intervalId)
      setRemaining(WARNING_TIME_MSECS)
      addEventListeners()
    }, [addEventListeners, intervalId])

    return (
        <Modal
          show={isOpen}
          centered
          size="sm"
          dialogClassName="mh-50"
        >
        <Modal.Header className="bg-light-grey">
          <Modal.Title>
            <span className="d-none d-md-inline-block ml-1">
              <PowerSettingsNewIcon fontSize="small" className="mr-2" />
              <FormattedMessage id="INACTIVITY_CONTROL.INACTIVE_SESSION" />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto">
          <FormattedMessage id="INACTIVITY_CONTROL.INACTIVITY_WARNING" values={{ s: (remaining / 1000) }} />
          <Button color="grey" className="float-right mt-4" onClick={closeModal}>
            <FormattedMessage id="INACTIVITY_CONTROL.STAY_LOGGED_IN" />
          </Button>
        </Modal.Body>
      </Modal>
    )
}

export default InactivityModal;