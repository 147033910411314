const BeSafeSeparator = () => {
  return (
    <div>
      <hr className='mt-1 border-tertiary' />
    </div>
  );
};

export default BeSafeSeparator;



