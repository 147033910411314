import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const actionTypes = {
  SetClusterTaskId: "[Change Clsuter Task Id] Action",
};

const initialState = {
  clusterTaskId: "",
};

export const reducer = persistReducer(
  { storage, key: "clusters" },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetClusterTaskId:
        return {
          ...state,
          clusterTaskId: action.payload.clusterTaskId,
        };
      default:
        return state;
    }
  }
);

export const SetClusterTaskId = (clusterTaskId) => ({
  type: actionTypes.SetClusterTaskId,
  payload: { clusterTaskId },
});
