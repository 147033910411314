import axios from 'axios';

const BASE_URL = '/accounts';

export function getAllAccounts(params) {
  return axios.get(`${BASE_URL}`, { params });
}

export function deleteAccount(id) {
  return axios.delete(`${BASE_URL}/${id}`, {});
}

export function getAccount(id) {
  return axios.get(`${BASE_URL}/${id}`);
}

export function createAccount(params) {
  return axios.post(`${BASE_URL}`, params);
}

export function updateAccount(account_id, params) {
  return axios.patch(`${BASE_URL}/${account_id}`, params);
}

export function getCNMConnection(account_id) {
  return axios.get(`${BASE_URL}/${account_id}/cnm`, {});
}

export function updateCNMConnection(account_id, id, params) {
  return axios.patch(`${BASE_URL}/${account_id}/cnm/${id}`, { params });
}

export function updateMaxInactiveTime(account_id, params) {
  return axios.patch(`${BASE_URL}/${account_id}/max_inactive_time`, params);
}

export function getInterfaces(params) {
  return axios.get(`${BASE_URL}/get_interfaces`, { params });
}

export function updateVpnClientConfigPullInterval(account_id, params) {
  return axios.patch(
    `${BASE_URL}/${account_id}/update_vpn_client_config_interval`,
    params
  );
}

export function updateAdSyncTimeInterval(account_id, params) {
  return axios.patch(
    `${BASE_URL}/${account_id}/update_ad_sync_interval_time`,
    params
  );
}

export function testCNMConnection(account_id, params) {
  return axios.get(`${BASE_URL}/${account_id}/test_cnm_connection`, {
    params: params,
  });
}

export function getConcurrentUsers(account_id) {
  return axios.get(`${BASE_URL}/${account_id}/concurrent_users`);
}
