import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AppsIcon from '@mui/icons-material/Apps';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DevicesIcon from '@mui/icons-material/Devices';
import SettingsIcon from '@mui/icons-material/Settings';

import BeSafeContainer from 'components/commons/BeSafeContainer';

var pjson = require('../../../package.json');

const LayoutSidebarMain = ({
  accountType,
  role,
  clickMenuButton,
  closeSidebar,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const homeLinks = {
    // QA: 'https://qa.networkcloudmanager.com/web',
    QA: 'https://besafeprocnm.networkcloudmanager.com/',
    PROD: 'https://teldat.cloudnetmanager.com/web',
  };

  const menuElementsAbove = [
    {
      link:
        process.env.NODE_ENV === 'production' ? homeLinks.PROD : homeLinks.QA,
      icon: <AppsIcon />,
      msg: <FormattedMessage id="SIDEBAR.HOME" />,
      key: 'home',
      allowed: {
        carrier: ['super_admin', 'account_manager', 'end_user', 'operator'],
        utm: ['super_admin', 'account_manager', 'end_user', 'operator'],
        reseller: ['super_admin', 'reseller_admin'],
      },
      isActive: (_route, _path) => {
        return false;
      },
    },
    {
      route: '/',
      icon: <DashboardIcon />,
      msg: <FormattedMessage id="SIDEBAR.ANALYTIC" />,
      key: 'analytic',
      allowed: {
        carrier: ['super_admin', 'account_manager', 'end_user', 'operator'],
        utm: ['super_admin', 'account_manager', 'end_user', 'operator'],
        reseller: ['super_admin', 'reseller_admin'],
      },
      isActive: (route, path) => {
        // Uncomment when XDR is activated
        return (
          route === '/' &&
          (path === '/' || path === '/reports') /*|| path === '/xdr'*/
        );
      },
    },
    {
      route: '/devices',
      icon: <DevicesIcon />,
      msg: <FormattedMessage id="SIDEBAR.DEVICES" />,
      key: 'devices',
      allowed: {
        carrier: ['super_admin', 'account_manager', 'end_user'],
        utm: ['super_admin', 'account_manager', 'end_user'],
        reseller: ['super_admin', 'reseller_admin'],
      },
      isActive: (route, path) => {
        return path.includes('device') && route === '/devices';
      },
    },
    {
      route: '/configuration',
      icon: <SettingsIcon />,
      msg: <FormattedMessage id="SIDEBAR.CONFIGURATION" />,
      key: 'configuration',
      allowed: {
        carrier: ['account_manager', 'end_user'],
        utm: ['account_manager', 'end_user'],
        reseller: [],
      },
      isActive: (route, path) => {
        return path.includes('configuration/') && route === '/configuration';
      },
    },
  ];

  const menuElementsBelow = [
    {
      route: '/administration',
      icon: <ManageAccountsIcon />,
      msg: <FormattedMessage id="SIDEBAR.ADMINISTRATION" />,
      key: 'administration',
      allowed: {
        carrier: [
          'super_admin',
          'reseller_admin',
          'account_manager',
          'end_user',
        ],
        utm: ['super_admin', 'reseller_admin', 'account_manager', 'end_user'],
        reseller: ['super_admin', 'reseller_admin'],
      },
      isActive: (route, path) => {
        return path === route;
      },
    },
  ];

  const clickNavButton = (elem) => {
    if (elem.key === 'analytic') {
      return clickMenuButton('analytic');
    }

    if (elem.key === 'configuration') {
      return clickMenuButton('configuration');
    }

    closeSidebar();
    navigate(elem.route);
  };

  const mainButton = (elem, index) => {
    const className = `nav_link ${
      elem.isActive(elem.route, location.pathname) ? 'is-active' : ''
    }`;

    const content = (
      <>
        <span className="nav_icon">{elem.icon}</span>
        <span className="nav_msg">{elem.msg}</span>
      </>
    );

    return (
      <span key={index}>
        <BeSafeContainer visible={'link' in elem}>
          <Link className={className} to={elem.link} target="_blank">
            {content}
          </Link>
        </BeSafeContainer>

        <BeSafeContainer visible={!('link' in elem)}>
          <Button className={className} onClick={() => clickNavButton(elem)}>
            {content}
          </Button>
        </BeSafeContainer>
      </span>
    );
  };

  return (
    <div className="l-navbar-main" id="nav-bar">
      <nav className="nav-sidebar h-100">
        <div className="nav_list">
          <div key={1} className="logo-sidebar">
            <img
              height="30"
              width="30"
              src={`${process.env.PUBLIC_URL}/images/besafe_logo.png`}
              alt="besafe-pro"
            />
            <div className="title-sidebar">
              <span>BeSafe</span>
              <span>Pro</span>
            </div>
          </div>
        </div>
        <div className="nav_list">
          {menuElementsAbove
            .filter((elem) =>
              elem.allowed?.[accountType || 'utm'].includes(role)
            )
            .map((elem, index) => mainButton(elem, index))}
        </div>
        <div className="version text-center d-flex flex-column justify-content-end h-100">
          {menuElementsBelow
            .filter((elem) =>
              elem.allowed?.[accountType || 'utm'].includes(role)
            )
            .map((elem, index) => mainButton(elem, index))}

          <img
            src={`${process.env.PUBLIC_URL}/images/teldat_logo.png`}
            alt="teldat"
            className="w-100 pl-2 pr-2"
          />
          <p>v{pjson.version}</p>
        </div>
      </nav>
    </div>
  );
};

export default LayoutSidebarMain;
