import { CircularProgress } from '@mui/material';
import { Card, ListGroup } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { getWhereUsed } from 'utils/utils';
import { successToast, errorToast } from 'utils/toastUtils';
import PersonIcon from '@mui/icons-material/Person';
import Download from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import BeSafeGreenCircle from 'components/commons/BeSafeGreenCircle';

import { useState } from 'react';
import { whereObjectUsed } from 'app/crud/networks.crud';
import {
  sendEmailToUser,
  regenerateApiKey,
} from 'app/crud/vpn_client_configs.crud';
import { getVpnClientConfig } from 'app/crud/networks.crud';
import { useEffect } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import BeSafeButton from 'components/commons/BeSafeButton';
import { enableFranciumFeatures } from 'utils/constants';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ConfirmationPopup } from 'components/ConfirmDialog';
import axios from 'axios';

const UserSubList = ({
  previewLoading,
  users,
  selectedUser,
  deleteNetworkObject,
  showEditNetworkModal,
  refetchNetwork,
}) => {
  const [whereUsed, setWhereUsed] = useState([]);
  const [ipsecTunnels, setIpsecTunnel] = useState([]);
  const [sslTunnels, setSslTunnel] = useState([]);
  const [vpnClientConfig, setVpnClientConfig] = useState({});
  const [disableRegenerateBtn, setDisableRegenerateBtn] = useState(false);
  const [disableEmailBtn, setDisableEmailBtn] = useState(false);
  const intl = useIntl();
  const downloadUrl = `${axios.defaults.baseURL}/windows_clients/download_windows_exe`;

  const sendWindowsInstallerEmail = (besafe_object_id) => {
    setDisableEmailBtn(true);
    if (!besafe_object_id) {
      return errorToast({ body: 'PAGE_RELOAD.PAGE_RELOAD', intl: intl });
    }
    sendEmailToUser(besafe_object_id)
      .then(() => {
        successToast({
          body: 'ADMIN_USERS.WINDOWS_EXE_EMAIL_SUCCESS',
          intl: intl,
        });
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
      })
      .finally(() => {
        setDisableEmailBtn(false);
      });
  };

  const handleApiKeyRegenerate = (user_id) => {
    if (!user_id) return;

    setDisableRegenerateBtn(true);
    ConfirmationPopup({
      title: intl.formatMessage({
        id: 'GENERAL.WARNING',
      }),
      description: intl.formatMessage({
        id: 'NETWORK_OBJECTS.CONFIRM_API_REGENERATE',
      }),
      okLabel: intl.formatMessage({
        id: 'GENERAL.OK',
      }),
      cancelLabel: intl.formatMessage({
        id: 'GENERAL.CANCEL',
      }),
      okAction: () => {
        regenerateApiKey(user_id)
          .then(() => {
            refetchNetwork();
            successToast({
              body: 'NETWORK_OBJECTS.API_KEY_REGENERATE_SUCCESS',
              intl: intl,
            });
          })
          .catch((err) => {
            errorToast({ body: err.response?.data?.error, intl: intl });
          });
      },
    });
    setDisableRegenerateBtn(false);
  };

  const userIsActive = (user) => {
    const userIsNonEditable = !user.editable;
    const userDataExists = 'data' in user;
    const activeKeyExists = 'active' in user.data;
    return (
      userIsNonEditable && userDataExists && activeKeyExists && user.data.active
    );
  };

  useEffect(() => {
    if (selectedUser.id) {
      setSslTunnel(
        selectedUser?.vpn_tunnels?.filter(
          (tunnel) => tunnel.tunnel_type === 'ssl'
        )
      );
      setIpsecTunnel(
        selectedUser?.vpn_tunnels?.filter(
          (tunnel) => tunnel.tunnel_type === 'ipsec'
        )
      );
    }
  }, [selectedUser.id, selectedUser]);

  useEffect(() => {
    if (selectedUser?.id) {
      getVpnClientConfig(selectedUser?.id)
        .then((response) => {
          setVpnClientConfig(response.data);
        })
        .catch(() => {});
    } else {
      setVpnClientConfig({});
    }
  }, [selectedUser.id, selectedUser]);

  useEffect(() => {
    whereObjectUsed(selectedUser.id)
      .then((response) => {
        setWhereUsed(getWhereUsed(response.data));
      })
      .catch(() => {});
  }, [selectedUser.id]);
  return (
    <>
      {previewLoading ? (
        <div className="d-flex card justify-content-center align-items-center w-60 h-100 mr3">
          <CircularProgress />
        </div>
      ) : users.length <= 0 ? (
        <span className="card w-60 h-100 mr-3 p-3 text-center pt-5">
          <FormattedMessage id="GENERAL.NO_DATA" />
        </span>
      ) : (
        <Card className="w-60 h-100 mr-3">
          <Card.Header>
            <Card.Title className="d-flex justify-content-between py-0 my-0 text-cus-heading">
              <span className="my-auto">
                <PersonIcon className="mr-2" />
                {selectedUser.name}
              </span>
              {(selectedUser.editable || !userIsActive(selectedUser)) && (
                <div>
                  {selectedUser.editable && (
                    <BeSafeButton
                      variant="transparent"
                      className="p-0 px-1"
                      onClick={showEditNetworkModal}
                      icon={<Edit className="icon-table-md" />}
                      tooltip="GENERAL.EDIT_SMALL"
                    />
                  )}
                  <BeSafeButton
                    variant="transparent"
                    className="p-0 px-1"
                    onClick={deleteNetworkObject}
                    icon={<Delete className="icon-table-md" />}
                    tooltip="GENERAL.DELETE_SMALL"
                  />
                </div>
              )}
            </Card.Title>
          </Card.Header>
          <Card.Body className="overflow-auto">
            <div className="d-flex py-2">
              <span className="font-weight-bold">
                <FormattedMessage id="NETWORK_OBJECTS.DOMAIN" />
              </span>
              <span>{`: ${selectedUser?.data?.domain || ''}`}</span>
            </div>

            <div className="d-flex py-2">
              <span className="font-weight-bold">
                <FormattedMessage id="NETWORK_OBJECTS.GROUP" />
              </span>
              <span>{`: ${selectedUser?.data?.group || ''}`}</span>
            </div>

            <div className="d-flex py-2">
              <span className="font-weight-bold">
                <FormattedMessage id="NETWORK_OBJECTS.COMPUTER" />
              </span>
              <span>{`: ${selectedUser?.data?.computer || ''}`}</span>
            </div>

            <div className="d-flex py-2">
              <span className="font-weight-bold">
                <FormattedMessage id="NETWORK_OBJECTS.IPV4" />
              </span>
              <span>{`: ${selectedUser?.ip_address || ''}`}</span>
            </div>

            <div className="d-flex py-2">
              <span className="font-weight-bold">
                <FormattedMessage id="NETWORK_OBJECTS.IPV6" />
              </span>
              <span>{`: ${selectedUser?.ipv6_address || ''}`}</span>
            </div>
            <div className="d-flex py-2">
              <span className="font-weight-bold">
                <FormattedMessage id="NETWORK_OBJECTS.EMAIL" />
              </span>
              <span>{`: ${selectedUser?.data?.email || ''}`}</span>
            </div>
            {selectedUser?.data?.source && (
              <div className="d-flex py-2">
                <span className="font-weight-bold">
                  <FormattedMessage id="NETWORK_OBJECTS.SOURCE" />
                </span>
                <span>{`: ${selectedUser?.data?.source.join(',') || ''}`}</span>
              </div>
            )}

            <div className="d-flex py-2">
              <span className="font-weight-bold">Status</span>
              <span>{`: ${selectedUser?.data?.active ? 'On' : 'Off'}`}</span>
            </div>
            {enableFranciumFeatures && (
              <>
                <div className="d-flex py-2">
                  <span className="font-weight-bold">
                    <FormattedMessage id="OBJECT_EXPLORER.APPLICATION_STATUS" />
                    :
                  </span>
                  <span className="ml-2">
                    {vpnClientConfig?.is_active ? (
                      <FormattedMessage id="OBJECT_EXPLORER.RUNNING" />
                    ) : (
                      <FormattedMessage id="OBJECT_EXPLORER.INACTIVE" />
                    )}
                  </span>
                </div>
                <div className="d-flex py-2 align-items-center">
                  <span className="font-weight-bold">
                    <FormattedMessage id="OBJECT_EXPLORER.USER_BUILD" />:
                  </span>
                  <BeSafeButton
                    className="px-1 py-0 mr-1 ml-1"
                    variant="primary"
                    href={downloadUrl}
                    icon={<Download className="icon-table" />}
                    disabled={!vpnClientConfig?.windows_installer_exists}
                    tooltip="NETWORK_OBJECTS.DOWNLOAD_BUILD"
                  />
                  <BeSafeButton
                    className="px-1 py-0 mr-1 ml-1"
                    variant="primary"
                    onClick={() => sendWindowsInstallerEmail(selectedUser?.id)}
                    icon={<EmailIcon className="icon-table" />}
                    disabled={
                      !vpnClientConfig?.windows_installer_exists ||
                      disableEmailBtn
                    }
                    tooltip="NETWORK_OBJECTS.SEND_EMAIL"
                  />
                </div>
                <div className="d-flex py-2 align-items-center">
                  <span className="font-weight-bold">
                    <FormattedMessage id="NETWORK_OBJECTS.API_KEY" />
                  </span>
                  <span>{`: ${
                    selectedUser?.vpn_client_config?.api_key || ''
                  }`}</span>
                  <span>
                    <BeSafeButton
                      className="px-1 py-0 mr-1 ml-2"
                      variant="primary"
                      onClick={() => handleApiKeyRegenerate(selectedUser?.id)}
                      icon={<RefreshIcon className="icon-table" />}
                      disabled={disableRegenerateBtn}
                      tooltip="NETWORK_OBJECTS.REGENERATE_API_KEY"
                    />
                  </span>
                </div>
                {sslTunnels.length > 0 && (
                  <>
                    <div className="d-flex py-2">
                      <span className="font-weight-bold mr-2">
                        <FormattedMessage id="OBJECT_EXPLORER.SSL_PROFILE" />
                      </span>
                      <ul className="list-unstyled mb-0">
                        {sslTunnels.map((sslTunnel) => (
                          <li key={sslTunnel.id}>
                            {sslTunnel.name}
                            {vpnClientConfig?.tunnel_profiles?.map(
                              (tunnel_profile) => {
                                const isMatch =
                                  sslTunnel.name ===
                                  tunnel_profile.profile_name;
                                return (
                                  <span key={sslTunnel.name}>
                                    {isMatch && (
                                      <>
                                        <BeSafeGreenCircle
                                          active={
                                            isMatch &&
                                            tunnel_profile.is_active_tunnel
                                          }
                                        />
                                      </>
                                    )}
                                  </span>
                                );
                              }
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
                {ipsecTunnels.length > 0 && (
                  <>
                    <div className="d-flex py-2">
                      <span className="font-weight-bold mr-2">
                        <FormattedMessage id="OBJECT_EXPLORER.IPSEC_PROFILE" />
                      </span>
                      <ul className="list-unstyled mb-0">
                        {ipsecTunnels.map((ipsecTunnel) => (
                          <li key={ipsecTunnel.id}>
                            {ipsecTunnel.name}
                            {vpnClientConfig?.tunnel_profiles?.map(
                              (tunnel_profile) => {
                                const isMatch =
                                  ipsecTunnel.name ===
                                  tunnel_profile.profile_name;
                                return (
                                  <span key={ipsecTunnel.name}>
                                    {isMatch && (
                                      <>
                                        <BeSafeGreenCircle
                                          active={
                                            isMatch &&
                                            tunnel_profile.is_active_tunnel
                                          }
                                        />
                                      </>
                                    )}
                                  </span>
                                );
                              }
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
              </>
            )}
            {selectedUser?.besafe_groups?.length > 0 && (
              <>
                <div className="d-flex py-2">
                  <span className="font-weight-bold">
                    <FormattedMessage id="NETWORK_OBJECTS.GROUPS" />
                  </span>
                </div>
                <ListGroup>
                  {selectedUser?.besafe_groups?.map((g, index) => (
                    <ListGroup.Item
                      key={g.id}
                      className="d-flex justify-content-between py-0"
                    >
                      <span className="w-75 py-2">{g.name}</span>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
          </Card.Body>
          <Card.Footer className="obj-where-used">
            <p className="font-weight-bold my-0">
              <FormattedMessage id="GENERAL.WHERE_USED" />:
            </p>
            <span>
              {whereUsed?.length <= 0 ? (
                <FormattedMessage id="GENERAL.NOT_IN_USE" />
              ) : (
                whereUsed?.map((r) => r).join(', ')
              )}
            </span>
          </Card.Footer>
        </Card>
      )}
    </>
  );
};

export default UserSubList;
