import axios from "axios";

export function sendEmailToUser(besafe_object_id) {
  return axios.get(`windows_clients/email_windows_installer?besafe_object_id=${besafe_object_id}`);
}

export function createWindowsExe() {
  return axios.post('windows_clients/build_windows_installer');
}

export function regenerateApiKey(user_id){
  return axios.patch(`/besafe_objects/${user_id}/regenerate_api_key`);
}
