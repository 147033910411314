import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Inventory2Icon from '@mui/icons-material/Inventory2';

import { SetObjectExplorerModal } from 'store/ducks/configuration.duck';
import BeSafeButton from 'components/commons/BeSafeButton';
import ObjectExplorerModal from 'components/ObjectExplorerModal';

const ObjectExplorer = () => {
  const dispatch = useDispatch();

  const { objectExplorerModal } = useSelector(
    ({ configuration }) => ({
      objectExplorerModal: configuration.objectExplorerModal,
    }),
    shallowEqual
  );

  return (
    <>
      <BeSafeButton
        onClick={() => dispatch(SetObjectExplorerModal(true))}
        className="object-explorer-button mr-3 p-1"
        icon={<Inventory2Icon className="mr-2" />}
        text={<FormattedMessage id="NAVBAR.OBJECT_EXPLORER" />}
      />

      <ObjectExplorerModal
        showModal={objectExplorerModal}
        hideModal={() => dispatch(SetObjectExplorerModal(false))}
      />
    </>
  );
};

export default ObjectExplorer;