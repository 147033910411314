import React, { useState } from 'react';
import Router from './shared/Router';
import I18nProvider from './i18n/i18nProvider';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './store';
import ThemeProvider from 'assets/materialUIThemeProvider/ThemeProvider';

import './vendor/styles/bootstrap.scss';
import './vendor/styles/appwork.scss';
import './vendor/styles/theme-teldat.scss';
import './vendor/styles/colors.scss';
import './App.scss';
import axios from 'axios';
import { getCountNotificacionChanges } from 'app/crud/configuration.crud';
import { SetConfigurationChanges } from 'store/ducks/configuration.duck';
import { useEffect } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import { useDispatch } from 'react-redux';
import { GetConfig } from 'utils/config';
import Loader from 'shared/Loader';
import InactivityModal from 'components/modals/InactivityModal';

function App() {
  const dispatch = useDispatch();
  const [keycloak, setKeycloak] = useState(null);

  useEffect(() => {
    GetConfig()
      .then((config) => config.json())
      .then((config) => {
        const keycloakConfig = {
          url: config.REACT_APP_AUTH_SERVER_URL,
          realm: config.REACT_APP_AUTH_SERVER_REALM,
          clientId: config.REACT_APP_AUTH_SERVER_CLIENT_ID,
        };
        setKeycloak(new Keycloak(keycloakConfig));
      });
  }, []);

  axios.interceptors.response.use((response) => {
    if (
      response.config.method !== 'get' &&
      response.config.url !== 'sign_out'
    ) {
      getCountNotificacionChanges()
        .then((response) => {
          dispatch(SetConfigurationChanges(response.data.count_changes));
        })
        .catch(() => {});
    }

    return Promise.resolve(response);
  });

  if (!keycloak) {
    return <Loader />;
  }

  return (
    <ReactKeycloakProvider
      initOptions={{ onLoad: 'login-required' }}
      authClient={keycloak}
    >
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <ThemeProvider>
            <I18nProvider>
              <InactivityModal />
              <Router />
            </I18nProvider>
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </ReactKeycloakProvider>
  );
}

export default App;
