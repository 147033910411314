import React from "react";

const BeSafeTabTitle = (props) => {

    return <>
        {props.icon && <span className='mr-1 align-bottom'>{props.icon}</span>}
        <span>{props.title}</span>
    </>
};

export default BeSafeTabTitle;
