import axios from 'axios';

export const LOGIN_URL = 'sign_in';
export const LOGOUT_URL = 'sign_out';
export const REGISTER_URL = 'users';
export const PASSWORD_RESET_URL = 'password_reset';

export const ME_URL = 'users/my_profile';

export function login(email) {
  return axios.post(LOGIN_URL, { session: { email } });
}

export function logout(delete_all_sessions = true) {
  return axios.delete(LOGOUT_URL, { data: { delete_all_sessions } });
}

// export function getCurrentUser() {
//   return axios.get(ME_URL);
// }
