import React from "react";
import { FormattedMessage } from "react-intl";

const NoDataDisplay = (props) => {

    const display = (
        <div className={"d-flex m-2 mt-4 " + (props.align === "center" ? "justify-content-center" : "")}>
            <FormattedMessage id="GENERAL.NO_DATA" />
        </div>
    );

    return (props.visible ? display : null);
};

NoDataDisplay.defaultProps = {
    align: "center"
};

export default NoDataDisplay;
