import React, { useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import { getConfigurationChanges } from "../../app/crud/configuration.crud";
import { useSelector, shallowEqual } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Pagination from "../Pagination";
import "bootstrap/js/src/collapse.js";
import ObjectViewer from "../ObjectViewer";
import BeSafeTableWithLoading from "components/commons/table/BeSafeTableWithLoading";

const ViewChangesModal = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedRow, setSelectedRow] = useState([]);
  const [perPage, setPerPage] = useState(10);

  // TO_DO replace this for template id
  const { accountID } = useSelector(
    ({ auth }) => ({
      accountID: auth.user.account_id,
    }),
    shallowEqual
  );

  const fetchChanges = () => {
    setLoading(true);
    getConfigurationChanges(accountID)
      .then((response) => {
        setData(response.data.changes);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handlePageChange = (pageNum) => {
    setPageNumber(pageNum);
  };

  const handlePerPageChange = (itemsPerPage) => {
    setPerPage(itemsPerPage);
  };

  return (
    <div>
      <Modal
        size="lg"
        centered
        show={props.showModal}
        onHide={props.onHide}
        onEnter={fetchChanges}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="NAVBAR.VIEW_CHANGES" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <BeSafeTableWithLoading
              loading={loading}
              borderless
            >
              <thead>
                <tr key="header">
                  <th>
                    <div className="d-flex ">
                      <FormattedMessage id="ADMINISTRATION.TYPE" />
                    </div>
                  </th>
                  <th>
                    <div className="d-flex ">
                      <FormattedMessage id="ADMINISTRATION.ACTION" />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((audit, index) => (
                  <React.Fragment key={audit.id}>
                    <tr
                      key={audit.id}
                      data-toggle="collapse"
                      data-target={".i" + audit.id}
                      style={{ cursor: "pointer" }}
                      className={
                        selectedRow.includes(audit.id) ? "bg-body" : ""
                      }
                      onClick={() =>
                        selectedRow.includes(audit.id)
                          ? setSelectedRow(
                              selectedRow.filter((item) => item !== audit.id)
                            )
                          : setSelectedRow([...selectedRow, audit.id])
                      }
                    >
                      <td>
                        {selectedRow.includes(audit.id) && (
                          <ArrowForwardIosIcon
                            className="text-primary icon-small"
                            style={{
                              marginRight: "10px",
                              transform: "rotate(90deg)",
                            }}
                          />
                        )}
                        {!selectedRow.includes(audit.id) && (
                          <ArrowForwardIosIcon
                            className="text-primary icon-small"
                            style={{ marginRight: "10px" }}
                          />
                        )}
                        {audit.auditable_type}
                      </td>
                      <td>
                        <i>{audit.action}</i>
                      </td>
                    </tr>
                    <tr
                      className={"collapse i" + audit.id}
                      id={"." + audit.id}
                    >
                      <td
                        colSpan="2"
                        style={{ paddingLeft: "30%", paddingRight: "30%" }}
                      >
                        <ObjectViewer
                          object={audit.audited_changes}
                          type={audit.action}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </BeSafeTableWithLoading>
            <div className="mt-3 d-flex justify-content-between">
              <div>
                <span className="text-primary align-sub">
                  <b>
                    <FormattedMessage id="GENERAL.TOTAL" />:{" "}
                  </b>
                  {data.length}
                </span>
              </div>
              <Pagination
                totalItems={data.length}
                handlePageChange={handlePageChange}
                pageNumber={pageNumber}
                perPage={perPage}
                handlePerPageChange={handlePerPageChange}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default connect((store) => ({
  user: store.auth.user,
}))(injectIntl(ViewChangesModal));
