const BeSafeContainer = ({visible, children}) => {

  return (
    visible ?
      children
      : null
  );
};

BeSafeContainer.defaultProps = {
  visible: true
};
export default BeSafeContainer;
