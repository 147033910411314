import React from 'react';
import { FormattedMessage } from 'react-intl';

import BeSafeContainer from 'components/commons/BeSafeContainer';
import MenuAnalytic from 'shared/layouts/MenuAnalytic';
import MenuConfiguration from 'shared/layouts/MenuConfiguration';

const LayoutSidebarSecondary = ({
  accountType,
  role,
  configurationMenu,
  sidebarMenu,
  expandMenu,
}) => {
  return (
    <div className="l-navbar-secondary" id="conf-bar">
      <nav className="l-nav-content">
        <div className="nav_label">
          {sidebarMenu && (
            <FormattedMessage id={`SIDEBAR.${sidebarMenu.toUpperCase()}`} />
          )}
        </div>
        <div className="nav_list">
          <BeSafeContainer visible={sidebarMenu === 'analytic'}>
            <MenuAnalytic accountType={accountType} role={role} />
          </BeSafeContainer>

          <BeSafeContainer visible={sidebarMenu === 'configuration'}>
            <MenuConfiguration
              accountType={accountType}
              menu={configurationMenu}
              parent={{ name: 'configuration' }}
              onChangeExpanded={expandMenu}
            />
          </BeSafeContainer>
        </div>
      </nav>
    </div>
  );
};

export default LayoutSidebarSecondary;
