import { CircularProgress } from "@mui/material";
import { Card, ListGroup } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import BeSafeContainer from "components/commons/BeSafeContainer";
import BeSafeButton from "components/commons/BeSafeButton";
import { useEffect } from "react";
import { whereObjectUsed } from "app/crud/networks.crud";
import { getWhereUsed } from "utils/utils";
import { useState } from "react";

const CasbTenantSubList = ({
  previewLoading,
  casbTenants,
  selectedCasbTenant,
  deleteNetworkObject,
  showEditNetworkModal,
}) => {
  const intl = useIntl();
  const [whereUsed, setWhereUsed] = useState([]);

  useEffect(() => {
    whereObjectUsed(selectedCasbTenant.id)
      .then((response) => {
        setWhereUsed(getWhereUsed(response.data));
      })
      .catch(() => { });
  }, [selectedCasbTenant.id]);

  return (
    <>
      <BeSafeContainer visible={previewLoading}>
        <div className="d-flex card justify-content-center align-items-center w-60 h-100 mr3">
          <CircularProgress />
        </div>
      </BeSafeContainer>
      <BeSafeContainer visible={!previewLoading && casbTenants.length <= 0}>
        <span className="card w-60 h-100 mr-3 p-3 text-center pt-5">
          <FormattedMessage id="GENERAL.NO_DATA" />
        </span>
      </BeSafeContainer>
      <BeSafeContainer visible={!previewLoading && casbTenants.length > 0}>
        <Card className="w-60 h-100 mr-3">
          <Card.Header>
            <Card.Title className="d-flex justify-content-between py-0 my-0 text-cus-heading">
              <span className="my-auto">
                <PersonIcon className="mr-2" />
                {selectedCasbTenant.name}
              </span>
              <BeSafeContainer visible={selectedCasbTenant.editable}>
                <div>
                  <BeSafeButton
                    variant="transparent"
                    className="pr-1"
                    onClick={showEditNetworkModal}
                    icon={<EditIcon className="icon-table-md" />}
                    tooltip={intl.formatMessage({ id: "GENERAL.EDIT_SMALL" })}
                  />
                  <BeSafeButton
                    variant="transparent"
                    className="px-2"
                    onClick={deleteNetworkObject}
                    icon={<DeleteIcon className="icon-table-md" />}
                    tooltip={intl.formatMessage({ id: "GENERAL.DELETE_SMALL" })}
                  />
                </div>
              </BeSafeContainer>
            </Card.Title>
          </Card.Header>
          <Card.Body className="overflow-auto">
            <div className="d-flex py-2">
              <span className="font-weight-bold">
                <FormattedMessage id="CASB_TENANT_OBJECTS.TARGET" />
              </span>
              <span>{`: ${selectedCasbTenant?.target || ""}`}</span>
            </div>

            <BeSafeContainer
              visible={selectedCasbTenant?.besafe_groups?.length > 0}
            >
              <div className="d-flex py-2">
                <span className="font-weight-bold">
                  <FormattedMessage id="NETWORK_OBJECTS.GROUPS" />
                </span>
              </div>
              <ListGroup>
                {selectedCasbTenant?.besafe_groups?.map((g, index) => (
                  <ListGroup.Item
                    key={g.id}
                    className="d-flex justify-content-between py-0"
                  >
                    <span className="w-75 py-2">{g.name}</span>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </BeSafeContainer>
          </Card.Body>
          <Card.Footer className="obj-where-used">
            <p className="font-weight-bold my-0">
              <FormattedMessage id="GENERAL.WHERE_USED" />:
            </p>
            <span>
              {whereUsed?.length <= 0 ? (
                <FormattedMessage id="GENERAL.NOT_IN_USE" />
              ) : (
                whereUsed?.map((r) => r).join(", ")
              )}
            </span>
          </Card.Footer>
        </Card>
      </BeSafeContainer>
    </>
  );
};

export default CasbTenantSubList;
