import React from "react";
import SkeletonLoader from "tiny-skeleton-loader-react";

const TableLoader = (props) => {
    const loader = Array.apply(null, { length: props.rows }).map((e, i) => (
        <SkeletonLoader
            className="mb-4"
            key={"loader" + i}
            width="100%"
            height="39.0px"
            style={{ marginBottom: "10px" }}
        />
    ));

    return (props.visible ? loader : null);
};

TableLoader.defaultProps = {
    rows: 10
};

export default TableLoader;
