import axios from "axios";

const BASE_URL = "/azure_ad_configs";

export function createAzureAdConfig(params) {
  return axios.post(`${BASE_URL}`, params);
}

export function deleteAzureAdConfig(id) {
  return axios.delete(`${BASE_URL}/${id}`, {});
}

export function getAzureAdConfigs(params) {
  return axios.get(`${BASE_URL}`, { params });
}

export function updateAzureAdConfig(account_id, params) {
  return axios.patch(`${BASE_URL}/${account_id}`, params);
}

export function syncAzureAdUsers(id){
  return axios.post(`${BASE_URL}/${id}/sync_azure_ad_users`, {});
}